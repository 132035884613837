<template>
  <div class="home">
    <div class="juou-card statistics" v-loading="statisticLoading">
      <div class="statistics-item">
        <span>累计设备数量</span>
        <span>
          <span class="number">{{ statistics.totalDevice }}</span>台
        </span>
      </div>
      <el-divider direction="vertical" />
      <div class="statistics-item">
        <span>在线设备</span>
        <span>
          <span class="number">{{ statistics.onlineDevice }}</span>台
        </span>
      </div>
      <el-divider direction="vertical" />
      <div class="statistics-item">
        <span>累计扫码次数</span>
        <span>
          <span class="number">{{ statistics.totalCount }}</span>次
        </span>
      </div>
      <el-divider direction="vertical" />
      <div class="statistics-item">
        <span>累计扫码人数</span>
        <span>
          <span class="number">{{ statistics.totalPeople }}</span>人
        </span>
      </div>
    </div>
    <div class="juou-card message" style="margin-top: 8px;" v-loading="messageLoading">
      <div class="title">
        <span>站内信</span>
        <el-button
          v-if="baseOperateStore.operates?.find(item => item.key === 'SEND_MESSAGE')"
          link type="danger" style="color: red" @click="handleClickSend">发送</el-button>
      </div>
      <div class="list" >
        <div class="list-item" v-for="msg in messages" :key="msg.id">
          <span class="time">{{ dayjs(msg.createTime).format("YYYY/MM/DD") }} </span>
          <span>{{ msg.msg }}</span>
          <el-button
            v-if="baseOperateStore.operates?.find(item => item.key === 'DEL_MESSAGE')"
            link type="danger" style="color: red" @click="() => handleDeleteMsg(msg.id)">删除</el-button>
          <!-- <el-divider style="margin: 8px 0 0 0; width: 99%" /> -->
        </div>
      </div>
    </div>
    <JuouProForm ref="sendMsgForm" title="发送站内信" :items="messageItems" is-dialog @ok="handleSendMsg" />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
  useBaseOperateStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage, ElMessageBox } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const baseOperateStore = useBaseOperateStore();

const statistics = ref({
  onlineDevice: 0,
  totalCount: 0,
  totalDevice: 0,
  totalPeople: 0,
});

const messages = ref<any[]>([]);


const messageItems = ref<JuouProFormItem[]>([
  {
    name: 'msg',
    label: '内容',
    rule: [{ required: true }],
    type: 'textaera',
  },
]);

const sendMsgForm = ref<InstanceType<typeof JuouProForm>>();

const statisticLoading = ref(false);

const getStatistics = useBaseAsync(async() => {
  try {
    statisticLoading.value = true;
    const res = await useApi().homeApi.getHomeStatisticshome();
    console.log(res);
    statistics.value = res.data.data;
  } finally {
    statisticLoading.value = false;
  }
});

const messageLoading = ref(false);
const getMessage = useBaseAsync(async() => {
  try {
    messageLoading.value = true;
    const res = await useApi().homeApi.listMessagehome({ conditions: {} });
    console.log(res);
    messages.value = res.data.data;
  } finally {
    messageLoading.value = false;
  }
});

const refreash = () => {
  getStatistics();
  getMessage();
};

const handleSendMsg = async(row: any) => {
  messageLoading.value = true;
  await useApi().homeApi.addMessagehome({ messageAdminVo: row });
  ElMessage.success('发送成功');
  refreash();
};

const deleteAction = async(msgId: number) => {
  await useApi().homeApi.delMessagehome(msgId);
  ElMessage.success('删除成功');
  refreash();
};

const handleDeleteMsg = (msgId: number) => {
  ElMessageBox.confirm('删除后无法恢复，确定要删除吗？', '删除', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(() => {
    deleteAction(msgId);
  });
};

const handleClickSend = () => {
  sendMsgForm.value?.reset();
  sendMsgForm.value?.showDialog();
};


refreash();

// init();

</script>
<style lang="scss" scoped>
.home {

  .statistics {
    width: 100%;
    height: 130px;
    display: grid;
    grid-template-columns: 1fr 16px 1fr 16px 1fr 16px 1fr;


    >* {
      justify-self: center;
      height: 100px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;

      .number {
        font-size: 24px;
        margin-right: 8px;
        color: var(--el-color-primary);
      }
    }


  }

  .message {
    min-height: 300px;
    display: block;

    .title {
      font-size: 16px;

      display: flex;
      align-items: center;
      gap: 16px;

      span {
        vertical-align: middle;
        margin-bottom: 4px;
      }
    }

    .list {

      overflow-y: auto;
      box-sizing: border-box;
      padding: 8px 0;

      &-item {
        padding: 8px 0;

        .time {
          color: #515151;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
