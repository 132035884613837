/* tslint:disable */
/* eslint-disable */
/**
 * JUOU.OpenDoor - API文档
 * <b>注意：仅开启swagger选项有效，建议上线前关闭该选项</b>  
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminVo
 */
export interface AdminVo {
    /**
     * 
     * @type {number}
     * @memberof AdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminVo
     */
    'phone': number;
    /**
     * 
     * @type {string}
     * @memberof AdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminVo
     */
    'password': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof AdminVo
     */
    'menus'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof AdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface AdvertisingAdminVo
 */
export interface AdvertisingAdminVo {
    /**
     * 
     * @type {number}
     * @memberof AdvertisingAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisingAdminVo
     */
    'sequence': number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisingAdminVo
     */
    'rowId': number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingAdminVo
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingAdminVo
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingAdminVo
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingAdminVo
     */
    'appid'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface AdvertisingRowAdminVo
 */
export interface AdvertisingRowAdminVo {
    /**
     * 
     * @type {number}
     * @memberof AdvertisingRowAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingRowAdminVo
     */
    'position': string;
    /**
     * 
     * @type {number}
     * @memberof AdvertisingRowAdminVo
     */
    'sequence': number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingRowAdminVo
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingRowAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BASEMENUTYPEE = {
    Link: 'link',
    Dir: 'dir'
} as const;

export type BASEMENUTYPEE = typeof BASEMENUTYPEE[keyof typeof BASEMENUTYPEE];


/**
 * 
 * @export
 * @enum {string}
 */

export const BASEROUTETYPEE = {
    AdminPage: 'admin_page',
    CommonPage: 'common_page'
} as const;

export type BASEROUTETYPEE = typeof BASEROUTETYPEE[keyof typeof BASEROUTETYPEE];


/**
 * 
 * @export
 * @interface BaseApiAdminVo
 */
export interface BaseApiAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseApiAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'swaggerPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'tagName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseApiAdminVo
     */
    'allowAll': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseApiAdminVo
     */
    'enable': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseApiAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseDictionaryAdminVo
 */
export interface BaseDictionaryAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseDictionaryAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseDictionaryAdminVo
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BaseDictionaryAdminVo
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof BaseDictionaryAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseLogAdminVo
 */
export interface BaseLogAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseLogAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseLogAdminVo
     */
    'startId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseLogAdminVo
     */
    'endId'?: number;
}
/**
 * 
 * @export
 * @interface BaseMenuAdminVo
 */
export interface BaseMenuAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseMenuAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'url'?: string;
    /**
     * 
     * @type {BASEMENUTYPEE}
     * @memberof BaseMenuAdminVo
     */
    'type': BASEMENUTYPEE;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'icon'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMenuAdminVo
     */
    'sequence': number;
    /**
     * 
     * @type {boolean}
     * @memberof BaseMenuAdminVo
     */
    'enable': boolean;
    /**
     * 
     * @type {number}
     * @memberof BaseMenuAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseMenuAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseMenuAdminVo
     */
    'routeIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface BaseOperateAdminVo
 */
export interface BaseOperateAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseOperateAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseOperateAdminVo
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BaseOperateAdminVo
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseOperateAdminVo
     */
    'roleIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BaseOperateAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseOrganizationAdminVo
 */
export interface BaseOrganizationAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseOrganizationAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseOrganizationAdminVo
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseOrganizationAdminVo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BaseOrganizationAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseOrganizationAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BasePageQueryVo
 */
export interface BasePageQueryVo {
    /**
     * 
     * @type {number}
     * @memberof BasePageQueryVo
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof BasePageQueryVo
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BaseResultVo
 */
export interface BaseResultVo {
    /**
     * 
     * @type {boolean}
     * @memberof BaseResultVo
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseResultVo
     */
    'msg'?: string;
    /**
     * 
     * @type {any}
     * @memberof BaseResultVo
     */
    'data'?: any;
}
/**
 * 
 * @export
 * @interface BaseRoleAdminVo
 */
export interface BaseRoleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseRoleAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'orgCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseRoleAdminVo
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseRouteAdminVo
 */
export interface BaseRouteAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseRouteAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {BASEROUTETYPEE}
     * @memberof BaseRouteAdminVo
     */
    'type': BASEROUTETYPEE;
    /**
     * 
     * @type {boolean}
     * @memberof BaseRouteAdminVo
     */
    'allowAll': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'componentName': string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseRouteAdminVo
     */
    'isNest': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'redirect'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseRouteAdminVo
     */
    'enable': boolean;
    /**
     * 
     * @type {number}
     * @memberof BaseRouteAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseRouteAdminVo
     */
    'roleIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface BaseTenantAdminVo
 */
export interface BaseTenantAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseTenantAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseTenantAdminVo
     */
    'name': string;
    /**
     * 
     * @type {any}
     * @memberof BaseTenantAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {string}
     * @memberof BaseTenantAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseUserAdminVo
 */
export interface BaseUserAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseUserAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseUserAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseUserAdminVo
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {any}
     * @memberof BaseUserAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseUserAdminVo
     */
    'roleIds': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseUserAdminVo
     */
    'orgCodes'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseUserAdminVo
     */
    'routeIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseUserAdminVo
     */
    'menuIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface BaseUserRoleAdminVo
 */
export interface BaseUserRoleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseUserRoleAdminVo
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof BaseUserRoleAdminVo
     */
    'orgCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseUserRoleAdminVo
     */
    'roleId': number;
}
/**
 * 
 * @export
 * @interface BlackAccountAdminVo
 */
export interface BlackAccountAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BlackAccountAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BlackAccountAdminVo
     */
    'phone': number;
    /**
     * 
     * @type {number}
     * @memberof BlackAccountAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof BlackAccountAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlackAccountAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DEVICEMODE = {
    Open: 'open',
    Control: 'control',
    Close: 'close'
} as const;

export type DEVICEMODE = typeof DEVICEMODE[keyof typeof DEVICEMODE];


/**
 * 
 * @export
 * @interface DeviceAdminVo
 */
export interface DeviceAdminVo {
    /**
     * 
     * @type {number}
     * @memberof DeviceAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceAdminVo
     */
    'idHex'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAdminVo
     */
    'serialNumber': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceAdminVo
     */
    'deviceTypeId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviceAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface DistanceAdminVo
 */
export interface DistanceAdminVo {
    /**
     * 
     * @type {number}
     * @memberof DistanceAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DistanceAdminVo
     */
    'phone': number;
    /**
     * 
     * @type {number}
     * @memberof DistanceAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof DistanceAdminVo
     */
    'distance': number;
    /**
     * 
     * @type {string}
     * @memberof DistanceAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistanceAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface FacilitatorAdminVo
 */
export interface FacilitatorAdminVo {
    /**
     * 
     * @type {number}
     * @memberof FacilitatorAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacilitatorAdminVo
     */
    'phone': number;
    /**
     * 
     * @type {string}
     * @memberof FacilitatorAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FacilitatorAdminVo
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof FacilitatorAdminVo
     */
    'province': string;
    /**
     * 
     * @type {string}
     * @memberof FacilitatorAdminVo
     */
    'city': string;
    /**
     * 
     * @type {number}
     * @memberof FacilitatorAdminVo
     */
    'deviceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacilitatorAdminVo
     */
    'relativePhone'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacilitatorAdminVo
     */
    'relativeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilitatorAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface MessageAdminVo
 */
export interface MessageAdminVo {
    /**
     * 
     * @type {number}
     * @memberof MessageAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageAdminVo
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof MessageAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PUBLISHSTATUSE = {
    NoPublish: 'no_publish',
    NeedUpdate: 'need_update',
    Review: 'review',
    ReviewFailure: 'review_failure',
    Success: 'success'
} as const;

export type PUBLISHSTATUSE = typeof PUBLISHSTATUSE[keyof typeof PUBLISHSTATUSE];


/**
 * 
 * @export
 * @interface PageQueryVo
 */
export interface PageQueryVo {
    /**
     * 
     * @type {number}
     * @memberof PageQueryVo
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageQueryVo
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface PartialAdminVo
 */
export interface PartialAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof PartialAdminVo
     */
    'menus'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof PartialAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialAdvertisingAdminVo
 */
export interface PartialAdvertisingAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialAdvertisingAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialAdvertisingAdminVo
     */
    'sequence'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialAdvertisingAdminVo
     */
    'rowId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialAdvertisingAdminVo
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAdvertisingAdminVo
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAdvertisingAdminVo
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAdvertisingAdminVo
     */
    'appid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAdvertisingAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialAdvertisingRowAdminVo
 */
export interface PartialAdvertisingRowAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialAdvertisingRowAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialAdvertisingRowAdminVo
     */
    'position'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialAdvertisingRowAdminVo
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialAdvertisingRowAdminVo
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAdvertisingRowAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseApiAdminVo
 */
export interface PartialBaseApiAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseApiAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'swaggerPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'tagName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseApiAdminVo
     */
    'allowAll'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseApiAdminVo
     */
    'enable'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseApiAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseDictionaryAdminVo
 */
export interface PartialBaseDictionaryAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseDictionaryAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseDictionaryAdminVo
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseDictionaryAdminVo
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseDictionaryAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseLogAdminVo
 */
export interface PartialBaseLogAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseLogAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseLogAdminVo
     */
    'startId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseLogAdminVo
     */
    'endId'?: number;
}
/**
 * 
 * @export
 * @interface PartialBaseMenuAdminVo
 */
export interface PartialBaseMenuAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseMenuAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'url'?: string;
    /**
     * 
     * @type {BASEMENUTYPEE}
     * @memberof PartialBaseMenuAdminVo
     */
    'type'?: BASEMENUTYPEE;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'icon'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseMenuAdminVo
     */
    'sequence'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseMenuAdminVo
     */
    'enable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseMenuAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseMenuAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseMenuAdminVo
     */
    'routeIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface PartialBaseOperateAdminVo
 */
export interface PartialBaseOperateAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseOperateAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOperateAdminVo
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOperateAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseOperateAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOperateAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseOrganizationAdminVo
 */
export interface PartialBaseOrganizationAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseRoleAdminVo
 */
export interface PartialBaseRoleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRoleAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'orgCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRoleAdminVo
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseRouteAdminVo
 */
export interface PartialBaseRouteAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRouteAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {BASEROUTETYPEE}
     * @memberof PartialBaseRouteAdminVo
     */
    'type'?: BASEROUTETYPEE;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseRouteAdminVo
     */
    'allowAll'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'componentName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseRouteAdminVo
     */
    'isNest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'redirect'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseRouteAdminVo
     */
    'enable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRouteAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseRouteAdminVo
     */
    'roleIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface PartialBaseScheduleAdminVo
 */
export interface PartialBaseScheduleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleInterval'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleCurrent'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleNextTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseTenantAdminVo
 */
export interface PartialBaseTenantAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseTenantAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseTenantAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {any}
     * @memberof PartialBaseTenantAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseTenantAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseUserAdminVo
 */
export interface PartialBaseUserAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseUserAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseUserAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseUserAdminVo
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseUserAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseUserAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {any}
     * @memberof PartialBaseUserAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseUserAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialBaseUserAdminVo
     */
    'orgCodes'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseUserAdminVo
     */
    'routeIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseUserAdminVo
     */
    'menuIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface PartialBlackAccountAdminVo
 */
export interface PartialBlackAccountAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBlackAccountAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBlackAccountAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBlackAccountAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBlackAccountAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBlackAccountAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialDeviceAdminVo
 */
export interface PartialDeviceAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialDeviceAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialDeviceAdminVo
     */
    'idHex'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialDeviceAdminVo
     */
    'serialNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialDeviceAdminVo
     */
    'deviceTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialDeviceAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialDeviceAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialDistanceAdminVo
 */
export interface PartialDistanceAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialDistanceAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialDistanceAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialDistanceAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialDistanceAdminVo
     */
    'distance'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialDistanceAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialDistanceAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialFacilitatorAdminVo
 */
export interface PartialFacilitatorAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialFacilitatorAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialFacilitatorAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialFacilitatorAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFacilitatorAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFacilitatorAdminVo
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFacilitatorAdminVo
     */
    'city'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialFacilitatorAdminVo
     */
    'deviceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialFacilitatorAdminVo
     */
    'relativePhone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialFacilitatorAdminVo
     */
    'relativeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFacilitatorAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialMessageAdminVo
 */
export interface PartialMessageAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialMessageAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialMessageAdminVo
     */
    'msg'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialMessageAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialRecordAdminVo
 */
export interface PartialRecordAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialRecordAdminVo
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'msg'?: string;
    /**
     * 
     * @type {object}
     * @memberof PartialRecordAdminVo
     */
    'userGeo'?: object;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'customType'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAdminVo
     */
    'distance'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'deviceRemark'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'storeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialStoreAdminVo
 */
export interface PartialStoreAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'deviceIdHex'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'end'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'longtitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'latitude'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'distance'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'deviceTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'facilitatorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'facilitatorName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'whiteStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'whiteEnd'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialStoreAdminVo
     */
    'openWhite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'wxAppid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'downloadQrcodeUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'allCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'allPeople'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'lastdayCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'lastdayPeople'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialSubAccountAdminVo
 */
export interface PartialSubAccountAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialSubAccountAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialSubAccountAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialSubAccountAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialSubAccountAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialSubAccountAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialTenantAdminVo
 */
export interface PartialTenantAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialTenantAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialTenantAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialTenantAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialTenantAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTenantAdminVo
     */
    'logo'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialTenantAdminVo
     */
    'weappTplIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialTenantAdminVo
     */
    'wxTplIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PartialTenantAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialWeappAdminVo
 */
export interface PartialWeappAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialWeappAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappAdminVo
     */
    'appid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappAdminVo
     */
    'principalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialWeappAdminVo
     */
    'baseSetting'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof PartialWeappAdminVo
     */
    'privacyInterface'?: any;
    /**
     * 
     * @type {number}
     * @memberof PartialWeappAdminVo
     */
    'templateId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialWeappAdminVo
     */
    'auditId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappAdminVo
     */
    'currentVersion'?: string;
    /**
     * 
     * @type {PUBLISHSTATUSE}
     * @memberof PartialWeappAdminVo
     */
    'status'?: PUBLISHSTATUSE;
    /**
     * 
     * @type {any}
     * @memberof PartialWeappAdminVo
     */
    'ext'?: any;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappAdminVo
     */
    'createTime'?: string;
}


/**
 * 
 * @export
 * @interface PartialWeappTplAdminVo
 */
export interface PartialWeappTplAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialWeappTplAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappTplAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappTplAdminVo
     */
    'currentVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappTplAdminVo
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialWeappTplAdminVo
     */
    'templateId'?: number;
    /**
     * 
     * @type {any}
     * @memberof PartialWeappTplAdminVo
     */
    'extjsFormItems'?: any;
    /**
     * 
     * @type {string}
     * @memberof PartialWeappTplAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialWhiteAccountAdminVo
 */
export interface PartialWhiteAccountAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialWhiteAccountAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialWhiteAccountAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialWhiteAccountAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialWhiteAccountAdminVo
     */
    'isApplay'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialWhiteAccountAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWhiteAccountAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialWxAdminVo
 */
export interface PartialWxAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialWxAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialWxAdminVo
     */
    'appid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxAdminVo
     */
    'principalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxAdminVo
     */
    'openMsgTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxAdminVo
     */
    'applyTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxAdminVo
     */
    'weappAppid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialWxTplAdminVo
 */
export interface PartialWxTplAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialWxTplAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialWxTplAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxTplAdminVo
     */
    'currentVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxTplAdminVo
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialWxTplAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface RecordAdminVo
 */
export interface RecordAdminVo {
    /**
     * 
     * @type {number}
     * @memberof RecordAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RecordAdminVo
     */
    'status': boolean;
    /**
     * 
     * @type {string}
     * @memberof RecordAdminVo
     */
    'msg': string;
    /**
     * 
     * @type {object}
     * @memberof RecordAdminVo
     */
    'userGeo'?: object;
    /**
     * 
     * @type {string}
     * @memberof RecordAdminVo
     */
    'customType'?: string;
    /**
     * 
     * @type {number}
     * @memberof RecordAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {number}
     * @memberof RecordAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof RecordAdminVo
     */
    'distance'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecordAdminVo
     */
    'deviceRemark'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordAdminVo
     */
    'storeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface ResultVo
 */
export interface ResultVo {
    /**
     * 
     * @type {boolean}
     * @memberof ResultVo
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResultVo
     */
    'msg'?: string;
    /**
     * 
     * @type {any}
     * @memberof ResultVo
     */
    'data'?: any;
}
/**
 * 
 * @export
 * @interface StoreAdminVo
 */
export interface StoreAdminVo {
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'phone': number;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'deviceIdHex': string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'end': number;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'longtitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'latitude'?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'distance'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'deviceTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'facilitatorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'facilitatorName'?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'whiteStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'whiteEnd'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StoreAdminVo
     */
    'openWhite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'wxAppid'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'downloadQrcodeUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'allCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'allPeople'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'lastdayCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'lastdayPeople'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface StoreOwnerVo
 */
export interface StoreOwnerVo {
    /**
     * 
     * @type {number}
     * @memberof StoreOwnerVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreOwnerVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOwnerVo
     */
    'deviceIdHex': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOwnerVo
     */
    'longtitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreOwnerVo
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreOwnerVo
     */
    'mod'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreOwnerVo
     */
    'status'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StoreOwnerVo
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof StoreOwnerVo
     */
    'end': number;
    /**
     * 
     * @type {boolean}
     * @memberof StoreOwnerVo
     */
    'isSubAccount'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StoreOwnerVo
     */
    'allCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreOwnerVo
     */
    'allPeople'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreOwnerVo
     */
    'lastdayCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreOwnerVo
     */
    'lastdayPeople'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StoreOwnerVo
     */
    'openWhite'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StoreOwnerVo
     */
    'whiteStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreOwnerVo
     */
    'whiteEnd'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreOwnerVo
     */
    'downloadQrcodeUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreOwnerVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface SubAccountAdminVo
 */
export interface SubAccountAdminVo {
    /**
     * 
     * @type {number}
     * @memberof SubAccountAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubAccountAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof SubAccountAdminVo
     */
    'phone': number;
    /**
     * 
     * @type {string}
     * @memberof SubAccountAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubAccountAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject1
 */
export interface SystemGenerateObject1 {
    /**
     * 
     * @type {PartialBaseApiAdminVo}
     * @memberof SystemGenerateObject1
     */
    'conditions': PartialBaseApiAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject10
 */
export interface SystemGenerateObject10 {
    /**
     * 
     * @type {PartialBaseRoleAdminVo}
     * @memberof SystemGenerateObject10
     */
    'conditions': PartialBaseRoleAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject100
 */
export interface SystemGenerateObject100 {
    /**
     * 
     * @type {MessageAdminVo}
     * @memberof SystemGenerateObject100
     */
    'messageAdminVo': MessageAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject101
 */
export interface SystemGenerateObject101 {
    /**
     * 
     * @type {PartialDistanceAdminVo}
     * @memberof SystemGenerateObject101
     */
    'conditions': PartialDistanceAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject102
 */
export interface SystemGenerateObject102 {
    /**
     * 
     * @type {PartialDistanceAdminVo}
     * @memberof SystemGenerateObject102
     */
    'conditions': PartialDistanceAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject102
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject103
 */
export interface SystemGenerateObject103 {
    /**
     * 
     * @type {DistanceAdminVo}
     * @memberof SystemGenerateObject103
     */
    'distanceAdminVo': DistanceAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject104
 */
export interface SystemGenerateObject104 {
    /**
     * 
     * @type {DistanceAdminVo}
     * @memberof SystemGenerateObject104
     */
    'distanceAdminVo': DistanceAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject105
 */
export interface SystemGenerateObject105 {
    /**
     * 
     * @type {PartialRecordAdminVo}
     * @memberof SystemGenerateObject105
     */
    'conditions': PartialRecordAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject105
     */
    'pageQuery': PageQueryVo;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject105
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject105
     */
    'end'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject106
 */
export interface SystemGenerateObject106 {
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject106
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject107
 */
export interface SystemGenerateObject107 {
    /**
     * 
     * @type {PartialRecordAdminVo}
     * @memberof SystemGenerateObject107
     */
    'conditions': PartialRecordAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject107
     */
    'pageQuery': PageQueryVo;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject107
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject107
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject107
     */
    'end'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject108
 */
export interface SystemGenerateObject108 {
    /**
     * 
     * @type {RecordAdminVo}
     * @memberof SystemGenerateObject108
     */
    'recordAdminVo': RecordAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject109
 */
export interface SystemGenerateObject109 {
    /**
     * 
     * @type {RecordAdminVo}
     * @memberof SystemGenerateObject109
     */
    'recordAdminVo': RecordAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject11
 */
export interface SystemGenerateObject11 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject11
     */
    'userId': number;
    /**
     * 
     * @type {Array<BaseUserRoleAdminVo>}
     * @memberof SystemGenerateObject11
     */
    'userRoleVos': Array<BaseUserRoleAdminVo>;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject110
 */
export interface SystemGenerateObject110 {
    /**
     * 
     * @type {PartialBlackAccountAdminVo}
     * @memberof SystemGenerateObject110
     */
    'conditions': PartialBlackAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject111
 */
export interface SystemGenerateObject111 {
    /**
     * 
     * @type {PartialBlackAccountAdminVo}
     * @memberof SystemGenerateObject111
     */
    'conditions': PartialBlackAccountAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject111
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject112
 */
export interface SystemGenerateObject112 {
    /**
     * 
     * @type {BlackAccountAdminVo}
     * @memberof SystemGenerateObject112
     */
    'blackAccountAdminVo': BlackAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject113
 */
export interface SystemGenerateObject113 {
    /**
     * 
     * @type {BlackAccountAdminVo}
     * @memberof SystemGenerateObject113
     */
    'blackAccountAdminVo': BlackAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject114
 */
export interface SystemGenerateObject114 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject114
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject114
     */
    'remarkOrPhone'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject115
 */
export interface SystemGenerateObject115 {
    /**
     * 
     * @type {BlackAccountAdminVo}
     * @memberof SystemGenerateObject115
     */
    'blackAccountAdminVo': BlackAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject116
 */
export interface SystemGenerateObject116 {
    /**
     * 
     * @type {BlackAccountAdminVo}
     * @memberof SystemGenerateObject116
     */
    'blackAccountAdminVo': BlackAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject117
 */
export interface SystemGenerateObject117 {
    /**
     * 
     * @type {PartialWhiteAccountAdminVo}
     * @memberof SystemGenerateObject117
     */
    'conditions': PartialWhiteAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject118
 */
export interface SystemGenerateObject118 {
    /**
     * 
     * @type {PartialWhiteAccountAdminVo}
     * @memberof SystemGenerateObject118
     */
    'conditions': PartialWhiteAccountAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject118
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject119
 */
export interface SystemGenerateObject119 {
    /**
     * 
     * @type {WhiteAccountAdminVo}
     * @memberof SystemGenerateObject119
     */
    'whiteAccountAdminVo': WhiteAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject12
 */
export interface SystemGenerateObject12 {
    /**
     * 
     * @type {PartialBaseRoleAdminVo}
     * @memberof SystemGenerateObject12
     */
    'conditions': PartialBaseRoleAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject120
 */
export interface SystemGenerateObject120 {
    /**
     * 
     * @type {WhiteAccountAdminVo}
     * @memberof SystemGenerateObject120
     */
    'whiteAccountAdminVo': WhiteAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject121
 */
export interface SystemGenerateObject121 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject121
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject121
     */
    'remarkOrPhone'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject122
 */
export interface SystemGenerateObject122 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject122
     */
    'storeId': number;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject122
     */
    'pageQuery': PageQueryVo;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject122
     */
    'remarkOrPhone'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject123
 */
export interface SystemGenerateObject123 {
    /**
     * 
     * @type {WhiteAccountAdminVo}
     * @memberof SystemGenerateObject123
     */
    'whiteAccountAdminVo': WhiteAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject124
 */
export interface SystemGenerateObject124 {
    /**
     * 
     * @type {WhiteAccountAdminVo}
     * @memberof SystemGenerateObject124
     */
    'whiteAccountAdminVo': WhiteAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject126
 */
export interface SystemGenerateObject126 {
    /**
     * 
     * @type {PartialAdvertisingAdminVo}
     * @memberof SystemGenerateObject126
     */
    'conditions': PartialAdvertisingAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject127
 */
export interface SystemGenerateObject127 {
    /**
     * 
     * @type {AdvertisingAdminVo}
     * @memberof SystemGenerateObject127
     */
    'advertisingAdminVo': AdvertisingAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject128
 */
export interface SystemGenerateObject128 {
    /**
     * 
     * @type {AdvertisingAdminVo}
     * @memberof SystemGenerateObject128
     */
    'advertisingAdminVo': AdvertisingAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject129
 */
export interface SystemGenerateObject129 {
    /**
     * 
     * @type {PartialAdvertisingRowAdminVo}
     * @memberof SystemGenerateObject129
     */
    'conditions': PartialAdvertisingRowAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject13
 */
export interface SystemGenerateObject13 {
    /**
     * 
     * @type {PartialBaseRoleAdminVo}
     * @memberof SystemGenerateObject13
     */
    'conditions': PartialBaseRoleAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject13
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject130
 */
export interface SystemGenerateObject130 {
    /**
     * 
     * @type {AdvertisingRowAdminVo}
     * @memberof SystemGenerateObject130
     */
    'advertisingRowAdminVo': AdvertisingRowAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject131
 */
export interface SystemGenerateObject131 {
    /**
     * 
     * @type {AdvertisingRowAdminVo}
     * @memberof SystemGenerateObject131
     */
    'advertisingRowAdminVo': AdvertisingRowAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject132
 */
export interface SystemGenerateObject132 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject132
     */
    'advertisingId': number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemGenerateObject132
     */
    'up': boolean;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject133
 */
export interface SystemGenerateObject133 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject133
     */
    'advertisingRowId': number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemGenerateObject133
     */
    'up': boolean;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject134
 */
export interface SystemGenerateObject134 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject134
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject135
 */
export interface SystemGenerateObject135 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject135
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject135
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject135
     */
    'deviceIdx': string;
    /**
     * 
     * @type {DEVICEMODE}
     * @memberof SystemGenerateObject135
     */
    'mod': DEVICEMODE;
}


/**
 * 
 * @export
 * @interface SystemGenerateObject136
 */
export interface SystemGenerateObject136 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject136
     */
    'timerName': string;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject136
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject136
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject136
     */
    'deviceIdx': string;
    /**
     * 
     * @type {DEVICEMODE}
     * @memberof SystemGenerateObject136
     */
    'mod': DEVICEMODE;
}


/**
 * 
 * @export
 * @interface SystemGenerateObject14
 */
export interface SystemGenerateObject14 {
    /**
     * 
     * @type {PartialBaseUserAdminVo}
     * @memberof SystemGenerateObject14
     */
    'conditions': PartialBaseUserAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject15
 */
export interface SystemGenerateObject15 {
    /**
     * 
     * @type {PartialBaseUserAdminVo}
     * @memberof SystemGenerateObject15
     */
    'conditions': PartialBaseUserAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject15
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject16
 */
export interface SystemGenerateObject16 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject16
     */
    'oldPw': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject16
     */
    'newPw': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject17
 */
export interface SystemGenerateObject17 {
    /**
     * 
     * @type {PartialBaseOrganizationAdminVo}
     * @memberof SystemGenerateObject17
     */
    'conditions': PartialBaseOrganizationAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject18
 */
export interface SystemGenerateObject18 {
    /**
     * 
     * @type {PartialBaseOrganizationAdminVo}
     * @memberof SystemGenerateObject18
     */
    'conditions': PartialBaseOrganizationAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject18
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject19
 */
export interface SystemGenerateObject19 {
    /**
     * 
     * @type {PartialBaseLogAdminVo}
     * @memberof SystemGenerateObject19
     */
    'conditions': PartialBaseLogAdminVo;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject19
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemGenerateObject19
     */
    'isGreater': boolean;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject2
 */
export interface SystemGenerateObject2 {
    /**
     * 
     * @type {PartialBaseMenuAdminVo}
     * @memberof SystemGenerateObject2
     */
    'conditions': PartialBaseMenuAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject20
 */
export interface SystemGenerateObject20 {
    /**
     * 
     * @type {PartialBaseLogAdminVo}
     * @memberof SystemGenerateObject20
     */
    'conditions': PartialBaseLogAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject21
 */
export interface SystemGenerateObject21 {
    /**
     * 
     * @type {PartialBaseLogAdminVo}
     * @memberof SystemGenerateObject21
     */
    'conditions': PartialBaseLogAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject21
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject22
 */
export interface SystemGenerateObject22 {
    /**
     * 
     * @type {PartialBaseDictionaryAdminVo}
     * @memberof SystemGenerateObject22
     */
    'conditions': PartialBaseDictionaryAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject23
 */
export interface SystemGenerateObject23 {
    /**
     * 
     * @type {PartialBaseDictionaryAdminVo}
     * @memberof SystemGenerateObject23
     */
    'conditions': PartialBaseDictionaryAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject23
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject24
 */
export interface SystemGenerateObject24 {
    /**
     * 
     * @type {PartialBaseTenantAdminVo}
     * @memberof SystemGenerateObject24
     */
    'conditions': PartialBaseTenantAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject24
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject25
 */
export interface SystemGenerateObject25 {
    /**
     * 
     * @type {PartialTenantAdminVo}
     * @memberof SystemGenerateObject25
     */
    'conditions': PartialTenantAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject25
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject26
 */
export interface SystemGenerateObject26 {
    /**
     * 
     * @type {PartialWeappAdminVo}
     * @memberof SystemGenerateObject26
     */
    'conditions': PartialWeappAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject27
 */
export interface SystemGenerateObject27 {
    /**
     * 
     * @type {PartialWeappAdminVo}
     * @memberof SystemGenerateObject27
     */
    'conditions': PartialWeappAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject27
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject28
 */
export interface SystemGenerateObject28 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject28
     */
    'redirectUri': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject29
 */
export interface SystemGenerateObject29 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject29
     */
    'authCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject3
 */
export interface SystemGenerateObject3 {
    /**
     * 
     * @type {PartialBaseMenuAdminVo}
     * @memberof SystemGenerateObject3
     */
    'conditions': PartialBaseMenuAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject3
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject30
 */
export interface SystemGenerateObject30 {
    /**
     * 
     * @type {WeappAdminVo}
     * @memberof SystemGenerateObject30
     */
    'weappAdminVo': WeappAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject31
 */
export interface SystemGenerateObject31 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject31
     */
    'loginCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject32
 */
export interface SystemGenerateObject32 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject32
     */
    'loginCode': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject32
     */
    'phoneCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject33
 */
export interface SystemGenerateObject33 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject33
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject34
 */
export interface SystemGenerateObject34 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject34
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject35
 */
export interface SystemGenerateObject35 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject35
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject36
 */
export interface SystemGenerateObject36 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject36
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject37
 */
export interface SystemGenerateObject37 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject37
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject38
 */
export interface SystemGenerateObject38 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject38
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject39
 */
export interface SystemGenerateObject39 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject39
     */
    'appid': string;
    /**
     * 
     * @type {any}
     * @memberof SystemGenerateObject39
     */
    'ext': any;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject4
 */
export interface SystemGenerateObject4 {
    /**
     * 
     * @type {PartialBaseOperateAdminVo}
     * @memberof SystemGenerateObject4
     */
    'conditions': PartialBaseOperateAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject40
 */
export interface SystemGenerateObject40 {
    /**
     * 
     * @type {PartialWxAdminVo}
     * @memberof SystemGenerateObject40
     */
    'conditions': PartialWxAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject41
 */
export interface SystemGenerateObject41 {
    /**
     * 
     * @type {PartialWxAdminVo}
     * @memberof SystemGenerateObject41
     */
    'conditions': PartialWxAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject41
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject42
 */
export interface SystemGenerateObject42 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject42
     */
    'redirectUri': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject43
 */
export interface SystemGenerateObject43 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject43
     */
    'authCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject44
 */
export interface SystemGenerateObject44 {
    /**
     * 
     * @type {WxAdminVo}
     * @memberof SystemGenerateObject44
     */
    'wxAdminVo': WxAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject45
 */
export interface SystemGenerateObject45 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject45
     */
    'phone': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject46
 */
export interface SystemGenerateObject46 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject46
     */
    'phone': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject46
     */
    'phoneCode': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject46
     */
    'loginCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject47
 */
export interface SystemGenerateObject47 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject47
     */
    'loginCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject48
 */
export interface SystemGenerateObject48 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject48
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject49
 */
export interface SystemGenerateObject49 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject49
     */
    'appid': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject49
     */
    'weappAppid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject5
 */
export interface SystemGenerateObject5 {
    /**
     * 
     * @type {PartialBaseOperateAdminVo}
     * @memberof SystemGenerateObject5
     */
    'conditions': PartialBaseOperateAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject5
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject50
 */
export interface SystemGenerateObject50 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject50
     */
    'appid': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject50
     */
    'weappAppid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject51
 */
export interface SystemGenerateObject51 {
    /**
     * 
     * @type {any}
     * @memberof SystemGenerateObject51
     */
    'xml': any;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject52
 */
export interface SystemGenerateObject52 {
    /**
     * 
     * @type {any}
     * @memberof SystemGenerateObject52
     */
    'xml': any;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject53
 */
export interface SystemGenerateObject53 {
    /**
     * 
     * @type {PartialWxTplAdminVo}
     * @memberof SystemGenerateObject53
     */
    'conditions': PartialWxTplAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject53
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject54
 */
export interface SystemGenerateObject54 {
    /**
     * 
     * @type {PartialWeappTplAdminVo}
     * @memberof SystemGenerateObject54
     */
    'conditions': PartialWeappTplAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject54
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject55
 */
export interface SystemGenerateObject55 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject55
     */
    'draftId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject55
     */
    'templateType': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject56
 */
export interface SystemGenerateObject56 {
    /**
     * 
     * @type {PartialDeviceAdminVo}
     * @memberof SystemGenerateObject56
     */
    'conditions': PartialDeviceAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject57
 */
export interface SystemGenerateObject57 {
    /**
     * 
     * @type {PartialDeviceAdminVo}
     * @memberof SystemGenerateObject57
     */
    'conditions': PartialDeviceAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject57
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject58
 */
export interface SystemGenerateObject58 {
    /**
     * 
     * @type {DeviceAdminVo}
     * @memberof SystemGenerateObject58
     */
    'deviceAdminVo': DeviceAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject59
 */
export interface SystemGenerateObject59 {
    /**
     * 
     * @type {DeviceAdminVo}
     * @memberof SystemGenerateObject59
     */
    'deviceAdminVo': DeviceAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject6
 */
export interface SystemGenerateObject6 {
    /**
     * 
     * @type {PartialBaseRouteAdminVo}
     * @memberof SystemGenerateObject6
     */
    'conditions': PartialBaseRouteAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject60
 */
export interface SystemGenerateObject60 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject60
     */
    'storeId': number;
    /**
     * 
     * @type {DEVICEMODE}
     * @memberof SystemGenerateObject60
     */
    'mod': DEVICEMODE;
}


/**
 * 
 * @export
 * @interface SystemGenerateObject61
 */
export interface SystemGenerateObject61 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject61
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject62
 */
export interface SystemGenerateObject62 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject62
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject63
 */
export interface SystemGenerateObject63 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject63
     */
    'nameOrdeviceIdHex'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject64
 */
export interface SystemGenerateObject64 {
    /**
     * 
     * @type {StoreOwnerVo}
     * @memberof SystemGenerateObject64
     */
    'storeOwnerVo': StoreOwnerVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject65
 */
export interface SystemGenerateObject65 {
    /**
     * 
     * @type {StoreOwnerVo}
     * @memberof SystemGenerateObject65
     */
    'storeOwnerVo': StoreOwnerVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject66
 */
export interface SystemGenerateObject66 {
    /**
     * 
     * @type {PartialStoreAdminVo}
     * @memberof SystemGenerateObject66
     */
    'conditions': PartialStoreAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject67
 */
export interface SystemGenerateObject67 {
    /**
     * 
     * @type {PartialStoreAdminVo}
     * @memberof SystemGenerateObject67
     */
    'conditions': PartialStoreAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject67
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject68
 */
export interface SystemGenerateObject68 {
    /**
     * 
     * @type {StoreAdminVo}
     * @memberof SystemGenerateObject68
     */
    'storeAdminVo': StoreAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject69
 */
export interface SystemGenerateObject69 {
    /**
     * 
     * @type {StoreAdminVo}
     * @memberof SystemGenerateObject69
     */
    'storeAdminVo': StoreAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject7
 */
export interface SystemGenerateObject7 {
    /**
     * 
     * @type {PartialBaseRouteAdminVo}
     * @memberof SystemGenerateObject7
     */
    'conditions': PartialBaseRouteAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject7
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject70
 */
export interface SystemGenerateObject70 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject70
     */
    'longtitude': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject70
     */
    'latitude': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject71
 */
export interface SystemGenerateObject71 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject71
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject72
 */
export interface SystemGenerateObject72 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject72
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject73
 */
export interface SystemGenerateObject73 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject73
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject74
 */
export interface SystemGenerateObject74 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject74
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject74
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject75
 */
export interface SystemGenerateObject75 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject75
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject75
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject76
 */
export interface SystemGenerateObject76 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject76
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject76
     */
    'h5TplId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject77
 */
export interface SystemGenerateObject77 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject77
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject78
 */
export interface SystemGenerateObject78 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject78
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject78
     */
    'appid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject79
 */
export interface SystemGenerateObject79 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject79
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject79
     */
    'h5TplId': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject8
 */
export interface SystemGenerateObject8 {
    /**
     * 
     * @type {PartialBaseScheduleAdminVo}
     * @memberof SystemGenerateObject8
     */
    'conditions': PartialBaseScheduleAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject8
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject80
 */
export interface SystemGenerateObject80 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject80
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject81
 */
export interface SystemGenerateObject81 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject81
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject81
     */
    'qrcodeUrl': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject82
 */
export interface SystemGenerateObject82 {
    /**
     * 
     * @type {PartialSubAccountAdminVo}
     * @memberof SystemGenerateObject82
     */
    'conditions': PartialSubAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject83
 */
export interface SystemGenerateObject83 {
    /**
     * 
     * @type {PartialSubAccountAdminVo}
     * @memberof SystemGenerateObject83
     */
    'conditions': PartialSubAccountAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject83
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject84
 */
export interface SystemGenerateObject84 {
    /**
     * 
     * @type {SubAccountAdminVo}
     * @memberof SystemGenerateObject84
     */
    'subAccountAdminVo': SubAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject85
 */
export interface SystemGenerateObject85 {
    /**
     * 
     * @type {SubAccountAdminVo}
     * @memberof SystemGenerateObject85
     */
    'subAccountAdminVo': SubAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject86
 */
export interface SystemGenerateObject86 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject86
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject86
     */
    'remarkOrPhone'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject87
 */
export interface SystemGenerateObject87 {
    /**
     * 
     * @type {SubAccountAdminVo}
     * @memberof SystemGenerateObject87
     */
    'subAccountAdminVo': SubAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject88
 */
export interface SystemGenerateObject88 {
    /**
     * 
     * @type {SubAccountAdminVo}
     * @memberof SystemGenerateObject88
     */
    'subAccountAdminVo': SubAccountAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject89
 */
export interface SystemGenerateObject89 {
    /**
     * 
     * @type {PartialAdminVo}
     * @memberof SystemGenerateObject89
     */
    'conditions': PartialAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject90
 */
export interface SystemGenerateObject90 {
    /**
     * 
     * @type {PartialAdminVo}
     * @memberof SystemGenerateObject90
     */
    'conditions': PartialAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject90
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject91
 */
export interface SystemGenerateObject91 {
    /**
     * 
     * @type {AdminVo}
     * @memberof SystemGenerateObject91
     */
    'adminAdminVo': AdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject92
 */
export interface SystemGenerateObject92 {
    /**
     * 
     * @type {AdminVo}
     * @memberof SystemGenerateObject92
     */
    'adminAdminVo': AdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject93
 */
export interface SystemGenerateObject93 {
    /**
     * 
     * @type {PartialFacilitatorAdminVo}
     * @memberof SystemGenerateObject93
     */
    'conditions': PartialFacilitatorAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject94
 */
export interface SystemGenerateObject94 {
    /**
     * 
     * @type {PartialFacilitatorAdminVo}
     * @memberof SystemGenerateObject94
     */
    'conditions': PartialFacilitatorAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject94
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject95
 */
export interface SystemGenerateObject95 {
    /**
     * 
     * @type {FacilitatorAdminVo}
     * @memberof SystemGenerateObject95
     */
    'facilitatorAdminVo': FacilitatorAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject96
 */
export interface SystemGenerateObject96 {
    /**
     * 
     * @type {FacilitatorAdminVo}
     * @memberof SystemGenerateObject96
     */
    'facilitatorAdminVo': FacilitatorAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject97
 */
export interface SystemGenerateObject97 {
    /**
     * 
     * @type {PartialMessageAdminVo}
     * @memberof SystemGenerateObject97
     */
    'conditions': PartialMessageAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject98
 */
export interface SystemGenerateObject98 {
    /**
     * 
     * @type {PartialMessageAdminVo}
     * @memberof SystemGenerateObject98
     */
    'conditions': PartialMessageAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject98
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject99
 */
export interface SystemGenerateObject99 {
    /**
     * 
     * @type {MessageAdminVo}
     * @memberof SystemGenerateObject99
     */
    'messageAdminVo': MessageAdminVo;
}
/**
 * 
 * @export
 * @interface TenantAdminVo
 */
export interface TenantAdminVo {
    /**
     * 
     * @type {number}
     * @memberof TenantAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantAdminVo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof TenantAdminVo
     */
    'phone': number;
    /**
     * 
     * @type {string}
     * @memberof TenantAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAdminVo
     */
    'logo'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TenantAdminVo
     */
    'weappTplIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TenantAdminVo
     */
    'wxTplIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof TenantAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface WeappAdminVo
 */
export interface WeappAdminVo {
    /**
     * 
     * @type {number}
     * @memberof WeappAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WeappAdminVo
     */
    'appid': string;
    /**
     * 
     * @type {string}
     * @memberof WeappAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WeappAdminVo
     */
    'principalName': string;
    /**
     * 
     * @type {string}
     * @memberof WeappAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WeappAdminVo
     */
    'baseSetting'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof WeappAdminVo
     */
    'privacyInterface'?: any;
    /**
     * 
     * @type {number}
     * @memberof WeappAdminVo
     */
    'templateId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WeappAdminVo
     */
    'auditId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WeappAdminVo
     */
    'currentVersion'?: string;
    /**
     * 
     * @type {PUBLISHSTATUSE}
     * @memberof WeappAdminVo
     */
    'status'?: PUBLISHSTATUSE;
    /**
     * 
     * @type {any}
     * @memberof WeappAdminVo
     */
    'ext'?: any;
    /**
     * 
     * @type {string}
     * @memberof WeappAdminVo
     */
    'createTime'?: string;
}


/**
 * 
 * @export
 * @interface WeappTplAdminVo
 */
export interface WeappTplAdminVo {
    /**
     * 
     * @type {number}
     * @memberof WeappTplAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WeappTplAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WeappTplAdminVo
     */
    'currentVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeappTplAdminVo
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof WeappTplAdminVo
     */
    'templateId'?: number;
    /**
     * 
     * @type {any}
     * @memberof WeappTplAdminVo
     */
    'extjsFormItems'?: any;
    /**
     * 
     * @type {string}
     * @memberof WeappTplAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface WhiteAccountAdminVo
 */
export interface WhiteAccountAdminVo {
    /**
     * 
     * @type {number}
     * @memberof WhiteAccountAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WhiteAccountAdminVo
     */
    'phone': number;
    /**
     * 
     * @type {number}
     * @memberof WhiteAccountAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {boolean}
     * @memberof WhiteAccountAdminVo
     */
    'isApplay'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WhiteAccountAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof WhiteAccountAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface WxAdminVo
 */
export interface WxAdminVo {
    /**
     * 
     * @type {number}
     * @memberof WxAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WxAdminVo
     */
    'appid': string;
    /**
     * 
     * @type {string}
     * @memberof WxAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WxAdminVo
     */
    'principalName': string;
    /**
     * 
     * @type {string}
     * @memberof WxAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAdminVo
     */
    'openMsgTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAdminVo
     */
    'applyTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAdminVo
     */
    'weappAppid'?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface WxTplAdminVo
 */
export interface WxTplAdminVo {
    /**
     * 
     * @type {number}
     * @memberof WxTplAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WxTplAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WxTplAdminVo
     */
    'currentVersion': string;
    /**
     * 
     * @type {string}
     * @memberof WxTplAdminVo
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof WxTplAdminVo
     */
    'createTime'?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加管理员
         * @param {SystemGenerateObject91} systemGenerateObject91 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminadmin: async (systemGenerateObject91: SystemGenerateObject91, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject91' is not null or undefined
            assertParamExists('addAdminadmin', 'systemGenerateObject91', systemGenerateObject91)
            const localVarPath = `/admin/addAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject91, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除管理员
         * @param {number} adminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delAdminadmin: async (adminId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminId' is not null or undefined
            assertParamExists('delAdminadmin', 'adminId', adminId)
            const localVarPath = `/admin/delAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (adminId !== undefined) {
                localVarQueryParameter['adminId'] = adminId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取管理员列表
         * @param {SystemGenerateObject89} systemGenerateObject89 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminadmin: async (systemGenerateObject89: SystemGenerateObject89, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject89' is not null or undefined
            assertParamExists('listAdminadmin', 'systemGenerateObject89', systemGenerateObject89)
            const localVarPath = `/admin/listAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject89, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取管理员列表(分页)
         * @param {SystemGenerateObject90} systemGenerateObject90 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAdminadmin: async (systemGenerateObject90: SystemGenerateObject90, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject90' is not null or undefined
            assertParamExists('pageAdminadmin', 'systemGenerateObject90', systemGenerateObject90)
            const localVarPath = `/admin/pageAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject90, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新管理员
         * @param {SystemGenerateObject92} systemGenerateObject92 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminadmin: async (systemGenerateObject92: SystemGenerateObject92, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject92' is not null or undefined
            assertParamExists('updateAdminadmin', 'systemGenerateObject92', systemGenerateObject92)
            const localVarPath = `/admin/updateAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject92, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加管理员
         * @param {SystemGenerateObject91} systemGenerateObject91 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAdminadmin(systemGenerateObject91: SystemGenerateObject91, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAdminadmin(systemGenerateObject91, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除管理员
         * @param {number} adminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delAdminadmin(adminId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delAdminadmin(adminId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取管理员列表
         * @param {SystemGenerateObject89} systemGenerateObject89 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdminadmin(systemGenerateObject89: SystemGenerateObject89, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdminadmin(systemGenerateObject89, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取管理员列表(分页)
         * @param {SystemGenerateObject90} systemGenerateObject90 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageAdminadmin(systemGenerateObject90: SystemGenerateObject90, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageAdminadmin(systemGenerateObject90, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新管理员
         * @param {SystemGenerateObject92} systemGenerateObject92 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminadmin(systemGenerateObject92: SystemGenerateObject92, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminadmin(systemGenerateObject92, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加管理员
         * @param {SystemGenerateObject91} systemGenerateObject91 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminadmin(systemGenerateObject91: SystemGenerateObject91, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addAdminadmin(systemGenerateObject91, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除管理员
         * @param {number} adminId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delAdminadmin(adminId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delAdminadmin(adminId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取管理员列表
         * @param {SystemGenerateObject89} systemGenerateObject89 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminadmin(systemGenerateObject89: SystemGenerateObject89, options?: any): AxiosPromise<any> {
            return localVarFp.listAdminadmin(systemGenerateObject89, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取管理员列表(分页)
         * @param {SystemGenerateObject90} systemGenerateObject90 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAdminadmin(systemGenerateObject90: SystemGenerateObject90, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageAdminadmin(systemGenerateObject90, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新管理员
         * @param {SystemGenerateObject92} systemGenerateObject92 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminadmin(systemGenerateObject92: SystemGenerateObject92, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateAdminadmin(systemGenerateObject92, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary 添加管理员
     * @param {SystemGenerateObject91} systemGenerateObject91 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addAdminadmin(systemGenerateObject91: SystemGenerateObject91, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).addAdminadmin(systemGenerateObject91, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除管理员
     * @param {number} adminId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public delAdminadmin(adminId: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).delAdminadmin(adminId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取管理员列表
     * @param {SystemGenerateObject89} systemGenerateObject89 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listAdminadmin(systemGenerateObject89: SystemGenerateObject89, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).listAdminadmin(systemGenerateObject89, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取管理员列表(分页)
     * @param {SystemGenerateObject90} systemGenerateObject90 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public pageAdminadmin(systemGenerateObject90: SystemGenerateObject90, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).pageAdminadmin(systemGenerateObject90, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新管理员
     * @param {SystemGenerateObject92} systemGenerateObject92 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateAdminadmin(systemGenerateObject92: SystemGenerateObject92, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updateAdminadmin(systemGenerateObject92, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdvertisingApi - axios parameter creator
 * @export
 */
export const AdvertisingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加广告行
         * @param {SystemGenerateObject130} systemGenerateObject130 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdvertisingRowadvertising: async (systemGenerateObject130: SystemGenerateObject130, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject130' is not null or undefined
            assertParamExists('addAdvertisingRowadvertising', 'systemGenerateObject130', systemGenerateObject130)
            const localVarPath = `/advertising/addAdvertisingRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject130, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加广告
         * @param {SystemGenerateObject127} systemGenerateObject127 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdvertisingadvertising: async (systemGenerateObject127: SystemGenerateObject127, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject127' is not null or undefined
            assertParamExists('addAdvertisingadvertising', 'systemGenerateObject127', systemGenerateObject127)
            const localVarPath = `/advertising/addAdvertising`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject127, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除广告行
         * @param {number} advertisingRowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delAdvertisingRowadvertising: async (advertisingRowId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertisingRowId' is not null or undefined
            assertParamExists('delAdvertisingRowadvertising', 'advertisingRowId', advertisingRowId)
            const localVarPath = `/advertising/delAdvertisingRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (advertisingRowId !== undefined) {
                localVarQueryParameter['advertisingRowId'] = advertisingRowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除广告
         * @param {number} advertisingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delAdvertisingadvertising: async (advertisingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertisingId' is not null or undefined
            assertParamExists('delAdvertisingadvertising', 'advertisingId', advertisingId)
            const localVarPath = `/advertising/delAdvertising`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (advertisingId !== undefined) {
                localVarQueryParameter['advertisingId'] = advertisingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取广告行列表
         * @param {SystemGenerateObject129} systemGenerateObject129 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvertisingRowadvertising: async (systemGenerateObject129: SystemGenerateObject129, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject129' is not null or undefined
            assertParamExists('listAdvertisingRowadvertising', 'systemGenerateObject129', systemGenerateObject129)
            const localVarPath = `/advertising/listAdvertisingRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject129, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取广告列表
         * @param {SystemGenerateObject126} systemGenerateObject126 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvertisingadvertising: async (systemGenerateObject126: SystemGenerateObject126, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject126' is not null or undefined
            assertParamExists('listAdvertisingadvertising', 'systemGenerateObject126', systemGenerateObject126)
            const localVarPath = `/advertising/listAdvertising`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject126, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 移动广告行
         * @param {SystemGenerateObject133} systemGenerateObject133 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveAdvertisingRowadvertising: async (systemGenerateObject133: SystemGenerateObject133, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject133' is not null or undefined
            assertParamExists('moveAdvertisingRowadvertising', 'systemGenerateObject133', systemGenerateObject133)
            const localVarPath = `/advertising/moveAdvertisingRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject133, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 移动广告
         * @param {SystemGenerateObject132} systemGenerateObject132 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveAdvertisingadvertising: async (systemGenerateObject132: SystemGenerateObject132, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject132' is not null or undefined
            assertParamExists('moveAdvertisingadvertising', 'systemGenerateObject132', systemGenerateObject132)
            const localVarPath = `/advertising/moveAdvertising`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject132, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新广告行
         * @param {SystemGenerateObject131} systemGenerateObject131 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvertisingRowadvertising: async (systemGenerateObject131: SystemGenerateObject131, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject131' is not null or undefined
            assertParamExists('updateAdvertisingRowadvertising', 'systemGenerateObject131', systemGenerateObject131)
            const localVarPath = `/advertising/updateAdvertisingRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject131, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新广告
         * @param {SystemGenerateObject128} systemGenerateObject128 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvertisingadvertising: async (systemGenerateObject128: SystemGenerateObject128, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject128' is not null or undefined
            assertParamExists('updateAdvertisingadvertising', 'systemGenerateObject128', systemGenerateObject128)
            const localVarPath = `/advertising/updateAdvertising`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject128, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertisingApi - functional programming interface
 * @export
 */
export const AdvertisingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertisingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加广告行
         * @param {SystemGenerateObject130} systemGenerateObject130 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAdvertisingRowadvertising(systemGenerateObject130: SystemGenerateObject130, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAdvertisingRowadvertising(systemGenerateObject130, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加广告
         * @param {SystemGenerateObject127} systemGenerateObject127 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAdvertisingadvertising(systemGenerateObject127: SystemGenerateObject127, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAdvertisingadvertising(systemGenerateObject127, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除广告行
         * @param {number} advertisingRowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delAdvertisingRowadvertising(advertisingRowId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delAdvertisingRowadvertising(advertisingRowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除广告
         * @param {number} advertisingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delAdvertisingadvertising(advertisingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delAdvertisingadvertising(advertisingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取广告行列表
         * @param {SystemGenerateObject129} systemGenerateObject129 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdvertisingRowadvertising(systemGenerateObject129: SystemGenerateObject129, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdvertisingRowadvertising(systemGenerateObject129, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取广告列表
         * @param {SystemGenerateObject126} systemGenerateObject126 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdvertisingadvertising(systemGenerateObject126: SystemGenerateObject126, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdvertisingadvertising(systemGenerateObject126, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 移动广告行
         * @param {SystemGenerateObject133} systemGenerateObject133 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveAdvertisingRowadvertising(systemGenerateObject133: SystemGenerateObject133, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveAdvertisingRowadvertising(systemGenerateObject133, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 移动广告
         * @param {SystemGenerateObject132} systemGenerateObject132 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveAdvertisingadvertising(systemGenerateObject132: SystemGenerateObject132, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveAdvertisingadvertising(systemGenerateObject132, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新广告行
         * @param {SystemGenerateObject131} systemGenerateObject131 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdvertisingRowadvertising(systemGenerateObject131: SystemGenerateObject131, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdvertisingRowadvertising(systemGenerateObject131, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新广告
         * @param {SystemGenerateObject128} systemGenerateObject128 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdvertisingadvertising(systemGenerateObject128: SystemGenerateObject128, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdvertisingadvertising(systemGenerateObject128, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertisingApi - factory interface
 * @export
 */
export const AdvertisingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertisingApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加广告行
         * @param {SystemGenerateObject130} systemGenerateObject130 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdvertisingRowadvertising(systemGenerateObject130: SystemGenerateObject130, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addAdvertisingRowadvertising(systemGenerateObject130, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加广告
         * @param {SystemGenerateObject127} systemGenerateObject127 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdvertisingadvertising(systemGenerateObject127: SystemGenerateObject127, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addAdvertisingadvertising(systemGenerateObject127, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除广告行
         * @param {number} advertisingRowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delAdvertisingRowadvertising(advertisingRowId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delAdvertisingRowadvertising(advertisingRowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除广告
         * @param {number} advertisingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delAdvertisingadvertising(advertisingId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delAdvertisingadvertising(advertisingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取广告行列表
         * @param {SystemGenerateObject129} systemGenerateObject129 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvertisingRowadvertising(systemGenerateObject129: SystemGenerateObject129, options?: any): AxiosPromise<any> {
            return localVarFp.listAdvertisingRowadvertising(systemGenerateObject129, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取广告列表
         * @param {SystemGenerateObject126} systemGenerateObject126 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdvertisingadvertising(systemGenerateObject126: SystemGenerateObject126, options?: any): AxiosPromise<any> {
            return localVarFp.listAdvertisingadvertising(systemGenerateObject126, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 移动广告行
         * @param {SystemGenerateObject133} systemGenerateObject133 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveAdvertisingRowadvertising(systemGenerateObject133: SystemGenerateObject133, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.moveAdvertisingRowadvertising(systemGenerateObject133, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 移动广告
         * @param {SystemGenerateObject132} systemGenerateObject132 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveAdvertisingadvertising(systemGenerateObject132: SystemGenerateObject132, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.moveAdvertisingadvertising(systemGenerateObject132, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新广告行
         * @param {SystemGenerateObject131} systemGenerateObject131 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvertisingRowadvertising(systemGenerateObject131: SystemGenerateObject131, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateAdvertisingRowadvertising(systemGenerateObject131, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新广告
         * @param {SystemGenerateObject128} systemGenerateObject128 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdvertisingadvertising(systemGenerateObject128: SystemGenerateObject128, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateAdvertisingadvertising(systemGenerateObject128, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvertisingApi - object-oriented interface
 * @export
 * @class AdvertisingApi
 * @extends {BaseAPI}
 */
export class AdvertisingApi extends BaseAPI {
    /**
     * 
     * @summary 添加广告行
     * @param {SystemGenerateObject130} systemGenerateObject130 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public addAdvertisingRowadvertising(systemGenerateObject130: SystemGenerateObject130, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).addAdvertisingRowadvertising(systemGenerateObject130, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加广告
     * @param {SystemGenerateObject127} systemGenerateObject127 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public addAdvertisingadvertising(systemGenerateObject127: SystemGenerateObject127, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).addAdvertisingadvertising(systemGenerateObject127, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除广告行
     * @param {number} advertisingRowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public delAdvertisingRowadvertising(advertisingRowId: number, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).delAdvertisingRowadvertising(advertisingRowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除广告
     * @param {number} advertisingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public delAdvertisingadvertising(advertisingId: number, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).delAdvertisingadvertising(advertisingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取广告行列表
     * @param {SystemGenerateObject129} systemGenerateObject129 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public listAdvertisingRowadvertising(systemGenerateObject129: SystemGenerateObject129, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).listAdvertisingRowadvertising(systemGenerateObject129, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取广告列表
     * @param {SystemGenerateObject126} systemGenerateObject126 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public listAdvertisingadvertising(systemGenerateObject126: SystemGenerateObject126, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).listAdvertisingadvertising(systemGenerateObject126, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 移动广告行
     * @param {SystemGenerateObject133} systemGenerateObject133 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public moveAdvertisingRowadvertising(systemGenerateObject133: SystemGenerateObject133, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).moveAdvertisingRowadvertising(systemGenerateObject133, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 移动广告
     * @param {SystemGenerateObject132} systemGenerateObject132 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public moveAdvertisingadvertising(systemGenerateObject132: SystemGenerateObject132, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).moveAdvertisingadvertising(systemGenerateObject132, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新广告行
     * @param {SystemGenerateObject131} systemGenerateObject131 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public updateAdvertisingRowadvertising(systemGenerateObject131: SystemGenerateObject131, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).updateAdvertisingRowadvertising(systemGenerateObject131, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新广告
     * @param {SystemGenerateObject128} systemGenerateObject128 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisingApi
     */
    public updateAdvertisingadvertising(systemGenerateObject128: SystemGenerateObject128, options?: AxiosRequestConfig) {
        return AdvertisingApiFp(this.configuration).updateAdvertisingadvertising(systemGenerateObject128, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseApiApi - axios parameter creator
 * @export
 */
export const BaseApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取接口列表
         * @param {SystemGenerateObject1} systemGenerateObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApisBaseApi: async (systemGenerateObject1: SystemGenerateObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject1' is not null or undefined
            assertParamExists('listApisBaseApi', 'systemGenerateObject1', systemGenerateObject1)
            const localVarPath = `/__base/api/listApis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改接口
         * @param {BaseApiAdminVo} baseApiAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiBaseApi: async (baseApiAdminVo: BaseApiAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseApiAdminVo' is not null or undefined
            assertParamExists('updateApiBaseApi', 'baseApiAdminVo', baseApiAdminVo)
            const localVarPath = `/__base/api/updateApi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseApiAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseApiApi - functional programming interface
 * @export
 */
export const BaseApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取接口列表
         * @param {SystemGenerateObject1} systemGenerateObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listApisBaseApi(systemGenerateObject1: SystemGenerateObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listApisBaseApi(systemGenerateObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改接口
         * @param {BaseApiAdminVo} baseApiAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApiBaseApi(baseApiAdminVo: BaseApiAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApiBaseApi(baseApiAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseApiApi - factory interface
 * @export
 */
export const BaseApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseApiApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取接口列表
         * @param {SystemGenerateObject1} systemGenerateObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApisBaseApi(systemGenerateObject1: SystemGenerateObject1, options?: any): AxiosPromise<any> {
            return localVarFp.listApisBaseApi(systemGenerateObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改接口
         * @param {BaseApiAdminVo} baseApiAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiBaseApi(baseApiAdminVo: BaseApiAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateApiBaseApi(baseApiAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseApiApi - object-oriented interface
 * @export
 * @class BaseApiApi
 * @extends {BaseAPI}
 */
export class BaseApiApi extends BaseAPI {
    /**
     * 
     * @summary 获取接口列表
     * @param {SystemGenerateObject1} systemGenerateObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApiApi
     */
    public listApisBaseApi(systemGenerateObject1: SystemGenerateObject1, options?: AxiosRequestConfig) {
        return BaseApiApiFp(this.configuration).listApisBaseApi(systemGenerateObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改接口
     * @param {BaseApiAdminVo} baseApiAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApiApi
     */
    public updateApiBaseApi(baseApiAdminVo: BaseApiAdminVo, options?: AxiosRequestConfig) {
        return BaseApiApiFp(this.configuration).updateApiBaseApi(baseApiAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseDictionaryApi - axios parameter creator
 * @export
 */
export const BaseDictionaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDictionaryBaseDictionary: async (baseDictionaryAdminVo: BaseDictionaryAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseDictionaryAdminVo' is not null or undefined
            assertParamExists('addDictionaryBaseDictionary', 'baseDictionaryAdminVo', baseDictionaryAdminVo)
            const localVarPath = `/__base/dictionary/addDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseDictionaryAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除字典
         * @param {number} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDictionaryBaseDictionary: async (dictionaryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryId' is not null or undefined
            assertParamExists('delDictionaryBaseDictionary', 'dictionaryId', dictionaryId)
            const localVarPath = `/__base/dictionary/delDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dictionaryId !== undefined) {
                localVarQueryParameter['dictionaryId'] = dictionaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取单个字典
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryBaseDictionary: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getDictionaryBaseDictionary', 'key', key)
            const localVarPath = `/__base/dictionary/getDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取字典列表
         * @param {SystemGenerateObject22} systemGenerateObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDictionaryBaseDictionary: async (systemGenerateObject22: SystemGenerateObject22, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject22' is not null or undefined
            assertParamExists('listDictionaryBaseDictionary', 'systemGenerateObject22', systemGenerateObject22)
            const localVarPath = `/__base/dictionary/listDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject22, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取字典列表(分页)
         * @param {SystemGenerateObject23} systemGenerateObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDictionaryBaseDictionary: async (systemGenerateObject23: SystemGenerateObject23, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject23' is not null or undefined
            assertParamExists('pageDictionaryBaseDictionary', 'systemGenerateObject23', systemGenerateObject23)
            const localVarPath = `/__base/dictionary/pageDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject23, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDictionaryBaseDictionary: async (baseDictionaryAdminVo: BaseDictionaryAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseDictionaryAdminVo' is not null or undefined
            assertParamExists('updateDictionaryBaseDictionary', 'baseDictionaryAdminVo', baseDictionaryAdminVo)
            const localVarPath = `/__base/dictionary/updateDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseDictionaryAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseDictionaryApi - functional programming interface
 * @export
 */
export const BaseDictionaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseDictionaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDictionaryBaseDictionary(baseDictionaryAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除字典
         * @param {number} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delDictionaryBaseDictionary(dictionaryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delDictionaryBaseDictionary(dictionaryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取单个字典
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionaryBaseDictionary(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionaryBaseDictionary(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取字典列表
         * @param {SystemGenerateObject22} systemGenerateObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDictionaryBaseDictionary(systemGenerateObject22: SystemGenerateObject22, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDictionaryBaseDictionary(systemGenerateObject22, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取字典列表(分页)
         * @param {SystemGenerateObject23} systemGenerateObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageDictionaryBaseDictionary(systemGenerateObject23: SystemGenerateObject23, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageDictionaryBaseDictionary(systemGenerateObject23, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDictionaryBaseDictionary(baseDictionaryAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseDictionaryApi - factory interface
 * @export
 */
export const BaseDictionaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseDictionaryApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addDictionaryBaseDictionary(baseDictionaryAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除字典
         * @param {number} dictionaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDictionaryBaseDictionary(dictionaryId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delDictionaryBaseDictionary(dictionaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取单个字典
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryBaseDictionary(key: string, options?: any): AxiosPromise<any> {
            return localVarFp.getDictionaryBaseDictionary(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取字典列表
         * @param {SystemGenerateObject22} systemGenerateObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDictionaryBaseDictionary(systemGenerateObject22: SystemGenerateObject22, options?: any): AxiosPromise<any> {
            return localVarFp.listDictionaryBaseDictionary(systemGenerateObject22, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取字典列表(分页)
         * @param {SystemGenerateObject23} systemGenerateObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDictionaryBaseDictionary(systemGenerateObject23: SystemGenerateObject23, options?: any): AxiosPromise<any> {
            return localVarFp.pageDictionaryBaseDictionary(systemGenerateObject23, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改字典
         * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateDictionaryBaseDictionary(baseDictionaryAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseDictionaryApi - object-oriented interface
 * @export
 * @class BaseDictionaryApi
 * @extends {BaseAPI}
 */
export class BaseDictionaryApi extends BaseAPI {
    /**
     * 
     * @summary 添加字典
     * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public addDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).addDictionaryBaseDictionary(baseDictionaryAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除字典
     * @param {number} dictionaryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public delDictionaryBaseDictionary(dictionaryId: number, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).delDictionaryBaseDictionary(dictionaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取单个字典
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public getDictionaryBaseDictionary(key: string, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).getDictionaryBaseDictionary(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取字典列表
     * @param {SystemGenerateObject22} systemGenerateObject22 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public listDictionaryBaseDictionary(systemGenerateObject22: SystemGenerateObject22, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).listDictionaryBaseDictionary(systemGenerateObject22, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取字典列表(分页)
     * @param {SystemGenerateObject23} systemGenerateObject23 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public pageDictionaryBaseDictionary(systemGenerateObject23: SystemGenerateObject23, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).pageDictionaryBaseDictionary(systemGenerateObject23, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改字典
     * @param {BaseDictionaryAdminVo} baseDictionaryAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseDictionaryApi
     */
    public updateDictionaryBaseDictionary(baseDictionaryAdminVo: BaseDictionaryAdminVo, options?: AxiosRequestConfig) {
        return BaseDictionaryApiFp(this.configuration).updateDictionaryBaseDictionary(baseDictionaryAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseLogApi - axios parameter creator
 * @export
 */
export const BaseLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取某个id的位置
         * @param {SystemGenerateObject19} systemGenerateObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountBaseLog: async (systemGenerateObject19: SystemGenerateObject19, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject19' is not null or undefined
            assertParamExists('getCountBaseLog', 'systemGenerateObject19', systemGenerateObject19)
            const localVarPath = `/__base/log/getCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject19, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject20} systemGenerateObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogBaseLog: async (systemGenerateObject20: SystemGenerateObject20, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject20' is not null or undefined
            assertParamExists('listLogBaseLog', 'systemGenerateObject20', systemGenerateObject20)
            const localVarPath = `/__base/log/listLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject20, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject21} systemGenerateObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageLogBaseLog: async (systemGenerateObject21: SystemGenerateObject21, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject21' is not null or undefined
            assertParamExists('pageLogBaseLog', 'systemGenerateObject21', systemGenerateObject21)
            const localVarPath = `/__base/log/pageLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject21, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseLogAdminVo} baseLogAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogBaseLog: async (baseLogAdminVo: BaseLogAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseLogAdminVo' is not null or undefined
            assertParamExists('updateLogBaseLog', 'baseLogAdminVo', baseLogAdminVo)
            const localVarPath = `/__base/log/updateLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseLogAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseLogApi - functional programming interface
 * @export
 */
export const BaseLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取某个id的位置
         * @param {SystemGenerateObject19} systemGenerateObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountBaseLog(systemGenerateObject19: SystemGenerateObject19, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountBaseLog(systemGenerateObject19, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject20} systemGenerateObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLogBaseLog(systemGenerateObject20: SystemGenerateObject20, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLogBaseLog(systemGenerateObject20, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject21} systemGenerateObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageLogBaseLog(systemGenerateObject21: SystemGenerateObject21, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageLogBaseLog(systemGenerateObject21, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseLogAdminVo} baseLogAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLogBaseLog(baseLogAdminVo: BaseLogAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLogBaseLog(baseLogAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseLogApi - factory interface
 * @export
 */
export const BaseLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseLogApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取某个id的位置
         * @param {SystemGenerateObject19} systemGenerateObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountBaseLog(systemGenerateObject19: SystemGenerateObject19, options?: any): AxiosPromise<any> {
            return localVarFp.getCountBaseLog(systemGenerateObject19, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject20} systemGenerateObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogBaseLog(systemGenerateObject20: SystemGenerateObject20, options?: any): AxiosPromise<any> {
            return localVarFp.listLogBaseLog(systemGenerateObject20, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject21} systemGenerateObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageLogBaseLog(systemGenerateObject21: SystemGenerateObject21, options?: any): AxiosPromise<any> {
            return localVarFp.pageLogBaseLog(systemGenerateObject21, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseLogAdminVo} baseLogAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogBaseLog(baseLogAdminVo: BaseLogAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateLogBaseLog(baseLogAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseLogApi - object-oriented interface
 * @export
 * @class BaseLogApi
 * @extends {BaseAPI}
 */
export class BaseLogApi extends BaseAPI {
    /**
     * 
     * @summary 获取某个id的位置
     * @param {SystemGenerateObject19} systemGenerateObject19 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public getCountBaseLog(systemGenerateObject19: SystemGenerateObject19, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).getCountBaseLog(systemGenerateObject19, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表
     * @param {SystemGenerateObject20} systemGenerateObject20 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public listLogBaseLog(systemGenerateObject20: SystemGenerateObject20, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).listLogBaseLog(systemGenerateObject20, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表(分页)
     * @param {SystemGenerateObject21} systemGenerateObject21 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public pageLogBaseLog(systemGenerateObject21: SystemGenerateObject21, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).pageLogBaseLog(systemGenerateObject21, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改操作
     * @param {BaseLogAdminVo} baseLogAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public updateLogBaseLog(baseLogAdminVo: BaseLogAdminVo, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).updateLogBaseLog(baseLogAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseMenuApi - axios parameter creator
 * @export
 */
export const BaseMenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMenuBaseMenu: async (baseMenuAdminVo: BaseMenuAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseMenuAdminVo' is not null or undefined
            assertParamExists('addMenuBaseMenu', 'baseMenuAdminVo', baseMenuAdminVo)
            const localVarPath = `/__base/menu/addMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseMenuAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除菜单
         * @param {number} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMenuBaseMenu: async (menuId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuId' is not null or undefined
            assertParamExists('delMenuBaseMenu', 'menuId', menuId)
            const localVarPath = `/__base/menu/delMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (menuId !== undefined) {
                localVarQueryParameter['menuId'] = menuId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取动态菜单
         * @summary 获取动态菜单
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getsBaseMenu: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/menu/gets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取菜单列表
         * @param {SystemGenerateObject2} systemGenerateObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMenusBaseMenu: async (systemGenerateObject2: SystemGenerateObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject2' is not null or undefined
            assertParamExists('listMenusBaseMenu', 'systemGenerateObject2', systemGenerateObject2)
            const localVarPath = `/__base/menu/listMenus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取菜单列表(分页)
         * @param {SystemGenerateObject3} systemGenerateObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMenusBaseMenu: async (systemGenerateObject3: SystemGenerateObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject3' is not null or undefined
            assertParamExists('pageMenusBaseMenu', 'systemGenerateObject3', systemGenerateObject3)
            const localVarPath = `/__base/menu/pageMenus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuBaseMenu: async (baseMenuAdminVo: BaseMenuAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseMenuAdminVo' is not null or undefined
            assertParamExists('updateMenuBaseMenu', 'baseMenuAdminVo', baseMenuAdminVo)
            const localVarPath = `/__base/menu/updateMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseMenuAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseMenuApi - functional programming interface
 * @export
 */
export const BaseMenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseMenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMenuBaseMenu(baseMenuAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除菜单
         * @param {number} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delMenuBaseMenu(menuId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delMenuBaseMenu(menuId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取动态菜单
         * @summary 获取动态菜单
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getsBaseMenu(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getsBaseMenu(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取菜单列表
         * @param {SystemGenerateObject2} systemGenerateObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMenusBaseMenu(systemGenerateObject2: SystemGenerateObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMenusBaseMenu(systemGenerateObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取菜单列表(分页)
         * @param {SystemGenerateObject3} systemGenerateObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageMenusBaseMenu(systemGenerateObject3: SystemGenerateObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageMenusBaseMenu(systemGenerateObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMenuBaseMenu(baseMenuAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseMenuApi - factory interface
 * @export
 */
export const BaseMenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseMenuApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除菜单
         * @param {number} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMenuBaseMenu(menuId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delMenuBaseMenu(menuId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取动态菜单
         * @summary 获取动态菜单
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getsBaseMenu(options?: any): AxiosPromise<any> {
            return localVarFp.getsBaseMenu(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取菜单列表
         * @param {SystemGenerateObject2} systemGenerateObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMenusBaseMenu(systemGenerateObject2: SystemGenerateObject2, options?: any): AxiosPromise<any> {
            return localVarFp.listMenusBaseMenu(systemGenerateObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取菜单列表(分页)
         * @param {SystemGenerateObject3} systemGenerateObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMenusBaseMenu(systemGenerateObject3: SystemGenerateObject3, options?: any): AxiosPromise<any> {
            return localVarFp.pageMenusBaseMenu(systemGenerateObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseMenuApi - object-oriented interface
 * @export
 * @class BaseMenuApi
 * @extends {BaseAPI}
 */
export class BaseMenuApi extends BaseAPI {
    /**
     * 
     * @summary 添加菜单
     * @param {BaseMenuAdminVo} baseMenuAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public addMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).addMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除菜单
     * @param {number} menuId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public delMenuBaseMenu(menuId: number, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).delMenuBaseMenu(menuId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取动态菜单
     * @summary 获取动态菜单
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public getsBaseMenu(options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).getsBaseMenu(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取菜单列表
     * @param {SystemGenerateObject2} systemGenerateObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public listMenusBaseMenu(systemGenerateObject2: SystemGenerateObject2, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).listMenusBaseMenu(systemGenerateObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取菜单列表(分页)
     * @param {SystemGenerateObject3} systemGenerateObject3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public pageMenusBaseMenu(systemGenerateObject3: SystemGenerateObject3, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).pageMenusBaseMenu(systemGenerateObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改菜单
     * @param {BaseMenuAdminVo} baseMenuAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public updateMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).updateMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseOperateApi - axios parameter creator
 * @export
 */
export const BaseOperateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperateBaseOperate: async (baseOperateAdminVo: BaseOperateAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOperateAdminVo' is not null or undefined
            assertParamExists('addOperateBaseOperate', 'baseOperateAdminVo', baseOperateAdminVo)
            const localVarPath = `/__base/operate/addOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOperateAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除操作
         * @param {number} operateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOperateBaseOperate: async (operateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operateId' is not null or undefined
            assertParamExists('delOperateBaseOperate', 'operateId', operateId)
            const localVarPath = `/__base/operate/delOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (operateId !== undefined) {
                localVarQueryParameter['operateId'] = operateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户的操作列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatesBaseOperate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/operate/getOperates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject4} systemGenerateObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperateBaseOperate: async (systemGenerateObject4: SystemGenerateObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject4' is not null or undefined
            assertParamExists('listOperateBaseOperate', 'systemGenerateObject4', systemGenerateObject4)
            const localVarPath = `/__base/operate/listOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject5} systemGenerateObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOperateBaseOperate: async (systemGenerateObject5: SystemGenerateObject5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject5' is not null or undefined
            assertParamExists('pageOperateBaseOperate', 'systemGenerateObject5', systemGenerateObject5)
            const localVarPath = `/__base/operate/pageOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperateBaseOperate: async (baseOperateAdminVo: BaseOperateAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOperateAdminVo' is not null or undefined
            assertParamExists('updateOperateBaseOperate', 'baseOperateAdminVo', baseOperateAdminVo)
            const localVarPath = `/__base/operate/updateOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOperateAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseOperateApi - functional programming interface
 * @export
 */
export const BaseOperateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseOperateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOperateBaseOperate(baseOperateAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除操作
         * @param {number} operateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOperateBaseOperate(operateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOperateBaseOperate(operateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户的操作列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOperatesBaseOperate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOperatesBaseOperate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject4} systemGenerateObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOperateBaseOperate(systemGenerateObject4: SystemGenerateObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOperateBaseOperate(systemGenerateObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject5} systemGenerateObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageOperateBaseOperate(systemGenerateObject5: SystemGenerateObject5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageOperateBaseOperate(systemGenerateObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOperateBaseOperate(baseOperateAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseOperateApi - factory interface
 * @export
 */
export const BaseOperateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseOperateApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除操作
         * @param {number} operateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOperateBaseOperate(operateId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delOperateBaseOperate(operateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户的操作列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatesBaseOperate(options?: any): AxiosPromise<any> {
            return localVarFp.getOperatesBaseOperate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject4} systemGenerateObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperateBaseOperate(systemGenerateObject4: SystemGenerateObject4, options?: any): AxiosPromise<any> {
            return localVarFp.listOperateBaseOperate(systemGenerateObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject5} systemGenerateObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOperateBaseOperate(systemGenerateObject5: SystemGenerateObject5, options?: any): AxiosPromise<any> {
            return localVarFp.pageOperateBaseOperate(systemGenerateObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseOperateApi - object-oriented interface
 * @export
 * @class BaseOperateApi
 * @extends {BaseAPI}
 */
export class BaseOperateApi extends BaseAPI {
    /**
     * 
     * @summary 添加操作
     * @param {BaseOperateAdminVo} baseOperateAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public addOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).addOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除操作
     * @param {number} operateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public delOperateBaseOperate(operateId: number, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).delOperateBaseOperate(operateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户的操作列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public getOperatesBaseOperate(options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).getOperatesBaseOperate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表
     * @param {SystemGenerateObject4} systemGenerateObject4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public listOperateBaseOperate(systemGenerateObject4: SystemGenerateObject4, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).listOperateBaseOperate(systemGenerateObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表(分页)
     * @param {SystemGenerateObject5} systemGenerateObject5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public pageOperateBaseOperate(systemGenerateObject5: SystemGenerateObject5, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).pageOperateBaseOperate(systemGenerateObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改操作
     * @param {BaseOperateAdminVo} baseOperateAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public updateOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).updateOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseOrganizationApi - axios parameter creator
 * @export
 */
export const BaseOrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationBaseOrganization: async (baseOrganizationAdminVo: BaseOrganizationAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOrganizationAdminVo' is not null or undefined
            assertParamExists('addOrganizationBaseOrganization', 'baseOrganizationAdminVo', baseOrganizationAdminVo)
            const localVarPath = `/__base/organization/addOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOrganizationAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除组织
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOrganizationBaseOrganization: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('delOrganizationBaseOrganization', 'organizationId', organizationId)
            const localVarPath = `/__base/organization/delOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取组织列表
         * @param {SystemGenerateObject17} systemGenerateObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationsBaseOrganization: async (systemGenerateObject17: SystemGenerateObject17, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject17' is not null or undefined
            assertParamExists('listOrganizationsBaseOrganization', 'systemGenerateObject17', systemGenerateObject17)
            const localVarPath = `/__base/organization/listOrganizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject17, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取组织列表(分页)
         * @param {SystemGenerateObject18} systemGenerateObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOrganizationsBaseOrganization: async (systemGenerateObject18: SystemGenerateObject18, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject18' is not null or undefined
            assertParamExists('pageOrganizationsBaseOrganization', 'systemGenerateObject18', systemGenerateObject18)
            const localVarPath = `/__base/organization/pageOrganizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject18, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateOrganizationBaseOrganization: async (baseOrganizationAdminVo: BaseOrganizationAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOrganizationAdminVo' is not null or undefined
            assertParamExists('udpateOrganizationBaseOrganization', 'baseOrganizationAdminVo', baseOrganizationAdminVo)
            const localVarPath = `/__base/organization/udpateOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOrganizationAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseOrganizationApi - functional programming interface
 * @export
 */
export const BaseOrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseOrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganizationBaseOrganization(baseOrganizationAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除组织
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOrganizationBaseOrganization(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOrganizationBaseOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取组织列表
         * @param {SystemGenerateObject17} systemGenerateObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizationsBaseOrganization(systemGenerateObject17: SystemGenerateObject17, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizationsBaseOrganization(systemGenerateObject17, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取组织列表(分页)
         * @param {SystemGenerateObject18} systemGenerateObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageOrganizationsBaseOrganization(systemGenerateObject18: SystemGenerateObject18, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageOrganizationsBaseOrganization(systemGenerateObject18, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async udpateOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.udpateOrganizationBaseOrganization(baseOrganizationAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseOrganizationApi - factory interface
 * @export
 */
export const BaseOrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseOrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除组织
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOrganizationBaseOrganization(organizationId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delOrganizationBaseOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取组织列表
         * @param {SystemGenerateObject17} systemGenerateObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationsBaseOrganization(systemGenerateObject17: SystemGenerateObject17, options?: any): AxiosPromise<any> {
            return localVarFp.listOrganizationsBaseOrganization(systemGenerateObject17, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取组织列表(分页)
         * @param {SystemGenerateObject18} systemGenerateObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOrganizationsBaseOrganization(systemGenerateObject18: SystemGenerateObject18, options?: any): AxiosPromise<any> {
            return localVarFp.pageOrganizationsBaseOrganization(systemGenerateObject18, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.udpateOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseOrganizationApi - object-oriented interface
 * @export
 * @class BaseOrganizationApi
 * @extends {BaseAPI}
 */
export class BaseOrganizationApi extends BaseAPI {
    /**
     * 
     * @summary 添加组织
     * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public addOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).addOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除组织
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public delOrganizationBaseOrganization(organizationId: number, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).delOrganizationBaseOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取组织列表
     * @param {SystemGenerateObject17} systemGenerateObject17 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public listOrganizationsBaseOrganization(systemGenerateObject17: SystemGenerateObject17, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).listOrganizationsBaseOrganization(systemGenerateObject17, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取组织列表(分页)
     * @param {SystemGenerateObject18} systemGenerateObject18 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public pageOrganizationsBaseOrganization(systemGenerateObject18: SystemGenerateObject18, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).pageOrganizationsBaseOrganization(systemGenerateObject18, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改组织
     * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public udpateOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).udpateOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseRouteApi - axios parameter creator
 * @export
 */
export const BaseRouteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRouteBaseRoute: async (baseRouteAdminVo: BaseRouteAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRouteAdminVo' is not null or undefined
            assertParamExists('addRouteBaseRoute', 'baseRouteAdminVo', baseRouteAdminVo)
            const localVarPath = `/__base/route/addRoute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRouteAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除路由
         * @param {number} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoteBaseRoute: async (routeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeId' is not null or undefined
            assertParamExists('deleteRoteBaseRoute', 'routeId', routeId)
            const localVarPath = `/__base/route/deleteRote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (routeId !== undefined) {
                localVarQueryParameter['routeId'] = routeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取无需授权的动态路由
         * @summary 获取无需授权的动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoPermissionRoutesBaseRoute: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/route/getNoPermissionRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取动态路由
         * @summary 获取动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionRoutesBaseRoute: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/route/getPermissionRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取路由列表
         * @param {SystemGenerateObject6} systemGenerateObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoutesBaseRoute: async (systemGenerateObject6: SystemGenerateObject6, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject6' is not null or undefined
            assertParamExists('listRoutesBaseRoute', 'systemGenerateObject6', systemGenerateObject6)
            const localVarPath = `/__base/route/listRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取路由列表(分页)
         * @param {SystemGenerateObject7} systemGenerateObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRoutesBaseRoute: async (systemGenerateObject7: SystemGenerateObject7, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject7' is not null or undefined
            assertParamExists('pageRoutesBaseRoute', 'systemGenerateObject7', systemGenerateObject7)
            const localVarPath = `/__base/route/pageRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject7, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteBaseRoute: async (baseRouteAdminVo: BaseRouteAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRouteAdminVo' is not null or undefined
            assertParamExists('updateRouteBaseRoute', 'baseRouteAdminVo', baseRouteAdminVo)
            const localVarPath = `/__base/route/updateRoute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRouteAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseRouteApi - functional programming interface
 * @export
 */
export const BaseRouteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseRouteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRouteBaseRoute(baseRouteAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除路由
         * @param {number} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoteBaseRoute(routeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoteBaseRoute(routeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取无需授权的动态路由
         * @summary 获取无需授权的动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoPermissionRoutesBaseRoute(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoPermissionRoutesBaseRoute(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取动态路由
         * @summary 获取动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionRoutesBaseRoute(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionRoutesBaseRoute(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取路由列表
         * @param {SystemGenerateObject6} systemGenerateObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoutesBaseRoute(systemGenerateObject6: SystemGenerateObject6, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRoutesBaseRoute(systemGenerateObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取路由列表(分页)
         * @param {SystemGenerateObject7} systemGenerateObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageRoutesBaseRoute(systemGenerateObject7: SystemGenerateObject7, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageRoutesBaseRoute(systemGenerateObject7, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRouteBaseRoute(baseRouteAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseRouteApi - factory interface
 * @export
 */
export const BaseRouteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseRouteApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除路由
         * @param {number} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoteBaseRoute(routeId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.deleteRoteBaseRoute(routeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取无需授权的动态路由
         * @summary 获取无需授权的动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoPermissionRoutesBaseRoute(options?: any): AxiosPromise<any> {
            return localVarFp.getNoPermissionRoutesBaseRoute(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取动态路由
         * @summary 获取动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionRoutesBaseRoute(options?: any): AxiosPromise<any> {
            return localVarFp.getPermissionRoutesBaseRoute(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取路由列表
         * @param {SystemGenerateObject6} systemGenerateObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoutesBaseRoute(systemGenerateObject6: SystemGenerateObject6, options?: any): AxiosPromise<any> {
            return localVarFp.listRoutesBaseRoute(systemGenerateObject6, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取路由列表(分页)
         * @param {SystemGenerateObject7} systemGenerateObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRoutesBaseRoute(systemGenerateObject7: SystemGenerateObject7, options?: any): AxiosPromise<any> {
            return localVarFp.pageRoutesBaseRoute(systemGenerateObject7, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseRouteApi - object-oriented interface
 * @export
 * @class BaseRouteApi
 * @extends {BaseAPI}
 */
export class BaseRouteApi extends BaseAPI {
    /**
     * 
     * @summary 添加路由
     * @param {BaseRouteAdminVo} baseRouteAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public addRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).addRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除路由
     * @param {number} routeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public deleteRoteBaseRoute(routeId: number, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).deleteRoteBaseRoute(routeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取无需授权的动态路由
     * @summary 获取无需授权的动态路由
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public getNoPermissionRoutesBaseRoute(options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).getNoPermissionRoutesBaseRoute(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取动态路由
     * @summary 获取动态路由
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public getPermissionRoutesBaseRoute(options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).getPermissionRoutesBaseRoute(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取路由列表
     * @param {SystemGenerateObject6} systemGenerateObject6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public listRoutesBaseRoute(systemGenerateObject6: SystemGenerateObject6, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).listRoutesBaseRoute(systemGenerateObject6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取路由列表(分页)
     * @param {SystemGenerateObject7} systemGenerateObject7 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public pageRoutesBaseRoute(systemGenerateObject7: SystemGenerateObject7, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).pageRoutesBaseRoute(systemGenerateObject7, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改路由
     * @param {BaseRouteAdminVo} baseRouteAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public updateRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).updateRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseScheduleApi - axios parameter creator
 * @export
 */
export const BaseScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 删除任务
         * @param {string} scheduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleBaseSchedule: async (scheduleName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleName' is not null or undefined
            assertParamExists('deleteScheduleBaseSchedule', 'scheduleName', scheduleName)
            const localVarPath = `/__base/schedule/deleteSchedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scheduleName !== undefined) {
                localVarQueryParameter['scheduleName'] = scheduleName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取任务列表(分页)
         * @param {SystemGenerateObject8} systemGenerateObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSchedulesBaseSchedule: async (systemGenerateObject8: SystemGenerateObject8, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject8' is not null or undefined
            assertParamExists('pageSchedulesBaseSchedule', 'systemGenerateObject8', systemGenerateObject8)
            const localVarPath = `/__base/schedule/pageSchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject8, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseScheduleApi - functional programming interface
 * @export
 */
export const BaseScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 删除任务
         * @param {string} scheduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScheduleBaseSchedule(scheduleName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScheduleBaseSchedule(scheduleName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取任务列表(分页)
         * @param {SystemGenerateObject8} systemGenerateObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageSchedulesBaseSchedule(systemGenerateObject8: SystemGenerateObject8, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageSchedulesBaseSchedule(systemGenerateObject8, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseScheduleApi - factory interface
 * @export
 */
export const BaseScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseScheduleApiFp(configuration)
    return {
        /**
         * 
         * @summary 删除任务
         * @param {string} scheduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleBaseSchedule(scheduleName: string, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.deleteScheduleBaseSchedule(scheduleName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取任务列表(分页)
         * @param {SystemGenerateObject8} systemGenerateObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSchedulesBaseSchedule(systemGenerateObject8: SystemGenerateObject8, options?: any): AxiosPromise<any> {
            return localVarFp.pageSchedulesBaseSchedule(systemGenerateObject8, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseScheduleApi - object-oriented interface
 * @export
 * @class BaseScheduleApi
 * @extends {BaseAPI}
 */
export class BaseScheduleApi extends BaseAPI {
    /**
     * 
     * @summary 删除任务
     * @param {string} scheduleName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseScheduleApi
     */
    public deleteScheduleBaseSchedule(scheduleName: string, options?: AxiosRequestConfig) {
        return BaseScheduleApiFp(this.configuration).deleteScheduleBaseSchedule(scheduleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取任务列表(分页)
     * @param {SystemGenerateObject8} systemGenerateObject8 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseScheduleApi
     */
    public pageSchedulesBaseSchedule(systemGenerateObject8: SystemGenerateObject8, options?: AxiosRequestConfig) {
        return BaseScheduleApiFp(this.configuration).pageSchedulesBaseSchedule(systemGenerateObject8, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseTenantApi - axios parameter creator
 * @export
 */
export const BaseTenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBaseTenantBaseTenant: async (baseTenantAdminVo: BaseTenantAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseTenantAdminVo' is not null or undefined
            assertParamExists('addBaseTenantBaseTenant', 'baseTenantAdminVo', baseTenantAdminVo)
            const localVarPath = `/__base/tenant/addBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseTenantAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除租户
         * @param {number} baseTenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delBaseTenantBaseTenant: async (baseTenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseTenantId' is not null or undefined
            assertParamExists('delBaseTenantBaseTenant', 'baseTenantId', baseTenantId)
            const localVarPath = `/__base/tenant/delBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (baseTenantId !== undefined) {
                localVarQueryParameter['baseTenantId'] = baseTenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户信息（公共）
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPublicBaseTenant: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenantPublicBaseTenant', 'id', id)
            const localVarPath = `/__base/tenant/getTenantPublic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户列表
         * @param {PartialBaseTenantAdminVo} partialBaseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBaseTenantBaseTenant: async (partialBaseTenantAdminVo: PartialBaseTenantAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partialBaseTenantAdminVo' is not null or undefined
            assertParamExists('listBaseTenantBaseTenant', 'partialBaseTenantAdminVo', partialBaseTenantAdminVo)
            const localVarPath = `/__base/tenant/listBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialBaseTenantAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户列表(分页)
         * @param {SystemGenerateObject24} systemGenerateObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBaseTenantBaseTenant: async (systemGenerateObject24: SystemGenerateObject24, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject24' is not null or undefined
            assertParamExists('pageBaseTenantBaseTenant', 'systemGenerateObject24', systemGenerateObject24)
            const localVarPath = `/__base/tenant/pageBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject24, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBaseTenantBaseTenant: async (baseTenantAdminVo: BaseTenantAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseTenantAdminVo' is not null or undefined
            assertParamExists('updateBaseTenantBaseTenant', 'baseTenantAdminVo', baseTenantAdminVo)
            const localVarPath = `/__base/tenant/updateBaseTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseTenantAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseTenantApi - functional programming interface
 * @export
 */
export const BaseTenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseTenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBaseTenantBaseTenant(baseTenantAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除租户
         * @param {number} baseTenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delBaseTenantBaseTenant(baseTenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delBaseTenantBaseTenant(baseTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户信息（公共）
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantPublicBaseTenant(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantPublicBaseTenant(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户列表
         * @param {PartialBaseTenantAdminVo} partialBaseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBaseTenantBaseTenant(partialBaseTenantAdminVo: PartialBaseTenantAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBaseTenantBaseTenant(partialBaseTenantAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户列表(分页)
         * @param {SystemGenerateObject24} systemGenerateObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageBaseTenantBaseTenant(systemGenerateObject24: SystemGenerateObject24, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageBaseTenantBaseTenant(systemGenerateObject24, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBaseTenantBaseTenant(baseTenantAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseTenantApi - factory interface
 * @export
 */
export const BaseTenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseTenantApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addBaseTenantBaseTenant(baseTenantAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除租户
         * @param {number} baseTenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delBaseTenantBaseTenant(baseTenantId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delBaseTenantBaseTenant(baseTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户信息（公共）
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantPublicBaseTenant(id: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.getTenantPublicBaseTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户列表
         * @param {PartialBaseTenantAdminVo} partialBaseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBaseTenantBaseTenant(partialBaseTenantAdminVo: PartialBaseTenantAdminVo, options?: any): AxiosPromise<any> {
            return localVarFp.listBaseTenantBaseTenant(partialBaseTenantAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户列表(分页)
         * @param {SystemGenerateObject24} systemGenerateObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBaseTenantBaseTenant(systemGenerateObject24: SystemGenerateObject24, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.pageBaseTenantBaseTenant(systemGenerateObject24, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新租户
         * @param {BaseTenantAdminVo} baseTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateBaseTenantBaseTenant(baseTenantAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseTenantApi - object-oriented interface
 * @export
 * @class BaseTenantApi
 * @extends {BaseAPI}
 */
export class BaseTenantApi extends BaseAPI {
    /**
     * 
     * @summary 添加租户
     * @param {BaseTenantAdminVo} baseTenantAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public addBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).addBaseTenantBaseTenant(baseTenantAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除租户
     * @param {number} baseTenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public delBaseTenantBaseTenant(baseTenantId: number, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).delBaseTenantBaseTenant(baseTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户信息（公共）
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public getTenantPublicBaseTenant(id: number, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).getTenantPublicBaseTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户列表
     * @param {PartialBaseTenantAdminVo} partialBaseTenantAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public listBaseTenantBaseTenant(partialBaseTenantAdminVo: PartialBaseTenantAdminVo, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).listBaseTenantBaseTenant(partialBaseTenantAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户列表(分页)
     * @param {SystemGenerateObject24} systemGenerateObject24 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public pageBaseTenantBaseTenant(systemGenerateObject24: SystemGenerateObject24, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).pageBaseTenantBaseTenant(systemGenerateObject24, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新租户
     * @param {BaseTenantAdminVo} baseTenantAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseTenantApi
     */
    public updateBaseTenantBaseTenant(baseTenantAdminVo: BaseTenantAdminVo, options?: AxiosRequestConfig) {
        return BaseTenantApiFp(this.configuration).updateBaseTenantBaseTenant(baseTenantAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseUserApi - axios parameter creator
 * @export
 */
export const BaseUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleBaseUser: async (baseRoleAdminVo: BaseRoleAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRoleAdminVo' is not null or undefined
            assertParamExists('addRoleBaseUser', 'baseRoleAdminVo', baseRoleAdminVo)
            const localVarPath = `/__base/user/addRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRoleAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserBaseUser: async (baseUserAdminVo: BaseUserAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUserAdminVo' is not null or undefined
            assertParamExists('addUserBaseUser', 'baseUserAdminVo', baseUserAdminVo)
            const localVarPath = `/__base/user/addUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除角色
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delRoleBaseUser: async (roleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('delRoleBaseUser', 'roleId', roleId)
            const localVarPath = `/__base/user/delRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除用户
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserBaseUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserBaseUser', 'userId', userId)
            const localVarPath = `/__base/user/deleteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取用户信息
         * @summary 获取用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoBaseUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/user/getUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取组织机构下角色列表
         * @param {string} orgCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgRolesBaseUser: async (orgCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgCode' is not null or undefined
            assertParamExists('listOrgRolesBaseUser', 'orgCode', orgCode)
            const localVarPath = `/__base/user/listOrgRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgCode !== undefined) {
                localVarQueryParameter['orgCode'] = orgCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取角色列表
         * @param {SystemGenerateObject12} systemGenerateObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRolesBaseUser: async (systemGenerateObject12: SystemGenerateObject12, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject12' is not null or undefined
            assertParamExists('listRolesBaseUser', 'systemGenerateObject12', systemGenerateObject12)
            const localVarPath = `/__base/user/listRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject12, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取系统角色列表
         * @param {SystemGenerateObject10} systemGenerateObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSystemRolesBaseUser: async (systemGenerateObject10: SystemGenerateObject10, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject10' is not null or undefined
            assertParamExists('listSystemRolesBaseUser', 'systemGenerateObject10', systemGenerateObject10)
            const localVarPath = `/__base/user/listSystemRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject10, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询用户的组织角色列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOrgRolesBaseUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listUserOrgRolesBaseUser', 'userId', userId)
            const localVarPath = `/__base/user/listUserOrgRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户列表
         * @param {SystemGenerateObject14} systemGenerateObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersBaseUser: async (systemGenerateObject14: SystemGenerateObject14, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject14' is not null or undefined
            assertParamExists('listUsersBaseUser', 'systemGenerateObject14', systemGenerateObject14)
            const localVarPath = `/__base/user/listUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject14, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 登录
         * @summary 登录
         * @param {string} userNameOrPhone 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginBaseUser: async (userNameOrPhone: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNameOrPhone' is not null or undefined
            assertParamExists('loginBaseUser', 'userNameOrPhone', userNameOrPhone)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginBaseUser', 'password', password)
            const localVarPath = `/__base/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (userNameOrPhone !== undefined) { 
                localVarFormParams.set('userNameOrPhone', userNameOrPhone as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 登出
         * @summary 登出
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBaseUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取角色列表(分页)
         * @param {SystemGenerateObject13} systemGenerateObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRolesBaseUser: async (systemGenerateObject13: SystemGenerateObject13, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject13' is not null or undefined
            assertParamExists('pageRolesBaseUser', 'systemGenerateObject13', systemGenerateObject13)
            const localVarPath = `/__base/user/pageRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject13, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户列表(分页)
         * @param {SystemGenerateObject15} systemGenerateObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageUsersBaseUser: async (systemGenerateObject15: SystemGenerateObject15, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject15' is not null or undefined
            assertParamExists('pageUsersBaseUser', 'systemGenerateObject15', systemGenerateObject15)
            const localVarPath = `/__base/user/pageUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject15, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateRoleBaseUser: async (baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRoleAdminVo' is not null or undefined
            assertParamExists('udpateRoleBaseUser', 'baseRoleAdminVo', baseRoleAdminVo)
            const localVarPath = `/__base/user/udpateRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRoleAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserBaseUser: async (baseUserAdminVo: BaseUserAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUserAdminVo' is not null or undefined
            assertParamExists('updateUserBaseUser', 'baseUserAdminVo', baseUserAdminVo)
            const localVarPath = `/__base/user/updateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新用户的组织角色列表
         * @param {SystemGenerateObject11} systemGenerateObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOrgRolesBaseUser: async (systemGenerateObject11: SystemGenerateObject11, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject11' is not null or undefined
            assertParamExists('updateUserOrgRolesBaseUser', 'systemGenerateObject11', systemGenerateObject11)
            const localVarPath = `/__base/user/updateUserOrgRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject11, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改用户密码
         * @param {SystemGenerateObject16} systemGenerateObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPWBaseUser: async (systemGenerateObject16: SystemGenerateObject16, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject16' is not null or undefined
            assertParamExists('updateUserPWBaseUser', 'systemGenerateObject16', systemGenerateObject16)
            const localVarPath = `/__base/user/updateUserPW`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject16, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseUserApi - functional programming interface
 * @export
 */
export const BaseUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoleBaseUser(baseRoleAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserBaseUser(baseUserAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除角色
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delRoleBaseUser(roleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delRoleBaseUser(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除用户
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserBaseUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserBaseUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取用户信息
         * @summary 获取用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfoBaseUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfoBaseUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取组织机构下角色列表
         * @param {string} orgCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrgRolesBaseUser(orgCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrgRolesBaseUser(orgCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取角色列表
         * @param {SystemGenerateObject12} systemGenerateObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRolesBaseUser(systemGenerateObject12: SystemGenerateObject12, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRolesBaseUser(systemGenerateObject12, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取系统角色列表
         * @param {SystemGenerateObject10} systemGenerateObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSystemRolesBaseUser(systemGenerateObject10: SystemGenerateObject10, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSystemRolesBaseUser(systemGenerateObject10, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询用户的组织角色列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserOrgRolesBaseUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserOrgRolesBaseUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户列表
         * @param {SystemGenerateObject14} systemGenerateObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsersBaseUser(systemGenerateObject14: SystemGenerateObject14, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsersBaseUser(systemGenerateObject14, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 登录
         * @summary 登录
         * @param {string} userNameOrPhone 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginBaseUser(userNameOrPhone: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginBaseUser(userNameOrPhone, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 登出
         * @summary 登出
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutBaseUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutBaseUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取角色列表(分页)
         * @param {SystemGenerateObject13} systemGenerateObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageRolesBaseUser(systemGenerateObject13: SystemGenerateObject13, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageRolesBaseUser(systemGenerateObject13, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户列表(分页)
         * @param {SystemGenerateObject15} systemGenerateObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageUsersBaseUser(systemGenerateObject15: SystemGenerateObject15, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageUsersBaseUser(systemGenerateObject15, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async udpateRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.udpateRoleBaseUser(baseRoleAdminVo, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserBaseUser(baseUserAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新用户的组织角色列表
         * @param {SystemGenerateObject11} systemGenerateObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserOrgRolesBaseUser(systemGenerateObject11: SystemGenerateObject11, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserOrgRolesBaseUser(systemGenerateObject11, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改用户密码
         * @param {SystemGenerateObject16} systemGenerateObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPWBaseUser(systemGenerateObject16: SystemGenerateObject16, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPWBaseUser(systemGenerateObject16, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseUserApi - factory interface
 * @export
 */
export const BaseUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseUserApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addRoleBaseUser(baseRoleAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addUserBaseUser(baseUserAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除角色
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delRoleBaseUser(roleId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delRoleBaseUser(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除用户
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserBaseUser(userId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.deleteUserBaseUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取用户信息
         * @summary 获取用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoBaseUser(options?: any): AxiosPromise<any> {
            return localVarFp.getUserInfoBaseUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取组织机构下角色列表
         * @param {string} orgCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgRolesBaseUser(orgCode: string, options?: any): AxiosPromise<any> {
            return localVarFp.listOrgRolesBaseUser(orgCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取角色列表
         * @param {SystemGenerateObject12} systemGenerateObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRolesBaseUser(systemGenerateObject12: SystemGenerateObject12, options?: any): AxiosPromise<any> {
            return localVarFp.listRolesBaseUser(systemGenerateObject12, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取系统角色列表
         * @param {SystemGenerateObject10} systemGenerateObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSystemRolesBaseUser(systemGenerateObject10: SystemGenerateObject10, options?: any): AxiosPromise<any> {
            return localVarFp.listSystemRolesBaseUser(systemGenerateObject10, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询用户的组织角色列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOrgRolesBaseUser(userId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.listUserOrgRolesBaseUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户列表
         * @param {SystemGenerateObject14} systemGenerateObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersBaseUser(systemGenerateObject14: SystemGenerateObject14, options?: any): AxiosPromise<any> {
            return localVarFp.listUsersBaseUser(systemGenerateObject14, options).then((request) => request(axios, basePath));
        },
        /**
         * 登录
         * @summary 登录
         * @param {string} userNameOrPhone 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginBaseUser(userNameOrPhone: string, password: string, options?: any): AxiosPromise<any> {
            return localVarFp.loginBaseUser(userNameOrPhone, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 登出
         * @summary 登出
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBaseUser(options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.logoutBaseUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取角色列表(分页)
         * @param {SystemGenerateObject13} systemGenerateObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRolesBaseUser(systemGenerateObject13: SystemGenerateObject13, options?: any): AxiosPromise<any> {
            return localVarFp.pageRolesBaseUser(systemGenerateObject13, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户列表(分页)
         * @param {SystemGenerateObject15} systemGenerateObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageUsersBaseUser(systemGenerateObject15: SystemGenerateObject15, options?: any): AxiosPromise<any> {
            return localVarFp.pageUsersBaseUser(systemGenerateObject15, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.udpateRoleBaseUser(baseRoleAdminVo, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateUserBaseUser(baseUserAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新用户的组织角色列表
         * @param {SystemGenerateObject11} systemGenerateObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOrgRolesBaseUser(systemGenerateObject11: SystemGenerateObject11, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateUserOrgRolesBaseUser(systemGenerateObject11, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改用户密码
         * @param {SystemGenerateObject16} systemGenerateObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPWBaseUser(systemGenerateObject16: SystemGenerateObject16, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateUserPWBaseUser(systemGenerateObject16, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseUserApi - object-oriented interface
 * @export
 * @class BaseUserApi
 * @extends {BaseAPI}
 */
export class BaseUserApi extends BaseAPI {
    /**
     * 
     * @summary 添加角色
     * @param {BaseRoleAdminVo} baseRoleAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public addRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).addRoleBaseUser(baseRoleAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加用户
     * @param {BaseUserAdminVo} baseUserAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public addUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).addUserBaseUser(baseUserAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除角色
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public delRoleBaseUser(roleId: number, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).delRoleBaseUser(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除用户
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public deleteUserBaseUser(userId: number, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).deleteUserBaseUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取用户信息
     * @summary 获取用户信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public getUserInfoBaseUser(options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).getUserInfoBaseUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取组织机构下角色列表
     * @param {string} orgCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listOrgRolesBaseUser(orgCode: string, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listOrgRolesBaseUser(orgCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取角色列表
     * @param {SystemGenerateObject12} systemGenerateObject12 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listRolesBaseUser(systemGenerateObject12: SystemGenerateObject12, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listRolesBaseUser(systemGenerateObject12, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取系统角色列表
     * @param {SystemGenerateObject10} systemGenerateObject10 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listSystemRolesBaseUser(systemGenerateObject10: SystemGenerateObject10, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listSystemRolesBaseUser(systemGenerateObject10, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询用户的组织角色列表
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listUserOrgRolesBaseUser(userId: number, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listUserOrgRolesBaseUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户列表
     * @param {SystemGenerateObject14} systemGenerateObject14 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listUsersBaseUser(systemGenerateObject14: SystemGenerateObject14, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listUsersBaseUser(systemGenerateObject14, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 登录
     * @summary 登录
     * @param {string} userNameOrPhone 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public loginBaseUser(userNameOrPhone: string, password: string, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).loginBaseUser(userNameOrPhone, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 登出
     * @summary 登出
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public logoutBaseUser(options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).logoutBaseUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取角色列表(分页)
     * @param {SystemGenerateObject13} systemGenerateObject13 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public pageRolesBaseUser(systemGenerateObject13: SystemGenerateObject13, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).pageRolesBaseUser(systemGenerateObject13, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户列表(分页)
     * @param {SystemGenerateObject15} systemGenerateObject15 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public pageUsersBaseUser(systemGenerateObject15: SystemGenerateObject15, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).pageUsersBaseUser(systemGenerateObject15, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改角色
     * @param {BaseRoleAdminVo} baseRoleAdminVo 
     * @param {boolean} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public udpateRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).udpateRoleBaseUser(baseRoleAdminVo, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改用户
     * @param {BaseUserAdminVo} baseUserAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public updateUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).updateUserBaseUser(baseUserAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新用户的组织角色列表
     * @param {SystemGenerateObject11} systemGenerateObject11 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public updateUserOrgRolesBaseUser(systemGenerateObject11: SystemGenerateObject11, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).updateUserOrgRolesBaseUser(systemGenerateObject11, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改用户密码
     * @param {SystemGenerateObject16} systemGenerateObject16 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public updateUserPWBaseUser(systemGenerateObject16: SystemGenerateObject16, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).updateUserPWBaseUser(systemGenerateObject16, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseUtilApi - axios parameter creator
 * @export
 */
export const BaseUtilApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取id
         * @summary 获取id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdBaseUtil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/util/getId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取id
         * @summary 获取ids
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdsBaseUtil: async (count: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'count' is not null or undefined
            assertParamExists('getIdsBaseUtil', 'count', count)
            const localVarPath = `/__base/util/getIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取uuid
         * @summary 获取uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUUIDBaseUtil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/util/getUUID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseUtilApi - functional programming interface
 * @export
 */
export const BaseUtilApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseUtilApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取id
         * @summary 获取id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdBaseUtil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdBaseUtil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取id
         * @summary 获取ids
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdsBaseUtil(count: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdsBaseUtil(count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取uuid
         * @summary 获取uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUUIDBaseUtil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUUIDBaseUtil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseUtilApi - factory interface
 * @export
 */
export const BaseUtilApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseUtilApiFp(configuration)
    return {
        /**
         * 获取id
         * @summary 获取id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdBaseUtil(options?: any): AxiosPromise<number> {
            return localVarFp.getIdBaseUtil(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取id
         * @summary 获取ids
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdsBaseUtil(count: number, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getIdsBaseUtil(count, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取uuid
         * @summary 获取uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUUIDBaseUtil(options?: any): AxiosPromise<string> {
            return localVarFp.getUUIDBaseUtil(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseUtilApi - object-oriented interface
 * @export
 * @class BaseUtilApi
 * @extends {BaseAPI}
 */
export class BaseUtilApi extends BaseAPI {
    /**
     * 获取id
     * @summary 获取id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUtilApi
     */
    public getIdBaseUtil(options?: AxiosRequestConfig) {
        return BaseUtilApiFp(this.configuration).getIdBaseUtil(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取id
     * @summary 获取ids
     * @param {number} count 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUtilApi
     */
    public getIdsBaseUtil(count: number, options?: AxiosRequestConfig) {
        return BaseUtilApiFp(this.configuration).getIdsBaseUtil(count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取uuid
     * @summary 获取uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUtilApi
     */
    public getUUIDBaseUtil(options?: AxiosRequestConfig) {
        return BaseUtilApiFp(this.configuration).getUUIDBaseUtil(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BlackAccountApi - axios parameter creator
 * @export
 */
export const BlackAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加黑名单
         * @param {SystemGenerateObject112} systemGenerateObject112 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBlackAccountblackAccount: async (systemGenerateObject112: SystemGenerateObject112, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject112' is not null or undefined
            assertParamExists('addBlackAccountblackAccount', 'systemGenerateObject112', systemGenerateObject112)
            const localVarPath = `/blackAccount/addBlackAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject112, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加自身管理的门店黑名单
         * @param {SystemGenerateObject115} systemGenerateObject115 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOwnerStoreBlackAccountblackAccount: async (systemGenerateObject115: SystemGenerateObject115, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject115' is not null or undefined
            assertParamExists('addOwnerStoreBlackAccountblackAccount', 'systemGenerateObject115', systemGenerateObject115)
            const localVarPath = `/blackAccount/addOwnerStoreBlackAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject115, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除黑名单
         * @param {number} blackAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delBlackAccountblackAccount: async (blackAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blackAccountId' is not null or undefined
            assertParamExists('delBlackAccountblackAccount', 'blackAccountId', blackAccountId)
            const localVarPath = `/blackAccount/delBlackAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (blackAccountId !== undefined) {
                localVarQueryParameter['blackAccountId'] = blackAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除自身管理的门店黑名单
         * @param {number} storeId 
         * @param {number} blackAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOwnerStoreBlackAccountblackAccount: async (storeId: number, blackAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('delOwnerStoreBlackAccountblackAccount', 'storeId', storeId)
            // verify required parameter 'blackAccountId' is not null or undefined
            assertParamExists('delOwnerStoreBlackAccountblackAccount', 'blackAccountId', blackAccountId)
            const localVarPath = `/blackAccount/delOwnerStoreBlackAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (blackAccountId !== undefined) {
                localVarQueryParameter['blackAccountId'] = blackAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取黑名单列表
         * @param {SystemGenerateObject110} systemGenerateObject110 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBlackAccountblackAccount: async (systemGenerateObject110: SystemGenerateObject110, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject110' is not null or undefined
            assertParamExists('listBlackAccountblackAccount', 'systemGenerateObject110', systemGenerateObject110)
            const localVarPath = `/blackAccount/listBlackAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject110, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取自身管理的门店黑名单列表
         * @param {SystemGenerateObject114} systemGenerateObject114 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnerStoreBlackAccountblackAccount: async (systemGenerateObject114: SystemGenerateObject114, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject114' is not null or undefined
            assertParamExists('listOwnerStoreBlackAccountblackAccount', 'systemGenerateObject114', systemGenerateObject114)
            const localVarPath = `/blackAccount/listOwnerStoreBlackAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject114, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取黑名单列表(分页)
         * @param {SystemGenerateObject111} systemGenerateObject111 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBlackAccountblackAccount: async (systemGenerateObject111: SystemGenerateObject111, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject111' is not null or undefined
            assertParamExists('pageBlackAccountblackAccount', 'systemGenerateObject111', systemGenerateObject111)
            const localVarPath = `/blackAccount/pageBlackAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject111, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新黑名单
         * @param {SystemGenerateObject113} systemGenerateObject113 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBlackAccountblackAccount: async (systemGenerateObject113: SystemGenerateObject113, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject113' is not null or undefined
            assertParamExists('updateBlackAccountblackAccount', 'systemGenerateObject113', systemGenerateObject113)
            const localVarPath = `/blackAccount/updateBlackAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject113, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新自身管理的门店黑名单
         * @param {SystemGenerateObject116} systemGenerateObject116 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerStoreBlackAccountblackAccount: async (systemGenerateObject116: SystemGenerateObject116, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject116' is not null or undefined
            assertParamExists('updateOwnerStoreBlackAccountblackAccount', 'systemGenerateObject116', systemGenerateObject116)
            const localVarPath = `/blackAccount/updateOwnerStoreBlackAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject116, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlackAccountApi - functional programming interface
 * @export
 */
export const BlackAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlackAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加黑名单
         * @param {SystemGenerateObject112} systemGenerateObject112 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBlackAccountblackAccount(systemGenerateObject112: SystemGenerateObject112, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBlackAccountblackAccount(systemGenerateObject112, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加自身管理的门店黑名单
         * @param {SystemGenerateObject115} systemGenerateObject115 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOwnerStoreBlackAccountblackAccount(systemGenerateObject115: SystemGenerateObject115, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOwnerStoreBlackAccountblackAccount(systemGenerateObject115, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除黑名单
         * @param {number} blackAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delBlackAccountblackAccount(blackAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delBlackAccountblackAccount(blackAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除自身管理的门店黑名单
         * @param {number} storeId 
         * @param {number} blackAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOwnerStoreBlackAccountblackAccount(storeId: number, blackAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOwnerStoreBlackAccountblackAccount(storeId, blackAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取黑名单列表
         * @param {SystemGenerateObject110} systemGenerateObject110 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBlackAccountblackAccount(systemGenerateObject110: SystemGenerateObject110, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBlackAccountblackAccount(systemGenerateObject110, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取自身管理的门店黑名单列表
         * @param {SystemGenerateObject114} systemGenerateObject114 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOwnerStoreBlackAccountblackAccount(systemGenerateObject114: SystemGenerateObject114, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOwnerStoreBlackAccountblackAccount(systemGenerateObject114, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取黑名单列表(分页)
         * @param {SystemGenerateObject111} systemGenerateObject111 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageBlackAccountblackAccount(systemGenerateObject111: SystemGenerateObject111, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageBlackAccountblackAccount(systemGenerateObject111, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新黑名单
         * @param {SystemGenerateObject113} systemGenerateObject113 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBlackAccountblackAccount(systemGenerateObject113: SystemGenerateObject113, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBlackAccountblackAccount(systemGenerateObject113, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新自身管理的门店黑名单
         * @param {SystemGenerateObject116} systemGenerateObject116 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnerStoreBlackAccountblackAccount(systemGenerateObject116: SystemGenerateObject116, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnerStoreBlackAccountblackAccount(systemGenerateObject116, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlackAccountApi - factory interface
 * @export
 */
export const BlackAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlackAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加黑名单
         * @param {SystemGenerateObject112} systemGenerateObject112 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBlackAccountblackAccount(systemGenerateObject112: SystemGenerateObject112, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addBlackAccountblackAccount(systemGenerateObject112, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加自身管理的门店黑名单
         * @param {SystemGenerateObject115} systemGenerateObject115 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOwnerStoreBlackAccountblackAccount(systemGenerateObject115: SystemGenerateObject115, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addOwnerStoreBlackAccountblackAccount(systemGenerateObject115, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除黑名单
         * @param {number} blackAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delBlackAccountblackAccount(blackAccountId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delBlackAccountblackAccount(blackAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除自身管理的门店黑名单
         * @param {number} storeId 
         * @param {number} blackAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOwnerStoreBlackAccountblackAccount(storeId: number, blackAccountId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delOwnerStoreBlackAccountblackAccount(storeId, blackAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取黑名单列表
         * @param {SystemGenerateObject110} systemGenerateObject110 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBlackAccountblackAccount(systemGenerateObject110: SystemGenerateObject110, options?: any): AxiosPromise<any> {
            return localVarFp.listBlackAccountblackAccount(systemGenerateObject110, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取自身管理的门店黑名单列表
         * @param {SystemGenerateObject114} systemGenerateObject114 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnerStoreBlackAccountblackAccount(systemGenerateObject114: SystemGenerateObject114, options?: any): AxiosPromise<any> {
            return localVarFp.listOwnerStoreBlackAccountblackAccount(systemGenerateObject114, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取黑名单列表(分页)
         * @param {SystemGenerateObject111} systemGenerateObject111 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBlackAccountblackAccount(systemGenerateObject111: SystemGenerateObject111, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageBlackAccountblackAccount(systemGenerateObject111, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新黑名单
         * @param {SystemGenerateObject113} systemGenerateObject113 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBlackAccountblackAccount(systemGenerateObject113: SystemGenerateObject113, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateBlackAccountblackAccount(systemGenerateObject113, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新自身管理的门店黑名单
         * @param {SystemGenerateObject116} systemGenerateObject116 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerStoreBlackAccountblackAccount(systemGenerateObject116: SystemGenerateObject116, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateOwnerStoreBlackAccountblackAccount(systemGenerateObject116, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlackAccountApi - object-oriented interface
 * @export
 * @class BlackAccountApi
 * @extends {BaseAPI}
 */
export class BlackAccountApi extends BaseAPI {
    /**
     * 
     * @summary 添加黑名单
     * @param {SystemGenerateObject112} systemGenerateObject112 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlackAccountApi
     */
    public addBlackAccountblackAccount(systemGenerateObject112: SystemGenerateObject112, options?: AxiosRequestConfig) {
        return BlackAccountApiFp(this.configuration).addBlackAccountblackAccount(systemGenerateObject112, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加自身管理的门店黑名单
     * @param {SystemGenerateObject115} systemGenerateObject115 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlackAccountApi
     */
    public addOwnerStoreBlackAccountblackAccount(systemGenerateObject115: SystemGenerateObject115, options?: AxiosRequestConfig) {
        return BlackAccountApiFp(this.configuration).addOwnerStoreBlackAccountblackAccount(systemGenerateObject115, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除黑名单
     * @param {number} blackAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlackAccountApi
     */
    public delBlackAccountblackAccount(blackAccountId: number, options?: AxiosRequestConfig) {
        return BlackAccountApiFp(this.configuration).delBlackAccountblackAccount(blackAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除自身管理的门店黑名单
     * @param {number} storeId 
     * @param {number} blackAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlackAccountApi
     */
    public delOwnerStoreBlackAccountblackAccount(storeId: number, blackAccountId: number, options?: AxiosRequestConfig) {
        return BlackAccountApiFp(this.configuration).delOwnerStoreBlackAccountblackAccount(storeId, blackAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取黑名单列表
     * @param {SystemGenerateObject110} systemGenerateObject110 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlackAccountApi
     */
    public listBlackAccountblackAccount(systemGenerateObject110: SystemGenerateObject110, options?: AxiosRequestConfig) {
        return BlackAccountApiFp(this.configuration).listBlackAccountblackAccount(systemGenerateObject110, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取自身管理的门店黑名单列表
     * @param {SystemGenerateObject114} systemGenerateObject114 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlackAccountApi
     */
    public listOwnerStoreBlackAccountblackAccount(systemGenerateObject114: SystemGenerateObject114, options?: AxiosRequestConfig) {
        return BlackAccountApiFp(this.configuration).listOwnerStoreBlackAccountblackAccount(systemGenerateObject114, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取黑名单列表(分页)
     * @param {SystemGenerateObject111} systemGenerateObject111 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlackAccountApi
     */
    public pageBlackAccountblackAccount(systemGenerateObject111: SystemGenerateObject111, options?: AxiosRequestConfig) {
        return BlackAccountApiFp(this.configuration).pageBlackAccountblackAccount(systemGenerateObject111, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新黑名单
     * @param {SystemGenerateObject113} systemGenerateObject113 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlackAccountApi
     */
    public updateBlackAccountblackAccount(systemGenerateObject113: SystemGenerateObject113, options?: AxiosRequestConfig) {
        return BlackAccountApiFp(this.configuration).updateBlackAccountblackAccount(systemGenerateObject113, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新自身管理的门店黑名单
     * @param {SystemGenerateObject116} systemGenerateObject116 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlackAccountApi
     */
    public updateOwnerStoreBlackAccountblackAccount(systemGenerateObject116: SystemGenerateObject116, options?: AxiosRequestConfig) {
        return BlackAccountApiFp(this.configuration).updateOwnerStoreBlackAccountblackAccount(systemGenerateObject116, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加设备
         * @param {SystemGenerateObject58} systemGenerateObject58 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceDevice: async (systemGenerateObject58: SystemGenerateObject58, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject58' is not null or undefined
            assertParamExists('addDeviceDevice', 'systemGenerateObject58', systemGenerateObject58)
            const localVarPath = `/device/addDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject58, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除设备
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deleteDeviceDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deleteDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理员开门
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceAdminOpendoorDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceAdminOpendoorDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceAdminOpendoor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理员开门(Hex)
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceAdminOpendoorHexDevice: async (deviceIdHex: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceIdHex' is not null or undefined
            assertParamExists('deviceAdminOpendoorHexDevice', 'deviceIdHex', deviceIdHex)
            const localVarPath = `/device/deviceAdminOpendoorHex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceIdHex !== undefined) {
                localVarQueryParameter['deviceIdHex'] = deviceIdHex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备模式
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetModDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceGetModDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceGetMod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 请求获取设备的模式(Hwx)
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetModHexDevice: async (deviceIdHex: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceIdHex' is not null or undefined
            assertParamExists('deviceGetModHexDevice', 'deviceIdHex', deviceIdHex)
            const localVarPath = `/device/deviceGetModHex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceIdHex !== undefined) {
                localVarQueryParameter['deviceIdHex'] = deviceIdHex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 设备恢复
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceRecoveryDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceRecoveryDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceRecovery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 设置设备的模式
         * @param {number} deviceId 
         * @param {DEVICEMODE} mod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSetModDevice: async (deviceId: number, mod: DEVICEMODE, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceSetModDevice', 'deviceId', deviceId)
            // verify required parameter 'mod' is not null or undefined
            assertParamExists('deviceSetModDevice', 'mod', mod)
            const localVarPath = `/device/deviceSetMod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (mod !== undefined) {
                localVarQueryParameter['mod'] = mod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备状态
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStatusDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceStatusDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备状态(Hex)
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStatusHexDevice: async (deviceIdHex: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceIdHex' is not null or undefined
            assertParamExists('deviceStatusHexDevice', 'deviceIdHex', deviceIdHex)
            const localVarPath = `/device/deviceStatusHex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceIdHex !== undefined) {
                localVarQueryParameter['deviceIdHex'] = deviceIdHex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 设备升级
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceUpgradeDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceUpgradeDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceUpgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备版本
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceVersionDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceVersionDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceVersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备列表
         * @param {SystemGenerateObject56} systemGenerateObject56 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceDevice: async (systemGenerateObject56: SystemGenerateObject56, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject56' is not null or undefined
            assertParamExists('listDeviceDevice', 'systemGenerateObject56', systemGenerateObject56)
            const localVarPath = `/device/listDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject56, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备类型列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceTypeDevice: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device/listDeviceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 直接打开设备
         * @param {string} deviceIdHex 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorByDirectGetDevice: async (deviceIdHex: string, tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceIdHex' is not null or undefined
            assertParamExists('opendoorByDirectGetDevice', 'deviceIdHex', deviceIdHex)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('opendoorByDirectGetDevice', 'tenantId', tenantId)
            const localVarPath = `/device/openDeviceByDirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceIdHex !== undefined) {
                localVarQueryParameter['deviceIdHex'] = deviceIdHex;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备列表(分页)
         * @param {SystemGenerateObject57} systemGenerateObject57 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDeviceDevice: async (systemGenerateObject57: SystemGenerateObject57, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject57' is not null or undefined
            assertParamExists('pageDeviceDevice', 'systemGenerateObject57', systemGenerateObject57)
            const localVarPath = `/device/pageDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject57, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新设备
         * @param {SystemGenerateObject59} systemGenerateObject59 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceDevice: async (systemGenerateObject59: SystemGenerateObject59, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject59' is not null or undefined
            assertParamExists('updateDeviceDevice', 'systemGenerateObject59', systemGenerateObject59)
            const localVarPath = `/device/updateDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject59, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加设备
         * @param {SystemGenerateObject58} systemGenerateObject58 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDeviceDevice(systemGenerateObject58: SystemGenerateObject58, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDeviceDevice(systemGenerateObject58, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除设备
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理员开门
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceAdminOpendoorDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceAdminOpendoorDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理员开门(Hex)
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceAdminOpendoorHexDevice(deviceIdHex: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceAdminOpendoorHexDevice(deviceIdHex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备模式
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceGetModDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceGetModDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 请求获取设备的模式(Hwx)
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceGetModHexDevice(deviceIdHex: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceGetModHexDevice(deviceIdHex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 设备恢复
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceRecoveryDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceRecoveryDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 设置设备的模式
         * @param {number} deviceId 
         * @param {DEVICEMODE} mod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSetModDevice(deviceId: number, mod: DEVICEMODE, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceSetModDevice(deviceId, mod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备状态
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceStatusDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceStatusDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备状态(Hex)
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceStatusHexDevice(deviceIdHex: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceStatusHexDevice(deviceIdHex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 设备升级
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceUpgradeDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceUpgradeDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备版本
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceVersionDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceVersionDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备列表
         * @param {SystemGenerateObject56} systemGenerateObject56 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeviceDevice(systemGenerateObject56: SystemGenerateObject56, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeviceDevice(systemGenerateObject56, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备类型列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeviceTypeDevice(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeviceTypeDevice(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 直接打开设备
         * @param {string} deviceIdHex 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opendoorByDirectGetDevice(deviceIdHex: string, tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opendoorByDirectGetDevice(deviceIdHex, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备列表(分页)
         * @param {SystemGenerateObject57} systemGenerateObject57 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageDeviceDevice(systemGenerateObject57: SystemGenerateObject57, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageDeviceDevice(systemGenerateObject57, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新设备
         * @param {SystemGenerateObject59} systemGenerateObject59 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceDevice(systemGenerateObject59: SystemGenerateObject59, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceDevice(systemGenerateObject59, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加设备
         * @param {SystemGenerateObject58} systemGenerateObject58 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceDevice(systemGenerateObject58: SystemGenerateObject58, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addDeviceDevice(systemGenerateObject58, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除设备
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceDevice(deviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deleteDeviceDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理员开门
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceAdminOpendoorDevice(deviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deviceAdminOpendoorDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理员开门(Hex)
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceAdminOpendoorHexDevice(deviceIdHex: string, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deviceAdminOpendoorHexDevice(deviceIdHex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备模式
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetModDevice(deviceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deviceGetModDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 请求获取设备的模式(Hwx)
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetModHexDevice(deviceIdHex: string, options?: any): AxiosPromise<any> {
            return localVarFp.deviceGetModHexDevice(deviceIdHex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 设备恢复
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceRecoveryDevice(deviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deviceRecoveryDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 设置设备的模式
         * @param {number} deviceId 
         * @param {DEVICEMODE} mod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSetModDevice(deviceId: number, mod: DEVICEMODE, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deviceSetModDevice(deviceId, mod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备状态
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStatusDevice(deviceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deviceStatusDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备状态(Hex)
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStatusHexDevice(deviceIdHex: string, options?: any): AxiosPromise<any> {
            return localVarFp.deviceStatusHexDevice(deviceIdHex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 设备升级
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceUpgradeDevice(deviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deviceUpgradeDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备版本
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceVersionDevice(deviceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deviceVersionDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备列表
         * @param {SystemGenerateObject56} systemGenerateObject56 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceDevice(systemGenerateObject56: SystemGenerateObject56, options?: any): AxiosPromise<any> {
            return localVarFp.listDeviceDevice(systemGenerateObject56, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备类型列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceTypeDevice(options?: any): AxiosPromise<any> {
            return localVarFp.listDeviceTypeDevice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 直接打开设备
         * @param {string} deviceIdHex 
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorByDirectGetDevice(deviceIdHex: string, tenantId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.opendoorByDirectGetDevice(deviceIdHex, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备列表(分页)
         * @param {SystemGenerateObject57} systemGenerateObject57 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDeviceDevice(systemGenerateObject57: SystemGenerateObject57, options?: any): AxiosPromise<any> {
            return localVarFp.pageDeviceDevice(systemGenerateObject57, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新设备
         * @param {SystemGenerateObject59} systemGenerateObject59 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceDevice(systemGenerateObject59: SystemGenerateObject59, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateDeviceDevice(systemGenerateObject59, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * 
     * @summary 添加设备
     * @param {SystemGenerateObject58} systemGenerateObject58 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public addDeviceDevice(systemGenerateObject58: SystemGenerateObject58, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).addDeviceDevice(systemGenerateObject58, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除设备
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deleteDeviceDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deleteDeviceDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理员开门
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceAdminOpendoorDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceAdminOpendoorDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理员开门(Hex)
     * @param {string} deviceIdHex 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceAdminOpendoorHexDevice(deviceIdHex: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceAdminOpendoorHexDevice(deviceIdHex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备模式
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceGetModDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceGetModDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 请求获取设备的模式(Hwx)
     * @param {string} deviceIdHex 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceGetModHexDevice(deviceIdHex: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceGetModHexDevice(deviceIdHex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 设备恢复
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceRecoveryDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceRecoveryDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 设置设备的模式
     * @param {number} deviceId 
     * @param {DEVICEMODE} mod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceSetModDevice(deviceId: number, mod: DEVICEMODE, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceSetModDevice(deviceId, mod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备状态
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceStatusDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceStatusDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备状态(Hex)
     * @param {string} deviceIdHex 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceStatusHexDevice(deviceIdHex: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceStatusHexDevice(deviceIdHex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 设备升级
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceUpgradeDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceUpgradeDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备版本
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceVersionDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceVersionDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备列表
     * @param {SystemGenerateObject56} systemGenerateObject56 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public listDeviceDevice(systemGenerateObject56: SystemGenerateObject56, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).listDeviceDevice(systemGenerateObject56, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备类型列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public listDeviceTypeDevice(options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).listDeviceTypeDevice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 直接打开设备
     * @param {string} deviceIdHex 
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public opendoorByDirectGetDevice(deviceIdHex: string, tenantId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).opendoorByDirectGetDevice(deviceIdHex, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备列表(分页)
     * @param {SystemGenerateObject57} systemGenerateObject57 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public pageDeviceDevice(systemGenerateObject57: SystemGenerateObject57, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).pageDeviceDevice(systemGenerateObject57, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新设备
     * @param {SystemGenerateObject59} systemGenerateObject59 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public updateDeviceDevice(systemGenerateObject59: SystemGenerateObject59, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).updateDeviceDevice(systemGenerateObject59, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DistanceApi - axios parameter creator
 * @export
 */
export const DistanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加距离
         * @param {SystemGenerateObject103} systemGenerateObject103 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDistancedistance: async (systemGenerateObject103: SystemGenerateObject103, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject103' is not null or undefined
            assertParamExists('addDistancedistance', 'systemGenerateObject103', systemGenerateObject103)
            const localVarPath = `/distance/addDistance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject103, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除距离
         * @param {number} distanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDistancedistance: async (distanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distanceId' is not null or undefined
            assertParamExists('delDistancedistance', 'distanceId', distanceId)
            const localVarPath = `/distance/delDistance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (distanceId !== undefined) {
                localVarQueryParameter['distanceId'] = distanceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取距离列表
         * @param {SystemGenerateObject101} systemGenerateObject101 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDistancedistance: async (systemGenerateObject101: SystemGenerateObject101, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject101' is not null or undefined
            assertParamExists('listDistancedistance', 'systemGenerateObject101', systemGenerateObject101)
            const localVarPath = `/distance/listDistance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject101, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取距离列表(分页)
         * @param {SystemGenerateObject102} systemGenerateObject102 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDistancedistance: async (systemGenerateObject102: SystemGenerateObject102, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject102' is not null or undefined
            assertParamExists('pageDistancedistance', 'systemGenerateObject102', systemGenerateObject102)
            const localVarPath = `/distance/pageDistance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject102, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新距离
         * @param {SystemGenerateObject104} systemGenerateObject104 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDistancedistance: async (systemGenerateObject104: SystemGenerateObject104, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject104' is not null or undefined
            assertParamExists('updateDistancedistance', 'systemGenerateObject104', systemGenerateObject104)
            const localVarPath = `/distance/updateDistance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject104, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistanceApi - functional programming interface
 * @export
 */
export const DistanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DistanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加距离
         * @param {SystemGenerateObject103} systemGenerateObject103 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDistancedistance(systemGenerateObject103: SystemGenerateObject103, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDistancedistance(systemGenerateObject103, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除距离
         * @param {number} distanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delDistancedistance(distanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delDistancedistance(distanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取距离列表
         * @param {SystemGenerateObject101} systemGenerateObject101 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDistancedistance(systemGenerateObject101: SystemGenerateObject101, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDistancedistance(systemGenerateObject101, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取距离列表(分页)
         * @param {SystemGenerateObject102} systemGenerateObject102 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageDistancedistance(systemGenerateObject102: SystemGenerateObject102, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageDistancedistance(systemGenerateObject102, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新距离
         * @param {SystemGenerateObject104} systemGenerateObject104 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDistancedistance(systemGenerateObject104: SystemGenerateObject104, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDistancedistance(systemGenerateObject104, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DistanceApi - factory interface
 * @export
 */
export const DistanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DistanceApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加距离
         * @param {SystemGenerateObject103} systemGenerateObject103 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDistancedistance(systemGenerateObject103: SystemGenerateObject103, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addDistancedistance(systemGenerateObject103, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除距离
         * @param {number} distanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDistancedistance(distanceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delDistancedistance(distanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取距离列表
         * @param {SystemGenerateObject101} systemGenerateObject101 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDistancedistance(systemGenerateObject101: SystemGenerateObject101, options?: any): AxiosPromise<any> {
            return localVarFp.listDistancedistance(systemGenerateObject101, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取距离列表(分页)
         * @param {SystemGenerateObject102} systemGenerateObject102 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDistancedistance(systemGenerateObject102: SystemGenerateObject102, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageDistancedistance(systemGenerateObject102, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新距离
         * @param {SystemGenerateObject104} systemGenerateObject104 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDistancedistance(systemGenerateObject104: SystemGenerateObject104, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateDistancedistance(systemGenerateObject104, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DistanceApi - object-oriented interface
 * @export
 * @class DistanceApi
 * @extends {BaseAPI}
 */
export class DistanceApi extends BaseAPI {
    /**
     * 
     * @summary 添加距离
     * @param {SystemGenerateObject103} systemGenerateObject103 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistanceApi
     */
    public addDistancedistance(systemGenerateObject103: SystemGenerateObject103, options?: AxiosRequestConfig) {
        return DistanceApiFp(this.configuration).addDistancedistance(systemGenerateObject103, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除距离
     * @param {number} distanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistanceApi
     */
    public delDistancedistance(distanceId: number, options?: AxiosRequestConfig) {
        return DistanceApiFp(this.configuration).delDistancedistance(distanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取距离列表
     * @param {SystemGenerateObject101} systemGenerateObject101 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistanceApi
     */
    public listDistancedistance(systemGenerateObject101: SystemGenerateObject101, options?: AxiosRequestConfig) {
        return DistanceApiFp(this.configuration).listDistancedistance(systemGenerateObject101, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取距离列表(分页)
     * @param {SystemGenerateObject102} systemGenerateObject102 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistanceApi
     */
    public pageDistancedistance(systemGenerateObject102: SystemGenerateObject102, options?: AxiosRequestConfig) {
        return DistanceApiFp(this.configuration).pageDistancedistance(systemGenerateObject102, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新距离
     * @param {SystemGenerateObject104} systemGenerateObject104 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistanceApi
     */
    public updateDistancedistance(systemGenerateObject104: SystemGenerateObject104, options?: AxiosRequestConfig) {
        return DistanceApiFp(this.configuration).updateDistancedistance(systemGenerateObject104, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FacilitatorApi - axios parameter creator
 * @export
 */
export const FacilitatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加服务商
         * @param {SystemGenerateObject95} systemGenerateObject95 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFacilitatorfacilitator: async (systemGenerateObject95: SystemGenerateObject95, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject95' is not null or undefined
            assertParamExists('addFacilitatorfacilitator', 'systemGenerateObject95', systemGenerateObject95)
            const localVarPath = `/facilitator/addFacilitator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject95, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除服务商
         * @param {number} facilitatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delFacilitatorfacilitator: async (facilitatorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilitatorId' is not null or undefined
            assertParamExists('delFacilitatorfacilitator', 'facilitatorId', facilitatorId)
            const localVarPath = `/facilitator/delFacilitator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (facilitatorId !== undefined) {
                localVarQueryParameter['facilitatorId'] = facilitatorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取服务商列表
         * @param {SystemGenerateObject93} systemGenerateObject93 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFacilitatorfacilitator: async (systemGenerateObject93: SystemGenerateObject93, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject93' is not null or undefined
            assertParamExists('listFacilitatorfacilitator', 'systemGenerateObject93', systemGenerateObject93)
            const localVarPath = `/facilitator/listFacilitator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject93, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取服务商列表(分页)
         * @param {SystemGenerateObject94} systemGenerateObject94 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageFacilitatorfacilitator: async (systemGenerateObject94: SystemGenerateObject94, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject94' is not null or undefined
            assertParamExists('pageFacilitatorfacilitator', 'systemGenerateObject94', systemGenerateObject94)
            const localVarPath = `/facilitator/pageFacilitator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject94, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新服务商
         * @param {SystemGenerateObject96} systemGenerateObject96 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacilitatorfacilitator: async (systemGenerateObject96: SystemGenerateObject96, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject96' is not null or undefined
            assertParamExists('updateFacilitatorfacilitator', 'systemGenerateObject96', systemGenerateObject96)
            const localVarPath = `/facilitator/updateFacilitator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject96, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacilitatorApi - functional programming interface
 * @export
 */
export const FacilitatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacilitatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加服务商
         * @param {SystemGenerateObject95} systemGenerateObject95 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFacilitatorfacilitator(systemGenerateObject95: SystemGenerateObject95, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFacilitatorfacilitator(systemGenerateObject95, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除服务商
         * @param {number} facilitatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delFacilitatorfacilitator(facilitatorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delFacilitatorfacilitator(facilitatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取服务商列表
         * @param {SystemGenerateObject93} systemGenerateObject93 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFacilitatorfacilitator(systemGenerateObject93: SystemGenerateObject93, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFacilitatorfacilitator(systemGenerateObject93, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取服务商列表(分页)
         * @param {SystemGenerateObject94} systemGenerateObject94 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageFacilitatorfacilitator(systemGenerateObject94: SystemGenerateObject94, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageFacilitatorfacilitator(systemGenerateObject94, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新服务商
         * @param {SystemGenerateObject96} systemGenerateObject96 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFacilitatorfacilitator(systemGenerateObject96: SystemGenerateObject96, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFacilitatorfacilitator(systemGenerateObject96, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacilitatorApi - factory interface
 * @export
 */
export const FacilitatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacilitatorApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加服务商
         * @param {SystemGenerateObject95} systemGenerateObject95 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFacilitatorfacilitator(systemGenerateObject95: SystemGenerateObject95, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addFacilitatorfacilitator(systemGenerateObject95, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除服务商
         * @param {number} facilitatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delFacilitatorfacilitator(facilitatorId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delFacilitatorfacilitator(facilitatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取服务商列表
         * @param {SystemGenerateObject93} systemGenerateObject93 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFacilitatorfacilitator(systemGenerateObject93: SystemGenerateObject93, options?: any): AxiosPromise<any> {
            return localVarFp.listFacilitatorfacilitator(systemGenerateObject93, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取服务商列表(分页)
         * @param {SystemGenerateObject94} systemGenerateObject94 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageFacilitatorfacilitator(systemGenerateObject94: SystemGenerateObject94, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageFacilitatorfacilitator(systemGenerateObject94, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新服务商
         * @param {SystemGenerateObject96} systemGenerateObject96 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacilitatorfacilitator(systemGenerateObject96: SystemGenerateObject96, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateFacilitatorfacilitator(systemGenerateObject96, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacilitatorApi - object-oriented interface
 * @export
 * @class FacilitatorApi
 * @extends {BaseAPI}
 */
export class FacilitatorApi extends BaseAPI {
    /**
     * 
     * @summary 添加服务商
     * @param {SystemGenerateObject95} systemGenerateObject95 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilitatorApi
     */
    public addFacilitatorfacilitator(systemGenerateObject95: SystemGenerateObject95, options?: AxiosRequestConfig) {
        return FacilitatorApiFp(this.configuration).addFacilitatorfacilitator(systemGenerateObject95, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除服务商
     * @param {number} facilitatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilitatorApi
     */
    public delFacilitatorfacilitator(facilitatorId: number, options?: AxiosRequestConfig) {
        return FacilitatorApiFp(this.configuration).delFacilitatorfacilitator(facilitatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取服务商列表
     * @param {SystemGenerateObject93} systemGenerateObject93 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilitatorApi
     */
    public listFacilitatorfacilitator(systemGenerateObject93: SystemGenerateObject93, options?: AxiosRequestConfig) {
        return FacilitatorApiFp(this.configuration).listFacilitatorfacilitator(systemGenerateObject93, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取服务商列表(分页)
     * @param {SystemGenerateObject94} systemGenerateObject94 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilitatorApi
     */
    public pageFacilitatorfacilitator(systemGenerateObject94: SystemGenerateObject94, options?: AxiosRequestConfig) {
        return FacilitatorApiFp(this.configuration).pageFacilitatorfacilitator(systemGenerateObject94, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新服务商
     * @param {SystemGenerateObject96} systemGenerateObject96 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilitatorApi
     */
    public updateFacilitatorfacilitator(systemGenerateObject96: SystemGenerateObject96, options?: AxiosRequestConfig) {
        return FacilitatorApiFp(this.configuration).updateFacilitatorfacilitator(systemGenerateObject96, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加站内信
         * @param {SystemGenerateObject99} systemGenerateObject99 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessagehome: async (systemGenerateObject99: SystemGenerateObject99, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject99' is not null or undefined
            assertParamExists('addMessagehome', 'systemGenerateObject99', systemGenerateObject99)
            const localVarPath = `/home/addMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject99, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除站内信
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMessagehome: async (messageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('delMessagehome', 'messageId', messageId)
            const localVarPath = `/home/delMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取首页统计
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeStatisticshome: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/home/getHomeStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取站内信列表
         * @param {SystemGenerateObject97} systemGenerateObject97 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessagehome: async (systemGenerateObject97: SystemGenerateObject97, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject97' is not null or undefined
            assertParamExists('listMessagehome', 'systemGenerateObject97', systemGenerateObject97)
            const localVarPath = `/home/listMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject97, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取站内信列表(分页)
         * @param {SystemGenerateObject98} systemGenerateObject98 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMessagehome: async (systemGenerateObject98: SystemGenerateObject98, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject98' is not null or undefined
            assertParamExists('pageMessagehome', 'systemGenerateObject98', systemGenerateObject98)
            const localVarPath = `/home/pageMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject98, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新站内信
         * @param {SystemGenerateObject100} systemGenerateObject100 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessagehome: async (systemGenerateObject100: SystemGenerateObject100, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject100' is not null or undefined
            assertParamExists('updateMessagehome', 'systemGenerateObject100', systemGenerateObject100)
            const localVarPath = `/home/updateMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject100, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加站内信
         * @param {SystemGenerateObject99} systemGenerateObject99 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMessagehome(systemGenerateObject99: SystemGenerateObject99, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMessagehome(systemGenerateObject99, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除站内信
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delMessagehome(messageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delMessagehome(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取首页统计
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeStatisticshome(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeStatisticshome(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取站内信列表
         * @param {SystemGenerateObject97} systemGenerateObject97 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMessagehome(systemGenerateObject97: SystemGenerateObject97, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMessagehome(systemGenerateObject97, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取站内信列表(分页)
         * @param {SystemGenerateObject98} systemGenerateObject98 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageMessagehome(systemGenerateObject98: SystemGenerateObject98, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageMessagehome(systemGenerateObject98, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新站内信
         * @param {SystemGenerateObject100} systemGenerateObject100 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMessagehome(systemGenerateObject100: SystemGenerateObject100, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMessagehome(systemGenerateObject100, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomeApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加站内信
         * @param {SystemGenerateObject99} systemGenerateObject99 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessagehome(systemGenerateObject99: SystemGenerateObject99, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addMessagehome(systemGenerateObject99, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除站内信
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMessagehome(messageId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delMessagehome(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取首页统计
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeStatisticshome(options?: any): AxiosPromise<ResultVo> {
            return localVarFp.getHomeStatisticshome(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取站内信列表
         * @param {SystemGenerateObject97} systemGenerateObject97 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessagehome(systemGenerateObject97: SystemGenerateObject97, options?: any): AxiosPromise<any> {
            return localVarFp.listMessagehome(systemGenerateObject97, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取站内信列表(分页)
         * @param {SystemGenerateObject98} systemGenerateObject98 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMessagehome(systemGenerateObject98: SystemGenerateObject98, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageMessagehome(systemGenerateObject98, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新站内信
         * @param {SystemGenerateObject100} systemGenerateObject100 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessagehome(systemGenerateObject100: SystemGenerateObject100, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateMessagehome(systemGenerateObject100, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI {
    /**
     * 
     * @summary 添加站内信
     * @param {SystemGenerateObject99} systemGenerateObject99 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public addMessagehome(systemGenerateObject99: SystemGenerateObject99, options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).addMessagehome(systemGenerateObject99, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除站内信
     * @param {number} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public delMessagehome(messageId: number, options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).delMessagehome(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取首页统计
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getHomeStatisticshome(options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).getHomeStatisticshome(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取站内信列表
     * @param {SystemGenerateObject97} systemGenerateObject97 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public listMessagehome(systemGenerateObject97: SystemGenerateObject97, options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).listMessagehome(systemGenerateObject97, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取站内信列表(分页)
     * @param {SystemGenerateObject98} systemGenerateObject98 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public pageMessagehome(systemGenerateObject98: SystemGenerateObject98, options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).pageMessagehome(systemGenerateObject98, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新站内信
     * @param {SystemGenerateObject100} systemGenerateObject100 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public updateMessagehome(systemGenerateObject100: SystemGenerateObject100, options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).updateMessagehome(systemGenerateObject100, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 微信开放平台第三方平台事件通知
         * @param {string} appid 
         * @param {SystemGenerateObject52} systemGenerateObject52 
         * @param {string} [timestamp] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpmessage: async (appid: string, systemGenerateObject52: SystemGenerateObject52, timestamp?: string, nonce?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appid' is not null or undefined
            assertParamExists('mpmessage', 'appid', appid)
            // verify required parameter 'systemGenerateObject52' is not null or undefined
            assertParamExists('mpmessage', 'systemGenerateObject52', systemGenerateObject52)
            const localVarPath = `/message/mp/{appid}`
                .replace(`{${"appid"}}`, encodeURIComponent(String(appid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (nonce !== undefined) {
                localVarQueryParameter['nonce'] = nonce;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject52, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 微信开放平台事件通知
         * @param {SystemGenerateObject51} systemGenerateObject51 
         * @param {string} [timestamp] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openwxmessage: async (systemGenerateObject51: SystemGenerateObject51, timestamp?: string, nonce?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject51' is not null or undefined
            assertParamExists('openwxmessage', 'systemGenerateObject51', systemGenerateObject51)
            const localVarPath = `/message/openwx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (nonce !== undefined) {
                localVarQueryParameter['nonce'] = nonce;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject51, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 微信开放平台第三方平台事件通知
         * @param {string} appid 
         * @param {SystemGenerateObject52} systemGenerateObject52 
         * @param {string} [timestamp] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mpmessage(appid: string, systemGenerateObject52: SystemGenerateObject52, timestamp?: string, nonce?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mpmessage(appid, systemGenerateObject52, timestamp, nonce, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 微信开放平台事件通知
         * @param {SystemGenerateObject51} systemGenerateObject51 
         * @param {string} [timestamp] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openwxmessage(systemGenerateObject51: SystemGenerateObject51, timestamp?: string, nonce?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openwxmessage(systemGenerateObject51, timestamp, nonce, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @summary 微信开放平台第三方平台事件通知
         * @param {string} appid 
         * @param {SystemGenerateObject52} systemGenerateObject52 
         * @param {string} [timestamp] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpmessage(appid: string, systemGenerateObject52: SystemGenerateObject52, timestamp?: string, nonce?: string, options?: any): AxiosPromise<string> {
            return localVarFp.mpmessage(appid, systemGenerateObject52, timestamp, nonce, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 微信开放平台事件通知
         * @param {SystemGenerateObject51} systemGenerateObject51 
         * @param {string} [timestamp] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openwxmessage(systemGenerateObject51: SystemGenerateObject51, timestamp?: string, nonce?: string, options?: any): AxiosPromise<string> {
            return localVarFp.openwxmessage(systemGenerateObject51, timestamp, nonce, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @summary 微信开放平台第三方平台事件通知
     * @param {string} appid 
     * @param {SystemGenerateObject52} systemGenerateObject52 
     * @param {string} [timestamp] 
     * @param {string} [nonce] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public mpmessage(appid: string, systemGenerateObject52: SystemGenerateObject52, timestamp?: string, nonce?: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).mpmessage(appid, systemGenerateObject52, timestamp, nonce, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 微信开放平台事件通知
     * @param {SystemGenerateObject51} systemGenerateObject51 
     * @param {string} [timestamp] 
     * @param {string} [nonce] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public openwxmessage(systemGenerateObject51: SystemGenerateObject51, timestamp?: string, nonce?: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).openwxmessage(systemGenerateObject51, timestamp, nonce, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RecordApi - axios parameter creator
 * @export
 */
export const RecordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加记录
         * @param {SystemGenerateObject108} systemGenerateObject108 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecordrecord: async (systemGenerateObject108: SystemGenerateObject108, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject108' is not null or undefined
            assertParamExists('addRecordrecord', 'systemGenerateObject108', systemGenerateObject108)
            const localVarPath = `/record/addRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject108, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除记录
         * @param {number} recordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delRecordrecord: async (recordId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('delRecordrecord', 'recordId', recordId)
            const localVarPath = `/record/delRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (recordId !== undefined) {
                localVarQueryParameter['recordId'] = recordId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取记录列表(管理员后台分页)
         * @param {SystemGenerateObject105} systemGenerateObject105 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAdminRecordrecord: async (systemGenerateObject105: SystemGenerateObject105, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject105' is not null or undefined
            assertParamExists('pageAdminRecordrecord', 'systemGenerateObject105', systemGenerateObject105)
            const localVarPath = `/record/pageAdminRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject105, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取记录列表(商家端分页, 默认最近7天)
         * @param {SystemGenerateObject107} systemGenerateObject107 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOwnerStoreRecordrecord: async (systemGenerateObject107: SystemGenerateObject107, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject107' is not null or undefined
            assertParamExists('pageOwnerStoreRecordrecord', 'systemGenerateObject107', systemGenerateObject107)
            const localVarPath = `/record/pageOwnerStoreRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject107, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取记录列表(用户端分页，只能最近7天)
         * @param {SystemGenerateObject106} systemGenerateObject106 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageUserRecordrecord: async (systemGenerateObject106: SystemGenerateObject106, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject106' is not null or undefined
            assertParamExists('pageUserRecordrecord', 'systemGenerateObject106', systemGenerateObject106)
            const localVarPath = `/record/pageUserRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject106, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新记录
         * @param {SystemGenerateObject109} systemGenerateObject109 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecordrecord: async (systemGenerateObject109: SystemGenerateObject109, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject109' is not null or undefined
            assertParamExists('updateRecordrecord', 'systemGenerateObject109', systemGenerateObject109)
            const localVarPath = `/record/updateRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject109, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecordApi - functional programming interface
 * @export
 */
export const RecordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加记录
         * @param {SystemGenerateObject108} systemGenerateObject108 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRecordrecord(systemGenerateObject108: SystemGenerateObject108, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRecordrecord(systemGenerateObject108, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除记录
         * @param {number} recordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delRecordrecord(recordId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delRecordrecord(recordId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取记录列表(管理员后台分页)
         * @param {SystemGenerateObject105} systemGenerateObject105 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageAdminRecordrecord(systemGenerateObject105: SystemGenerateObject105, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageAdminRecordrecord(systemGenerateObject105, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取记录列表(商家端分页, 默认最近7天)
         * @param {SystemGenerateObject107} systemGenerateObject107 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageOwnerStoreRecordrecord(systemGenerateObject107: SystemGenerateObject107, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageOwnerStoreRecordrecord(systemGenerateObject107, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取记录列表(用户端分页，只能最近7天)
         * @param {SystemGenerateObject106} systemGenerateObject106 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageUserRecordrecord(systemGenerateObject106: SystemGenerateObject106, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageUserRecordrecord(systemGenerateObject106, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新记录
         * @param {SystemGenerateObject109} systemGenerateObject109 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecordrecord(systemGenerateObject109: SystemGenerateObject109, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecordrecord(systemGenerateObject109, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecordApi - factory interface
 * @export
 */
export const RecordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecordApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加记录
         * @param {SystemGenerateObject108} systemGenerateObject108 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecordrecord(systemGenerateObject108: SystemGenerateObject108, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addRecordrecord(systemGenerateObject108, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除记录
         * @param {number} recordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delRecordrecord(recordId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delRecordrecord(recordId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取记录列表(管理员后台分页)
         * @param {SystemGenerateObject105} systemGenerateObject105 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAdminRecordrecord(systemGenerateObject105: SystemGenerateObject105, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageAdminRecordrecord(systemGenerateObject105, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取记录列表(商家端分页, 默认最近7天)
         * @param {SystemGenerateObject107} systemGenerateObject107 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOwnerStoreRecordrecord(systemGenerateObject107: SystemGenerateObject107, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageOwnerStoreRecordrecord(systemGenerateObject107, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取记录列表(用户端分页，只能最近7天)
         * @param {SystemGenerateObject106} systemGenerateObject106 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageUserRecordrecord(systemGenerateObject106: SystemGenerateObject106, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageUserRecordrecord(systemGenerateObject106, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新记录
         * @param {SystemGenerateObject109} systemGenerateObject109 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecordrecord(systemGenerateObject109: SystemGenerateObject109, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateRecordrecord(systemGenerateObject109, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecordApi - object-oriented interface
 * @export
 * @class RecordApi
 * @extends {BaseAPI}
 */
export class RecordApi extends BaseAPI {
    /**
     * 
     * @summary 添加记录
     * @param {SystemGenerateObject108} systemGenerateObject108 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordApi
     */
    public addRecordrecord(systemGenerateObject108: SystemGenerateObject108, options?: AxiosRequestConfig) {
        return RecordApiFp(this.configuration).addRecordrecord(systemGenerateObject108, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除记录
     * @param {number} recordId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordApi
     */
    public delRecordrecord(recordId: number, options?: AxiosRequestConfig) {
        return RecordApiFp(this.configuration).delRecordrecord(recordId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取记录列表(管理员后台分页)
     * @param {SystemGenerateObject105} systemGenerateObject105 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordApi
     */
    public pageAdminRecordrecord(systemGenerateObject105: SystemGenerateObject105, options?: AxiosRequestConfig) {
        return RecordApiFp(this.configuration).pageAdminRecordrecord(systemGenerateObject105, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取记录列表(商家端分页, 默认最近7天)
     * @param {SystemGenerateObject107} systemGenerateObject107 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordApi
     */
    public pageOwnerStoreRecordrecord(systemGenerateObject107: SystemGenerateObject107, options?: AxiosRequestConfig) {
        return RecordApiFp(this.configuration).pageOwnerStoreRecordrecord(systemGenerateObject107, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取记录列表(用户端分页，只能最近7天)
     * @param {SystemGenerateObject106} systemGenerateObject106 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordApi
     */
    public pageUserRecordrecord(systemGenerateObject106: SystemGenerateObject106, options?: AxiosRequestConfig) {
        return RecordApiFp(this.configuration).pageUserRecordrecord(systemGenerateObject106, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新记录
     * @param {SystemGenerateObject109} systemGenerateObject109 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordApi
     */
    public updateRecordrecord(systemGenerateObject109: SystemGenerateObject109, options?: AxiosRequestConfig) {
        return RecordApiFp(this.configuration).updateRecordrecord(systemGenerateObject109, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加一店一码直开二维码
         * @param {SystemGenerateObject77} systemGenerateObject77 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDirectQrcodeUrlstore: async (systemGenerateObject77: SystemGenerateObject77, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject77' is not null or undefined
            assertParamExists('addDirectQrcodeUrlstore', 'systemGenerateObject77', systemGenerateObject77)
            const localVarPath = `/store/addDirectQrcodeUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject77, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加一店一码H5二维码
         * @param {SystemGenerateObject76} systemGenerateObject76 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addH5Qrcodestore: async (systemGenerateObject76: SystemGenerateObject76, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject76' is not null or undefined
            assertParamExists('addH5Qrcodestore', 'systemGenerateObject76', systemGenerateObject76)
            const localVarPath = `/store/addH5Qrcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject76, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加自身管理门店
         * @param {SystemGenerateObject64} systemGenerateObject64 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOwnerStorestore: async (systemGenerateObject64: SystemGenerateObject64, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject64' is not null or undefined
            assertParamExists('addOwnerStorestore', 'systemGenerateObject64', systemGenerateObject64)
            const localVarPath = `/store/addOwnerStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject64, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加门店
         * @param {SystemGenerateObject68} systemGenerateObject68 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStorestore: async (systemGenerateObject68: SystemGenerateObject68, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject68' is not null or undefined
            assertParamExists('addStorestore', 'systemGenerateObject68', systemGenerateObject68)
            const localVarPath = `/store/addStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject68, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加一店一码小程序二维码
         * @param {SystemGenerateObject75} systemGenerateObject75 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWeappQrcodestore: async (systemGenerateObject75: SystemGenerateObject75, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject75' is not null or undefined
            assertParamExists('addWeappQrcodestore', 'systemGenerateObject75', systemGenerateObject75)
            const localVarPath = `/store/addWeappQrcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject75, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 绑定一店一码公众号
         * @param {SystemGenerateObject74} systemGenerateObject74 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindWxstore: async (systemGenerateObject74: SystemGenerateObject74, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject74' is not null or undefined
            assertParamExists('bindWxstore', 'systemGenerateObject74', systemGenerateObject74)
            const localVarPath = `/store/bindWx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject74, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除一店一码直开二维码
         * @param {SystemGenerateObject80} systemGenerateObject80 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDirectQrcodestore: async (systemGenerateObject80: SystemGenerateObject80, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject80' is not null or undefined
            assertParamExists('delDirectQrcodestore', 'systemGenerateObject80', systemGenerateObject80)
            const localVarPath = `/store/delDirectQrcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject80, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除自身管理门店
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOwnerStorestore: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('delOwnerStorestore', 'storeId', storeId)
            const localVarPath = `/store/delOwnerStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除门店
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStorestore: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('delStorestore', 'storeId', storeId)
            const localVarPath = `/store/delStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除一店一码小程序二维码
         * @param {SystemGenerateObject78} systemGenerateObject78 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWeappQrcodestore: async (systemGenerateObject78: SystemGenerateObject78, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject78' is not null or undefined
            assertParamExists('delWeappQrcodestore', 'systemGenerateObject78', systemGenerateObject78)
            const localVarPath = `/store/delWeappQrcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject78, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除一店一码公众号二维码
         * @param {SystemGenerateObject79} systemGenerateObject79 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWxQrcodestore: async (systemGenerateObject79: SystemGenerateObject79, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject79' is not null or undefined
            assertParamExists('delWxQrcodestore', 'systemGenerateObject79', systemGenerateObject79)
            const localVarPath = `/store/delWxQrcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject79, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取自身管理的门店详情
         * @param {SystemGenerateObject62} systemGenerateObject62 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnerStoreDetailstore: async (systemGenerateObject62: SystemGenerateObject62, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject62' is not null or undefined
            assertParamExists('getOwnerStoreDetailstore', 'systemGenerateObject62', systemGenerateObject62)
            const localVarPath = `/store/getOwnerStoreDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject62, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取一店一码列表
         * @param {SystemGenerateObject73} systemGenerateObject73 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQrcodesstore: async (systemGenerateObject73: SystemGenerateObject73, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject73' is not null or undefined
            assertParamExists('getQrcodesstore', 'systemGenerateObject73', systemGenerateObject73)
            const localVarPath = `/store/getQrcodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject73, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取自身管理的门店列表
         * @param {SystemGenerateObject63} systemGenerateObject63 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnerStorestore: async (systemGenerateObject63: SystemGenerateObject63, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject63' is not null or undefined
            assertParamExists('listOwnerStorestore', 'systemGenerateObject63', systemGenerateObject63)
            const localVarPath = `/store/listOwnerStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject63, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取门店列表
         * @param {SystemGenerateObject66} systemGenerateObject66 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStorestore: async (systemGenerateObject66: SystemGenerateObject66, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject66' is not null or undefined
            assertParamExists('listStorestore', 'systemGenerateObject66', systemGenerateObject66)
            const localVarPath = `/store/listStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject66, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 扫码开门
         * @param {SystemGenerateObject71} systemGenerateObject71 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorByCodestore: async (systemGenerateObject71: SystemGenerateObject71, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject71' is not null or undefined
            assertParamExists('opendoorByCodestore', 'systemGenerateObject71', systemGenerateObject71)
            const localVarPath = `/store/opendoorByCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject71, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 直接开门
         * @param {SystemGenerateObject72} systemGenerateObject72 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorByDirectstore: async (systemGenerateObject72: SystemGenerateObject72, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject72' is not null or undefined
            assertParamExists('opendoorByDirectstore', 'systemGenerateObject72', systemGenerateObject72)
            const localVarPath = `/store/opendoorByDirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject72, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 定位开门
         * @param {SystemGenerateObject70} systemGenerateObject70 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorByGeostore: async (systemGenerateObject70: SystemGenerateObject70, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject70' is not null or undefined
            assertParamExists('opendoorByGeostore', 'systemGenerateObject70', systemGenerateObject70)
            const localVarPath = `/store/opendoorByGeo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject70, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 打开自身管理的门店门
         * @param {SystemGenerateObject61} systemGenerateObject61 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorOwnerStorestore: async (systemGenerateObject61: SystemGenerateObject61, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject61' is not null or undefined
            assertParamExists('opendoorOwnerStorestore', 'systemGenerateObject61', systemGenerateObject61)
            const localVarPath = `/store/opendoorOwnerStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject61, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取门店列表(分页)
         * @param {SystemGenerateObject67} systemGenerateObject67 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageStorestore: async (systemGenerateObject67: SystemGenerateObject67, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject67' is not null or undefined
            assertParamExists('pageStorestore', 'systemGenerateObject67', systemGenerateObject67)
            const localVarPath = `/store/pageStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject67, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 设置一店一码客户端下载二维码
         * @param {SystemGenerateObject81} systemGenerateObject81 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDownloadQrcodestore: async (systemGenerateObject81: SystemGenerateObject81, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject81' is not null or undefined
            assertParamExists('setDownloadQrcodestore', 'systemGenerateObject81', systemGenerateObject81)
            const localVarPath = `/store/setDownloadQrcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject81, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 设置自身管理的门店模式
         * @param {SystemGenerateObject60} systemGenerateObject60 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setModOwnerStorestore: async (systemGenerateObject60: SystemGenerateObject60, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject60' is not null or undefined
            assertParamExists('setModOwnerStorestore', 'systemGenerateObject60', systemGenerateObject60)
            const localVarPath = `/store/setModOwnerStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject60, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新自身管理门店
         * @param {SystemGenerateObject65} systemGenerateObject65 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerStorestore: async (systemGenerateObject65: SystemGenerateObject65, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject65' is not null or undefined
            assertParamExists('updateOwnerStorestore', 'systemGenerateObject65', systemGenerateObject65)
            const localVarPath = `/store/updateOwnerStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject65, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新门店
         * @param {SystemGenerateObject69} systemGenerateObject69 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStorestore: async (systemGenerateObject69: SystemGenerateObject69, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject69' is not null or undefined
            assertParamExists('updateStorestore', 'systemGenerateObject69', systemGenerateObject69)
            const localVarPath = `/store/updateStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject69, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加一店一码直开二维码
         * @param {SystemGenerateObject77} systemGenerateObject77 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDirectQrcodeUrlstore(systemGenerateObject77: SystemGenerateObject77, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDirectQrcodeUrlstore(systemGenerateObject77, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加一店一码H5二维码
         * @param {SystemGenerateObject76} systemGenerateObject76 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addH5Qrcodestore(systemGenerateObject76: SystemGenerateObject76, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addH5Qrcodestore(systemGenerateObject76, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加自身管理门店
         * @param {SystemGenerateObject64} systemGenerateObject64 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOwnerStorestore(systemGenerateObject64: SystemGenerateObject64, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOwnerStorestore(systemGenerateObject64, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加门店
         * @param {SystemGenerateObject68} systemGenerateObject68 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStorestore(systemGenerateObject68: SystemGenerateObject68, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStorestore(systemGenerateObject68, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加一店一码小程序二维码
         * @param {SystemGenerateObject75} systemGenerateObject75 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWeappQrcodestore(systemGenerateObject75: SystemGenerateObject75, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWeappQrcodestore(systemGenerateObject75, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 绑定一店一码公众号
         * @param {SystemGenerateObject74} systemGenerateObject74 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bindWxstore(systemGenerateObject74: SystemGenerateObject74, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bindWxstore(systemGenerateObject74, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除一店一码直开二维码
         * @param {SystemGenerateObject80} systemGenerateObject80 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delDirectQrcodestore(systemGenerateObject80: SystemGenerateObject80, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delDirectQrcodestore(systemGenerateObject80, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除自身管理门店
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOwnerStorestore(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOwnerStorestore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除门店
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delStorestore(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delStorestore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除一店一码小程序二维码
         * @param {SystemGenerateObject78} systemGenerateObject78 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delWeappQrcodestore(systemGenerateObject78: SystemGenerateObject78, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delWeappQrcodestore(systemGenerateObject78, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除一店一码公众号二维码
         * @param {SystemGenerateObject79} systemGenerateObject79 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delWxQrcodestore(systemGenerateObject79: SystemGenerateObject79, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delWxQrcodestore(systemGenerateObject79, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取自身管理的门店详情
         * @param {SystemGenerateObject62} systemGenerateObject62 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnerStoreDetailstore(systemGenerateObject62: SystemGenerateObject62, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnerStoreDetailstore(systemGenerateObject62, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取一店一码列表
         * @param {SystemGenerateObject73} systemGenerateObject73 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQrcodesstore(systemGenerateObject73: SystemGenerateObject73, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQrcodesstore(systemGenerateObject73, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取自身管理的门店列表
         * @param {SystemGenerateObject63} systemGenerateObject63 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOwnerStorestore(systemGenerateObject63: SystemGenerateObject63, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOwnerStorestore(systemGenerateObject63, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取门店列表
         * @param {SystemGenerateObject66} systemGenerateObject66 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStorestore(systemGenerateObject66: SystemGenerateObject66, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStorestore(systemGenerateObject66, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 扫码开门
         * @param {SystemGenerateObject71} systemGenerateObject71 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opendoorByCodestore(systemGenerateObject71: SystemGenerateObject71, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opendoorByCodestore(systemGenerateObject71, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 直接开门
         * @param {SystemGenerateObject72} systemGenerateObject72 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opendoorByDirectstore(systemGenerateObject72: SystemGenerateObject72, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opendoorByDirectstore(systemGenerateObject72, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 定位开门
         * @param {SystemGenerateObject70} systemGenerateObject70 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opendoorByGeostore(systemGenerateObject70: SystemGenerateObject70, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opendoorByGeostore(systemGenerateObject70, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 打开自身管理的门店门
         * @param {SystemGenerateObject61} systemGenerateObject61 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opendoorOwnerStorestore(systemGenerateObject61: SystemGenerateObject61, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opendoorOwnerStorestore(systemGenerateObject61, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取门店列表(分页)
         * @param {SystemGenerateObject67} systemGenerateObject67 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageStorestore(systemGenerateObject67: SystemGenerateObject67, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageStorestore(systemGenerateObject67, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 设置一店一码客户端下载二维码
         * @param {SystemGenerateObject81} systemGenerateObject81 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDownloadQrcodestore(systemGenerateObject81: SystemGenerateObject81, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDownloadQrcodestore(systemGenerateObject81, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 设置自身管理的门店模式
         * @param {SystemGenerateObject60} systemGenerateObject60 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setModOwnerStorestore(systemGenerateObject60: SystemGenerateObject60, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setModOwnerStorestore(systemGenerateObject60, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新自身管理门店
         * @param {SystemGenerateObject65} systemGenerateObject65 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnerStorestore(systemGenerateObject65: SystemGenerateObject65, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnerStorestore(systemGenerateObject65, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新门店
         * @param {SystemGenerateObject69} systemGenerateObject69 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStorestore(systemGenerateObject69: SystemGenerateObject69, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStorestore(systemGenerateObject69, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加一店一码直开二维码
         * @param {SystemGenerateObject77} systemGenerateObject77 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDirectQrcodeUrlstore(systemGenerateObject77: SystemGenerateObject77, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addDirectQrcodeUrlstore(systemGenerateObject77, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加一店一码H5二维码
         * @param {SystemGenerateObject76} systemGenerateObject76 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addH5Qrcodestore(systemGenerateObject76: SystemGenerateObject76, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addH5Qrcodestore(systemGenerateObject76, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加自身管理门店
         * @param {SystemGenerateObject64} systemGenerateObject64 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOwnerStorestore(systemGenerateObject64: SystemGenerateObject64, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addOwnerStorestore(systemGenerateObject64, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加门店
         * @param {SystemGenerateObject68} systemGenerateObject68 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStorestore(systemGenerateObject68: SystemGenerateObject68, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addStorestore(systemGenerateObject68, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加一店一码小程序二维码
         * @param {SystemGenerateObject75} systemGenerateObject75 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWeappQrcodestore(systemGenerateObject75: SystemGenerateObject75, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addWeappQrcodestore(systemGenerateObject75, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 绑定一店一码公众号
         * @param {SystemGenerateObject74} systemGenerateObject74 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindWxstore(systemGenerateObject74: SystemGenerateObject74, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.bindWxstore(systemGenerateObject74, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除一店一码直开二维码
         * @param {SystemGenerateObject80} systemGenerateObject80 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDirectQrcodestore(systemGenerateObject80: SystemGenerateObject80, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delDirectQrcodestore(systemGenerateObject80, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除自身管理门店
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOwnerStorestore(storeId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delOwnerStorestore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除门店
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStorestore(storeId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delStorestore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除一店一码小程序二维码
         * @param {SystemGenerateObject78} systemGenerateObject78 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWeappQrcodestore(systemGenerateObject78: SystemGenerateObject78, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delWeappQrcodestore(systemGenerateObject78, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除一店一码公众号二维码
         * @param {SystemGenerateObject79} systemGenerateObject79 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWxQrcodestore(systemGenerateObject79: SystemGenerateObject79, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delWxQrcodestore(systemGenerateObject79, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取自身管理的门店详情
         * @param {SystemGenerateObject62} systemGenerateObject62 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnerStoreDetailstore(systemGenerateObject62: SystemGenerateObject62, options?: any): AxiosPromise<any> {
            return localVarFp.getOwnerStoreDetailstore(systemGenerateObject62, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取一店一码列表
         * @param {SystemGenerateObject73} systemGenerateObject73 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQrcodesstore(systemGenerateObject73: SystemGenerateObject73, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.getQrcodesstore(systemGenerateObject73, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取自身管理的门店列表
         * @param {SystemGenerateObject63} systemGenerateObject63 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnerStorestore(systemGenerateObject63: SystemGenerateObject63, options?: any): AxiosPromise<any> {
            return localVarFp.listOwnerStorestore(systemGenerateObject63, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取门店列表
         * @param {SystemGenerateObject66} systemGenerateObject66 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStorestore(systemGenerateObject66: SystemGenerateObject66, options?: any): AxiosPromise<any> {
            return localVarFp.listStorestore(systemGenerateObject66, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 扫码开门
         * @param {SystemGenerateObject71} systemGenerateObject71 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorByCodestore(systemGenerateObject71: SystemGenerateObject71, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.opendoorByCodestore(systemGenerateObject71, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 直接开门
         * @param {SystemGenerateObject72} systemGenerateObject72 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorByDirectstore(systemGenerateObject72: SystemGenerateObject72, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.opendoorByDirectstore(systemGenerateObject72, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 定位开门
         * @param {SystemGenerateObject70} systemGenerateObject70 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorByGeostore(systemGenerateObject70: SystemGenerateObject70, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.opendoorByGeostore(systemGenerateObject70, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 打开自身管理的门店门
         * @param {SystemGenerateObject61} systemGenerateObject61 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opendoorOwnerStorestore(systemGenerateObject61: SystemGenerateObject61, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.opendoorOwnerStorestore(systemGenerateObject61, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取门店列表(分页)
         * @param {SystemGenerateObject67} systemGenerateObject67 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageStorestore(systemGenerateObject67: SystemGenerateObject67, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageStorestore(systemGenerateObject67, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 设置一店一码客户端下载二维码
         * @param {SystemGenerateObject81} systemGenerateObject81 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDownloadQrcodestore(systemGenerateObject81: SystemGenerateObject81, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.setDownloadQrcodestore(systemGenerateObject81, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 设置自身管理的门店模式
         * @param {SystemGenerateObject60} systemGenerateObject60 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setModOwnerStorestore(systemGenerateObject60: SystemGenerateObject60, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.setModOwnerStorestore(systemGenerateObject60, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新自身管理门店
         * @param {SystemGenerateObject65} systemGenerateObject65 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerStorestore(systemGenerateObject65: SystemGenerateObject65, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateOwnerStorestore(systemGenerateObject65, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新门店
         * @param {SystemGenerateObject69} systemGenerateObject69 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStorestore(systemGenerateObject69: SystemGenerateObject69, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateStorestore(systemGenerateObject69, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * 
     * @summary 添加一店一码直开二维码
     * @param {SystemGenerateObject77} systemGenerateObject77 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addDirectQrcodeUrlstore(systemGenerateObject77: SystemGenerateObject77, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addDirectQrcodeUrlstore(systemGenerateObject77, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加一店一码H5二维码
     * @param {SystemGenerateObject76} systemGenerateObject76 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addH5Qrcodestore(systemGenerateObject76: SystemGenerateObject76, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addH5Qrcodestore(systemGenerateObject76, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加自身管理门店
     * @param {SystemGenerateObject64} systemGenerateObject64 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addOwnerStorestore(systemGenerateObject64: SystemGenerateObject64, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addOwnerStorestore(systemGenerateObject64, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加门店
     * @param {SystemGenerateObject68} systemGenerateObject68 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addStorestore(systemGenerateObject68: SystemGenerateObject68, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addStorestore(systemGenerateObject68, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加一店一码小程序二维码
     * @param {SystemGenerateObject75} systemGenerateObject75 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addWeappQrcodestore(systemGenerateObject75: SystemGenerateObject75, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addWeappQrcodestore(systemGenerateObject75, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 绑定一店一码公众号
     * @param {SystemGenerateObject74} systemGenerateObject74 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public bindWxstore(systemGenerateObject74: SystemGenerateObject74, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).bindWxstore(systemGenerateObject74, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除一店一码直开二维码
     * @param {SystemGenerateObject80} systemGenerateObject80 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public delDirectQrcodestore(systemGenerateObject80: SystemGenerateObject80, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).delDirectQrcodestore(systemGenerateObject80, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除自身管理门店
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public delOwnerStorestore(storeId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).delOwnerStorestore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除门店
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public delStorestore(storeId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).delStorestore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除一店一码小程序二维码
     * @param {SystemGenerateObject78} systemGenerateObject78 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public delWeappQrcodestore(systemGenerateObject78: SystemGenerateObject78, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).delWeappQrcodestore(systemGenerateObject78, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除一店一码公众号二维码
     * @param {SystemGenerateObject79} systemGenerateObject79 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public delWxQrcodestore(systemGenerateObject79: SystemGenerateObject79, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).delWxQrcodestore(systemGenerateObject79, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取自身管理的门店详情
     * @param {SystemGenerateObject62} systemGenerateObject62 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getOwnerStoreDetailstore(systemGenerateObject62: SystemGenerateObject62, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getOwnerStoreDetailstore(systemGenerateObject62, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取一店一码列表
     * @param {SystemGenerateObject73} systemGenerateObject73 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getQrcodesstore(systemGenerateObject73: SystemGenerateObject73, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getQrcodesstore(systemGenerateObject73, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取自身管理的门店列表
     * @param {SystemGenerateObject63} systemGenerateObject63 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public listOwnerStorestore(systemGenerateObject63: SystemGenerateObject63, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).listOwnerStorestore(systemGenerateObject63, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取门店列表
     * @param {SystemGenerateObject66} systemGenerateObject66 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public listStorestore(systemGenerateObject66: SystemGenerateObject66, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).listStorestore(systemGenerateObject66, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 扫码开门
     * @param {SystemGenerateObject71} systemGenerateObject71 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public opendoorByCodestore(systemGenerateObject71: SystemGenerateObject71, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).opendoorByCodestore(systemGenerateObject71, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 直接开门
     * @param {SystemGenerateObject72} systemGenerateObject72 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public opendoorByDirectstore(systemGenerateObject72: SystemGenerateObject72, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).opendoorByDirectstore(systemGenerateObject72, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 定位开门
     * @param {SystemGenerateObject70} systemGenerateObject70 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public opendoorByGeostore(systemGenerateObject70: SystemGenerateObject70, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).opendoorByGeostore(systemGenerateObject70, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 打开自身管理的门店门
     * @param {SystemGenerateObject61} systemGenerateObject61 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public opendoorOwnerStorestore(systemGenerateObject61: SystemGenerateObject61, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).opendoorOwnerStorestore(systemGenerateObject61, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取门店列表(分页)
     * @param {SystemGenerateObject67} systemGenerateObject67 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public pageStorestore(systemGenerateObject67: SystemGenerateObject67, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).pageStorestore(systemGenerateObject67, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 设置一店一码客户端下载二维码
     * @param {SystemGenerateObject81} systemGenerateObject81 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public setDownloadQrcodestore(systemGenerateObject81: SystemGenerateObject81, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).setDownloadQrcodestore(systemGenerateObject81, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 设置自身管理的门店模式
     * @param {SystemGenerateObject60} systemGenerateObject60 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public setModOwnerStorestore(systemGenerateObject60: SystemGenerateObject60, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).setModOwnerStorestore(systemGenerateObject60, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新自身管理门店
     * @param {SystemGenerateObject65} systemGenerateObject65 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public updateOwnerStorestore(systemGenerateObject65: SystemGenerateObject65, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).updateOwnerStorestore(systemGenerateObject65, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新门店
     * @param {SystemGenerateObject69} systemGenerateObject69 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public updateStorestore(systemGenerateObject69: SystemGenerateObject69, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).updateStorestore(systemGenerateObject69, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubAccountApi - axios parameter creator
 * @export
 */
export const SubAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加自身管理的门店子账户
         * @param {SystemGenerateObject87} systemGenerateObject87 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOwnerStoreSubAccountsubAccount: async (systemGenerateObject87: SystemGenerateObject87, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject87' is not null or undefined
            assertParamExists('addOwnerStoreSubAccountsubAccount', 'systemGenerateObject87', systemGenerateObject87)
            const localVarPath = `/subAccount/addOwnerStoreSubAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject87, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加子账户
         * @param {SystemGenerateObject84} systemGenerateObject84 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubAccountsubAccount: async (systemGenerateObject84: SystemGenerateObject84, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject84' is not null or undefined
            assertParamExists('addSubAccountsubAccount', 'systemGenerateObject84', systemGenerateObject84)
            const localVarPath = `/subAccount/addSubAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject84, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除自身管理的门店子账户
         * @param {number} storeId 
         * @param {number} subAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOwnerStoreSubAccountsubAccount: async (storeId: number, subAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('delOwnerStoreSubAccountsubAccount', 'storeId', storeId)
            // verify required parameter 'subAccountId' is not null or undefined
            assertParamExists('delOwnerStoreSubAccountsubAccount', 'subAccountId', subAccountId)
            const localVarPath = `/subAccount/delOwnerStoreSubAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (subAccountId !== undefined) {
                localVarQueryParameter['subAccountId'] = subAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除子账户
         * @param {number} subAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delSubAccountsubAccount: async (subAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subAccountId' is not null or undefined
            assertParamExists('delSubAccountsubAccount', 'subAccountId', subAccountId)
            const localVarPath = `/subAccount/delSubAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subAccountId !== undefined) {
                localVarQueryParameter['subAccountId'] = subAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取自身管理的门店子账户列表
         * @param {SystemGenerateObject86} systemGenerateObject86 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnerStoreSubAccountsubAccount: async (systemGenerateObject86: SystemGenerateObject86, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject86' is not null or undefined
            assertParamExists('listOwnerStoreSubAccountsubAccount', 'systemGenerateObject86', systemGenerateObject86)
            const localVarPath = `/subAccount/listOwnerStoreSubAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject86, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取子账户列表
         * @param {SystemGenerateObject82} systemGenerateObject82 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubAccountsubAccount: async (systemGenerateObject82: SystemGenerateObject82, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject82' is not null or undefined
            assertParamExists('listSubAccountsubAccount', 'systemGenerateObject82', systemGenerateObject82)
            const localVarPath = `/subAccount/listSubAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject82, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取子账户列表(分页)
         * @param {SystemGenerateObject83} systemGenerateObject83 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSubAccountsubAccount: async (systemGenerateObject83: SystemGenerateObject83, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject83' is not null or undefined
            assertParamExists('pageSubAccountsubAccount', 'systemGenerateObject83', systemGenerateObject83)
            const localVarPath = `/subAccount/pageSubAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject83, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新自身管理的门店子账户
         * @param {SystemGenerateObject88} systemGenerateObject88 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerStoreSubAccountsubAccount: async (systemGenerateObject88: SystemGenerateObject88, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject88' is not null or undefined
            assertParamExists('updateOwnerStoreSubAccountsubAccount', 'systemGenerateObject88', systemGenerateObject88)
            const localVarPath = `/subAccount/updateOwnerStoreSubAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject88, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新子账户
         * @param {SystemGenerateObject85} systemGenerateObject85 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubAccountsubAccount: async (systemGenerateObject85: SystemGenerateObject85, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject85' is not null or undefined
            assertParamExists('updateSubAccountsubAccount', 'systemGenerateObject85', systemGenerateObject85)
            const localVarPath = `/subAccount/updateSubAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject85, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubAccountApi - functional programming interface
 * @export
 */
export const SubAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加自身管理的门店子账户
         * @param {SystemGenerateObject87} systemGenerateObject87 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOwnerStoreSubAccountsubAccount(systemGenerateObject87: SystemGenerateObject87, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOwnerStoreSubAccountsubAccount(systemGenerateObject87, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加子账户
         * @param {SystemGenerateObject84} systemGenerateObject84 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSubAccountsubAccount(systemGenerateObject84: SystemGenerateObject84, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSubAccountsubAccount(systemGenerateObject84, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除自身管理的门店子账户
         * @param {number} storeId 
         * @param {number} subAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOwnerStoreSubAccountsubAccount(storeId: number, subAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOwnerStoreSubAccountsubAccount(storeId, subAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除子账户
         * @param {number} subAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delSubAccountsubAccount(subAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delSubAccountsubAccount(subAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取自身管理的门店子账户列表
         * @param {SystemGenerateObject86} systemGenerateObject86 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOwnerStoreSubAccountsubAccount(systemGenerateObject86: SystemGenerateObject86, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOwnerStoreSubAccountsubAccount(systemGenerateObject86, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取子账户列表
         * @param {SystemGenerateObject82} systemGenerateObject82 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubAccountsubAccount(systemGenerateObject82: SystemGenerateObject82, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSubAccountsubAccount(systemGenerateObject82, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取子账户列表(分页)
         * @param {SystemGenerateObject83} systemGenerateObject83 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageSubAccountsubAccount(systemGenerateObject83: SystemGenerateObject83, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageSubAccountsubAccount(systemGenerateObject83, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新自身管理的门店子账户
         * @param {SystemGenerateObject88} systemGenerateObject88 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnerStoreSubAccountsubAccount(systemGenerateObject88: SystemGenerateObject88, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnerStoreSubAccountsubAccount(systemGenerateObject88, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新子账户
         * @param {SystemGenerateObject85} systemGenerateObject85 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubAccountsubAccount(systemGenerateObject85: SystemGenerateObject85, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubAccountsubAccount(systemGenerateObject85, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubAccountApi - factory interface
 * @export
 */
export const SubAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加自身管理的门店子账户
         * @param {SystemGenerateObject87} systemGenerateObject87 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOwnerStoreSubAccountsubAccount(systemGenerateObject87: SystemGenerateObject87, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addOwnerStoreSubAccountsubAccount(systemGenerateObject87, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加子账户
         * @param {SystemGenerateObject84} systemGenerateObject84 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubAccountsubAccount(systemGenerateObject84: SystemGenerateObject84, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addSubAccountsubAccount(systemGenerateObject84, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除自身管理的门店子账户
         * @param {number} storeId 
         * @param {number} subAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOwnerStoreSubAccountsubAccount(storeId: number, subAccountId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delOwnerStoreSubAccountsubAccount(storeId, subAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除子账户
         * @param {number} subAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delSubAccountsubAccount(subAccountId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delSubAccountsubAccount(subAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取自身管理的门店子账户列表
         * @param {SystemGenerateObject86} systemGenerateObject86 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnerStoreSubAccountsubAccount(systemGenerateObject86: SystemGenerateObject86, options?: any): AxiosPromise<any> {
            return localVarFp.listOwnerStoreSubAccountsubAccount(systemGenerateObject86, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取子账户列表
         * @param {SystemGenerateObject82} systemGenerateObject82 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubAccountsubAccount(systemGenerateObject82: SystemGenerateObject82, options?: any): AxiosPromise<any> {
            return localVarFp.listSubAccountsubAccount(systemGenerateObject82, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取子账户列表(分页)
         * @param {SystemGenerateObject83} systemGenerateObject83 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSubAccountsubAccount(systemGenerateObject83: SystemGenerateObject83, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageSubAccountsubAccount(systemGenerateObject83, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新自身管理的门店子账户
         * @param {SystemGenerateObject88} systemGenerateObject88 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerStoreSubAccountsubAccount(systemGenerateObject88: SystemGenerateObject88, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateOwnerStoreSubAccountsubAccount(systemGenerateObject88, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新子账户
         * @param {SystemGenerateObject85} systemGenerateObject85 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubAccountsubAccount(systemGenerateObject85: SystemGenerateObject85, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateSubAccountsubAccount(systemGenerateObject85, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubAccountApi - object-oriented interface
 * @export
 * @class SubAccountApi
 * @extends {BaseAPI}
 */
export class SubAccountApi extends BaseAPI {
    /**
     * 
     * @summary 添加自身管理的门店子账户
     * @param {SystemGenerateObject87} systemGenerateObject87 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubAccountApi
     */
    public addOwnerStoreSubAccountsubAccount(systemGenerateObject87: SystemGenerateObject87, options?: AxiosRequestConfig) {
        return SubAccountApiFp(this.configuration).addOwnerStoreSubAccountsubAccount(systemGenerateObject87, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加子账户
     * @param {SystemGenerateObject84} systemGenerateObject84 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubAccountApi
     */
    public addSubAccountsubAccount(systemGenerateObject84: SystemGenerateObject84, options?: AxiosRequestConfig) {
        return SubAccountApiFp(this.configuration).addSubAccountsubAccount(systemGenerateObject84, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除自身管理的门店子账户
     * @param {number} storeId 
     * @param {number} subAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubAccountApi
     */
    public delOwnerStoreSubAccountsubAccount(storeId: number, subAccountId: number, options?: AxiosRequestConfig) {
        return SubAccountApiFp(this.configuration).delOwnerStoreSubAccountsubAccount(storeId, subAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除子账户
     * @param {number} subAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubAccountApi
     */
    public delSubAccountsubAccount(subAccountId: number, options?: AxiosRequestConfig) {
        return SubAccountApiFp(this.configuration).delSubAccountsubAccount(subAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取自身管理的门店子账户列表
     * @param {SystemGenerateObject86} systemGenerateObject86 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubAccountApi
     */
    public listOwnerStoreSubAccountsubAccount(systemGenerateObject86: SystemGenerateObject86, options?: AxiosRequestConfig) {
        return SubAccountApiFp(this.configuration).listOwnerStoreSubAccountsubAccount(systemGenerateObject86, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取子账户列表
     * @param {SystemGenerateObject82} systemGenerateObject82 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubAccountApi
     */
    public listSubAccountsubAccount(systemGenerateObject82: SystemGenerateObject82, options?: AxiosRequestConfig) {
        return SubAccountApiFp(this.configuration).listSubAccountsubAccount(systemGenerateObject82, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取子账户列表(分页)
     * @param {SystemGenerateObject83} systemGenerateObject83 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubAccountApi
     */
    public pageSubAccountsubAccount(systemGenerateObject83: SystemGenerateObject83, options?: AxiosRequestConfig) {
        return SubAccountApiFp(this.configuration).pageSubAccountsubAccount(systemGenerateObject83, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新自身管理的门店子账户
     * @param {SystemGenerateObject88} systemGenerateObject88 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubAccountApi
     */
    public updateOwnerStoreSubAccountsubAccount(systemGenerateObject88: SystemGenerateObject88, options?: AxiosRequestConfig) {
        return SubAccountApiFp(this.configuration).updateOwnerStoreSubAccountsubAccount(systemGenerateObject88, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新子账户
     * @param {SystemGenerateObject85} systemGenerateObject85 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubAccountApi
     */
    public updateSubAccountsubAccount(systemGenerateObject85: SystemGenerateObject85, options?: AxiosRequestConfig) {
        return SubAccountApiFp(this.configuration).updateSubAccountsubAccount(systemGenerateObject85, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取swagger.json
         * @summary swagger接口文档
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _87040000000000000000system: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/swagger.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取swagger.json
         * @summary swagger接口文档
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _87040000000000000000system(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._87040000000000000000system(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 获取swagger.json
         * @summary swagger接口文档
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _87040000000000000000system(options?: any): AxiosPromise<any> {
            return localVarFp._87040000000000000000system(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 获取swagger.json
     * @summary swagger接口文档
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public _87040000000000000000system(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration)._87040000000000000000system(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加租户
         * @param {TenantAdminVo} tenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTenanttenant: async (tenantAdminVo: TenantAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantAdminVo' is not null or undefined
            assertParamExists('addTenanttenant', 'tenantAdminVo', tenantAdminVo)
            const localVarPath = `/tenant/addTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除租户
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delTenanttenant: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('delTenanttenant', 'tenantId', tenantId)
            const localVarPath = `/tenant/delTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户对外显示的信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantComInfotenant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenant/getTenantComInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户列表
         * @param {PartialTenantAdminVo} partialTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenanttenant: async (partialTenantAdminVo: PartialTenantAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partialTenantAdminVo' is not null or undefined
            assertParamExists('listTenanttenant', 'partialTenantAdminVo', partialTenantAdminVo)
            const localVarPath = `/tenant/listTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialTenantAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户列表(分页)
         * @param {SystemGenerateObject25} systemGenerateObject25 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageTenanttenant: async (systemGenerateObject25: SystemGenerateObject25, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject25' is not null or undefined
            assertParamExists('pageTenanttenant', 'systemGenerateObject25', systemGenerateObject25)
            const localVarPath = `/tenant/pageTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject25, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新租户
         * @param {TenantAdminVo} tenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenanttenant: async (tenantAdminVo: TenantAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantAdminVo' is not null or undefined
            assertParamExists('updateTenanttenant', 'tenantAdminVo', tenantAdminVo)
            const localVarPath = `/tenant/updateTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加租户
         * @param {TenantAdminVo} tenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTenanttenant(tenantAdminVo: TenantAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTenanttenant(tenantAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除租户
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delTenanttenant(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delTenanttenant(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户对外显示的信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantComInfotenant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantComInfotenant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户列表
         * @param {PartialTenantAdminVo} partialTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTenanttenant(partialTenantAdminVo: PartialTenantAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTenanttenant(partialTenantAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户列表(分页)
         * @param {SystemGenerateObject25} systemGenerateObject25 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageTenanttenant(systemGenerateObject25: SystemGenerateObject25, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageTenanttenant(systemGenerateObject25, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新租户
         * @param {TenantAdminVo} tenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenanttenant(tenantAdminVo: TenantAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenanttenant(tenantAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加租户
         * @param {TenantAdminVo} tenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTenanttenant(tenantAdminVo: TenantAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addTenanttenant(tenantAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除租户
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delTenanttenant(tenantId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delTenanttenant(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户对外显示的信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantComInfotenant(options?: any): AxiosPromise<ResultVo> {
            return localVarFp.getTenantComInfotenant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户列表
         * @param {PartialTenantAdminVo} partialTenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenanttenant(partialTenantAdminVo: PartialTenantAdminVo, options?: any): AxiosPromise<any> {
            return localVarFp.listTenanttenant(partialTenantAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户列表(分页)
         * @param {SystemGenerateObject25} systemGenerateObject25 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageTenanttenant(systemGenerateObject25: SystemGenerateObject25, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageTenanttenant(systemGenerateObject25, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新租户
         * @param {TenantAdminVo} tenantAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenanttenant(tenantAdminVo: TenantAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateTenanttenant(tenantAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @summary 添加租户
     * @param {TenantAdminVo} tenantAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public addTenanttenant(tenantAdminVo: TenantAdminVo, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).addTenanttenant(tenantAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除租户
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public delTenanttenant(tenantId: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).delTenanttenant(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户对外显示的信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantComInfotenant(options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenantComInfotenant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户列表
     * @param {PartialTenantAdminVo} partialTenantAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public listTenanttenant(partialTenantAdminVo: PartialTenantAdminVo, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).listTenanttenant(partialTenantAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户列表(分页)
     * @param {SystemGenerateObject25} systemGenerateObject25 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public pageTenanttenant(systemGenerateObject25: SystemGenerateObject25, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).pageTenanttenant(systemGenerateObject25, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新租户
     * @param {TenantAdminVo} tenantAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public updateTenanttenant(tenantAdminVo: TenantAdminVo, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).updateTenanttenant(tenantAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TimerApi - axios parameter creator
 * @export
 */
export const TimerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加定时器
         * @param {SystemGenerateObject135} systemGenerateObject135 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTimertimer: async (systemGenerateObject135: SystemGenerateObject135, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject135' is not null or undefined
            assertParamExists('addTimertimer', 'systemGenerateObject135', systemGenerateObject135)
            const localVarPath = `/timer/addTimer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject135, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除定时器
         * @param {string} timerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delTimertimer: async (timerName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timerName' is not null or undefined
            assertParamExists('delTimertimer', 'timerName', timerName)
            const localVarPath = `/timer/delTimer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timerName !== undefined) {
                localVarQueryParameter['timerName'] = timerName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取定时器列表
         * @param {SystemGenerateObject134} systemGenerateObject134 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimertimer: async (systemGenerateObject134: SystemGenerateObject134, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject134' is not null or undefined
            assertParamExists('listTimertimer', 'systemGenerateObject134', systemGenerateObject134)
            const localVarPath = `/timer/listTimer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject134, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新定时器
         * @param {SystemGenerateObject136} systemGenerateObject136 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimertimer: async (systemGenerateObject136: SystemGenerateObject136, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject136' is not null or undefined
            assertParamExists('updateTimertimer', 'systemGenerateObject136', systemGenerateObject136)
            const localVarPath = `/timer/updateTimer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject136, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimerApi - functional programming interface
 * @export
 */
export const TimerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加定时器
         * @param {SystemGenerateObject135} systemGenerateObject135 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTimertimer(systemGenerateObject135: SystemGenerateObject135, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTimertimer(systemGenerateObject135, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除定时器
         * @param {string} timerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delTimertimer(timerName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delTimertimer(timerName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取定时器列表
         * @param {SystemGenerateObject134} systemGenerateObject134 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTimertimer(systemGenerateObject134: SystemGenerateObject134, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTimertimer(systemGenerateObject134, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新定时器
         * @param {SystemGenerateObject136} systemGenerateObject136 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimertimer(systemGenerateObject136: SystemGenerateObject136, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimertimer(systemGenerateObject136, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimerApi - factory interface
 * @export
 */
export const TimerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimerApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加定时器
         * @param {SystemGenerateObject135} systemGenerateObject135 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTimertimer(systemGenerateObject135: SystemGenerateObject135, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addTimertimer(systemGenerateObject135, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除定时器
         * @param {string} timerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delTimertimer(timerName: string, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delTimertimer(timerName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取定时器列表
         * @param {SystemGenerateObject134} systemGenerateObject134 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimertimer(systemGenerateObject134: SystemGenerateObject134, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.listTimertimer(systemGenerateObject134, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新定时器
         * @param {SystemGenerateObject136} systemGenerateObject136 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimertimer(systemGenerateObject136: SystemGenerateObject136, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateTimertimer(systemGenerateObject136, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimerApi - object-oriented interface
 * @export
 * @class TimerApi
 * @extends {BaseAPI}
 */
export class TimerApi extends BaseAPI {
    /**
     * 
     * @summary 添加定时器
     * @param {SystemGenerateObject135} systemGenerateObject135 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimerApi
     */
    public addTimertimer(systemGenerateObject135: SystemGenerateObject135, options?: AxiosRequestConfig) {
        return TimerApiFp(this.configuration).addTimertimer(systemGenerateObject135, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除定时器
     * @param {string} timerName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimerApi
     */
    public delTimertimer(timerName: string, options?: AxiosRequestConfig) {
        return TimerApiFp(this.configuration).delTimertimer(timerName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取定时器列表
     * @param {SystemGenerateObject134} systemGenerateObject134 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimerApi
     */
    public listTimertimer(systemGenerateObject134: SystemGenerateObject134, options?: AxiosRequestConfig) {
        return TimerApiFp(this.configuration).listTimertimer(systemGenerateObject134, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新定时器
     * @param {SystemGenerateObject136} systemGenerateObject136 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimerApi
     */
    public updateTimertimer(systemGenerateObject136: SystemGenerateObject136, options?: AxiosRequestConfig) {
        return TimerApiFp(this.configuration).updateTimertimer(systemGenerateObject136, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 上传图片
         * @summary 上传图片
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUpload: async (files: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('imageUpload', 'files', files)
            const localVarPath = `/upload/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 上传图片
         * @summary 上传图片
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUpload(files: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUpload(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 上传图片
         * @summary 上传图片
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUpload(files: Array<File>, options?: any): AxiosPromise<any> {
            return localVarFp.imageUpload(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 上传图片
     * @summary 上传图片
     * @param {Array<File>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public imageUpload(files: Array<File>, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).imageUpload(files, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取用户客户端信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClientVouser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/getUserClientVo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改客户的videoCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseVideoCountuser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/increaseVideoCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取用户客户端信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserClientVouser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserClientVouser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改客户的videoCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async increaseVideoCountuser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.increaseVideoCountuser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取用户客户端信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClientVouser(options?: any): AxiosPromise<any> {
            return localVarFp.getUserClientVouser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改客户的videoCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseVideoCountuser(options?: any): AxiosPromise<ResultVo> {
            return localVarFp.increaseVideoCountuser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary 获取用户客户端信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserClientVouser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserClientVouser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改客户的videoCount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public increaseVideoCountuser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).increaseVideoCountuser(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WeappApi - axios parameter creator
 * @export
 */
export const WeappApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加微信小程序(获取授权信息)
         * @param {SystemGenerateObject29} systemGenerateObject29 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWeappweapp: async (systemGenerateObject29: SystemGenerateObject29, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject29' is not null or undefined
            assertParamExists('addWeappweapp', 'systemGenerateObject29', systemGenerateObject29)
            const localVarPath = `/weapp/addWeapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject29, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检查配置基础配置（服务器域名、业务域名、类目）
         * @param {SystemGenerateObject33} systemGenerateObject33 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBaseSettingweapp: async (systemGenerateObject33: SystemGenerateObject33, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject33' is not null or undefined
            assertParamExists('checkBaseSettingweapp', 'systemGenerateObject33', systemGenerateObject33)
            const localVarPath = `/weapp/checkBaseSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject33, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检查接口配置（定位）
         * @param {SystemGenerateObject34} systemGenerateObject34 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPrivacyInterfaceweapp: async (systemGenerateObject34: SystemGenerateObject34, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject34' is not null or undefined
            assertParamExists('checkPrivacyInterfaceweapp', 'systemGenerateObject34', systemGenerateObject34)
            const localVarPath = `/weapp/checkPrivacyInterface`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject34, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检查发布状态
         * @param {SystemGenerateObject35} systemGenerateObject35 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkStatusweapp: async (systemGenerateObject35: SystemGenerateObject35, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject35' is not null or undefined
            assertParamExists('checkStatusweapp', 'systemGenerateObject35', systemGenerateObject35)
            const localVarPath = `/weapp/checkStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject35, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除微信小程序
         * @param {string} appid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWeappweapp: async (appid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appid' is not null or undefined
            assertParamExists('delWeappweapp', 'appid', appid)
            const localVarPath = `/weapp/delWeapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appid !== undefined) {
                localVarQueryParameter['appid'] = appid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信小程序授权连接
         * @param {SystemGenerateObject28} systemGenerateObject28 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeappAuthUrlweapp: async (systemGenerateObject28: SystemGenerateObject28, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject28' is not null or undefined
            assertParamExists('getWeappAuthUrlweapp', 'systemGenerateObject28', systemGenerateObject28)
            const localVarPath = `/weapp/getWeappAuthUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject28, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信小程序列表
         * @param {SystemGenerateObject26} systemGenerateObject26 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWeappweapp: async (systemGenerateObject26: SystemGenerateObject26, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject26' is not null or undefined
            assertParamExists('listWeappweapp', 'systemGenerateObject26', systemGenerateObject26)
            const localVarPath = `/weapp/listWeapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject26, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 登录微信小程序
         * @param {SystemGenerateObject31} systemGenerateObject31 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginweapp: async (systemGenerateObject31: SystemGenerateObject31, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject31' is not null or undefined
            assertParamExists('loginweapp', 'systemGenerateObject31', systemGenerateObject31)
            const localVarPath = `/weapp/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject31, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信小程序列表(分页)
         * @param {SystemGenerateObject27} systemGenerateObject27 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWeappweapp: async (systemGenerateObject27: SystemGenerateObject27, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject27' is not null or undefined
            assertParamExists('pageWeappweapp', 'systemGenerateObject27', systemGenerateObject27)
            const localVarPath = `/weapp/pageWeapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject27, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配置发布
         * @param {SystemGenerateObject39} systemGenerateObject39 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishWeappweapp: async (systemGenerateObject39: SystemGenerateObject39, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject39' is not null or undefined
            assertParamExists('publishWeappweapp', 'systemGenerateObject39', systemGenerateObject39)
            const localVarPath = `/weapp/publishWeapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject39, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 授权微信小程序
         * @param {SystemGenerateObject32} systemGenerateObject32 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerweapp: async (systemGenerateObject32: SystemGenerateObject32, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject32' is not null or undefined
            assertParamExists('registerweapp', 'systemGenerateObject32', systemGenerateObject32)
            const localVarPath = `/weapp/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject32, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配置所有
         * @param {SystemGenerateObject38} systemGenerateObject38 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAllweapp: async (systemGenerateObject38: SystemGenerateObject38, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject38' is not null or undefined
            assertParamExists('setAllweapp', 'systemGenerateObject38', systemGenerateObject38)
            const localVarPath = `/weapp/setAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject38, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配置基础配置（服务器域名、业务域名、类目）
         * @param {SystemGenerateObject36} systemGenerateObject36 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBaseSettingweapp: async (systemGenerateObject36: SystemGenerateObject36, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject36' is not null or undefined
            assertParamExists('setBaseSettingweapp', 'systemGenerateObject36', systemGenerateObject36)
            const localVarPath = `/weapp/setBaseSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject36, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 配置接口配置（定位）
         * @param {SystemGenerateObject37} systemGenerateObject37 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrivacyInterfaceweapp: async (systemGenerateObject37: SystemGenerateObject37, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject37' is not null or undefined
            assertParamExists('setPrivacyInterfaceweapp', 'systemGenerateObject37', systemGenerateObject37)
            const localVarPath = `/weapp/setPrivacyInterface`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject37, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新微信小程序
         * @param {SystemGenerateObject30} systemGenerateObject30 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeappweapp: async (systemGenerateObject30: SystemGenerateObject30, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject30' is not null or undefined
            assertParamExists('updateWeappweapp', 'systemGenerateObject30', systemGenerateObject30)
            const localVarPath = `/weapp/updateWeapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject30, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeappApi - functional programming interface
 * @export
 */
export const WeappApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeappApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加微信小程序(获取授权信息)
         * @param {SystemGenerateObject29} systemGenerateObject29 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWeappweapp(systemGenerateObject29: SystemGenerateObject29, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWeappweapp(systemGenerateObject29, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检查配置基础配置（服务器域名、业务域名、类目）
         * @param {SystemGenerateObject33} systemGenerateObject33 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkBaseSettingweapp(systemGenerateObject33: SystemGenerateObject33, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkBaseSettingweapp(systemGenerateObject33, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检查接口配置（定位）
         * @param {SystemGenerateObject34} systemGenerateObject34 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPrivacyInterfaceweapp(systemGenerateObject34: SystemGenerateObject34, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPrivacyInterfaceweapp(systemGenerateObject34, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检查发布状态
         * @param {SystemGenerateObject35} systemGenerateObject35 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkStatusweapp(systemGenerateObject35: SystemGenerateObject35, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkStatusweapp(systemGenerateObject35, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除微信小程序
         * @param {string} appid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delWeappweapp(appid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delWeappweapp(appid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信小程序授权连接
         * @param {SystemGenerateObject28} systemGenerateObject28 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeappAuthUrlweapp(systemGenerateObject28: SystemGenerateObject28, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeappAuthUrlweapp(systemGenerateObject28, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信小程序列表
         * @param {SystemGenerateObject26} systemGenerateObject26 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWeappweapp(systemGenerateObject26: SystemGenerateObject26, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWeappweapp(systemGenerateObject26, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 登录微信小程序
         * @param {SystemGenerateObject31} systemGenerateObject31 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginweapp(systemGenerateObject31: SystemGenerateObject31, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginweapp(systemGenerateObject31, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信小程序列表(分页)
         * @param {SystemGenerateObject27} systemGenerateObject27 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageWeappweapp(systemGenerateObject27: SystemGenerateObject27, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageWeappweapp(systemGenerateObject27, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配置发布
         * @param {SystemGenerateObject39} systemGenerateObject39 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishWeappweapp(systemGenerateObject39: SystemGenerateObject39, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishWeappweapp(systemGenerateObject39, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 授权微信小程序
         * @param {SystemGenerateObject32} systemGenerateObject32 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerweapp(systemGenerateObject32: SystemGenerateObject32, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerweapp(systemGenerateObject32, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配置所有
         * @param {SystemGenerateObject38} systemGenerateObject38 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAllweapp(systemGenerateObject38: SystemGenerateObject38, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAllweapp(systemGenerateObject38, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配置基础配置（服务器域名、业务域名、类目）
         * @param {SystemGenerateObject36} systemGenerateObject36 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setBaseSettingweapp(systemGenerateObject36: SystemGenerateObject36, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setBaseSettingweapp(systemGenerateObject36, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 配置接口配置（定位）
         * @param {SystemGenerateObject37} systemGenerateObject37 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPrivacyInterfaceweapp(systemGenerateObject37: SystemGenerateObject37, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPrivacyInterfaceweapp(systemGenerateObject37, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新微信小程序
         * @param {SystemGenerateObject30} systemGenerateObject30 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWeappweapp(systemGenerateObject30: SystemGenerateObject30, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWeappweapp(systemGenerateObject30, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WeappApi - factory interface
 * @export
 */
export const WeappApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeappApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加微信小程序(获取授权信息)
         * @param {SystemGenerateObject29} systemGenerateObject29 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWeappweapp(systemGenerateObject29: SystemGenerateObject29, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addWeappweapp(systemGenerateObject29, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检查配置基础配置（服务器域名、业务域名、类目）
         * @param {SystemGenerateObject33} systemGenerateObject33 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBaseSettingweapp(systemGenerateObject33: SystemGenerateObject33, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.checkBaseSettingweapp(systemGenerateObject33, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检查接口配置（定位）
         * @param {SystemGenerateObject34} systemGenerateObject34 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPrivacyInterfaceweapp(systemGenerateObject34: SystemGenerateObject34, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.checkPrivacyInterfaceweapp(systemGenerateObject34, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检查发布状态
         * @param {SystemGenerateObject35} systemGenerateObject35 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkStatusweapp(systemGenerateObject35: SystemGenerateObject35, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.checkStatusweapp(systemGenerateObject35, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除微信小程序
         * @param {string} appid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWeappweapp(appid: string, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delWeappweapp(appid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信小程序授权连接
         * @param {SystemGenerateObject28} systemGenerateObject28 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeappAuthUrlweapp(systemGenerateObject28: SystemGenerateObject28, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.getWeappAuthUrlweapp(systemGenerateObject28, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信小程序列表
         * @param {SystemGenerateObject26} systemGenerateObject26 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWeappweapp(systemGenerateObject26: SystemGenerateObject26, options?: any): AxiosPromise<any> {
            return localVarFp.listWeappweapp(systemGenerateObject26, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 登录微信小程序
         * @param {SystemGenerateObject31} systemGenerateObject31 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginweapp(systemGenerateObject31: SystemGenerateObject31, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.loginweapp(systemGenerateObject31, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信小程序列表(分页)
         * @param {SystemGenerateObject27} systemGenerateObject27 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWeappweapp(systemGenerateObject27: SystemGenerateObject27, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageWeappweapp(systemGenerateObject27, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配置发布
         * @param {SystemGenerateObject39} systemGenerateObject39 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishWeappweapp(systemGenerateObject39: SystemGenerateObject39, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.publishWeappweapp(systemGenerateObject39, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 授权微信小程序
         * @param {SystemGenerateObject32} systemGenerateObject32 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerweapp(systemGenerateObject32: SystemGenerateObject32, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.registerweapp(systemGenerateObject32, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配置所有
         * @param {SystemGenerateObject38} systemGenerateObject38 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAllweapp(systemGenerateObject38: SystemGenerateObject38, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.setAllweapp(systemGenerateObject38, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配置基础配置（服务器域名、业务域名、类目）
         * @param {SystemGenerateObject36} systemGenerateObject36 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBaseSettingweapp(systemGenerateObject36: SystemGenerateObject36, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.setBaseSettingweapp(systemGenerateObject36, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 配置接口配置（定位）
         * @param {SystemGenerateObject37} systemGenerateObject37 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrivacyInterfaceweapp(systemGenerateObject37: SystemGenerateObject37, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.setPrivacyInterfaceweapp(systemGenerateObject37, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新微信小程序
         * @param {SystemGenerateObject30} systemGenerateObject30 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeappweapp(systemGenerateObject30: SystemGenerateObject30, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateWeappweapp(systemGenerateObject30, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WeappApi - object-oriented interface
 * @export
 * @class WeappApi
 * @extends {BaseAPI}
 */
export class WeappApi extends BaseAPI {
    /**
     * 
     * @summary 添加微信小程序(获取授权信息)
     * @param {SystemGenerateObject29} systemGenerateObject29 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public addWeappweapp(systemGenerateObject29: SystemGenerateObject29, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).addWeappweapp(systemGenerateObject29, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检查配置基础配置（服务器域名、业务域名、类目）
     * @param {SystemGenerateObject33} systemGenerateObject33 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public checkBaseSettingweapp(systemGenerateObject33: SystemGenerateObject33, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).checkBaseSettingweapp(systemGenerateObject33, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检查接口配置（定位）
     * @param {SystemGenerateObject34} systemGenerateObject34 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public checkPrivacyInterfaceweapp(systemGenerateObject34: SystemGenerateObject34, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).checkPrivacyInterfaceweapp(systemGenerateObject34, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检查发布状态
     * @param {SystemGenerateObject35} systemGenerateObject35 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public checkStatusweapp(systemGenerateObject35: SystemGenerateObject35, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).checkStatusweapp(systemGenerateObject35, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除微信小程序
     * @param {string} appid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public delWeappweapp(appid: string, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).delWeappweapp(appid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信小程序授权连接
     * @param {SystemGenerateObject28} systemGenerateObject28 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public getWeappAuthUrlweapp(systemGenerateObject28: SystemGenerateObject28, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).getWeappAuthUrlweapp(systemGenerateObject28, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信小程序列表
     * @param {SystemGenerateObject26} systemGenerateObject26 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public listWeappweapp(systemGenerateObject26: SystemGenerateObject26, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).listWeappweapp(systemGenerateObject26, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 登录微信小程序
     * @param {SystemGenerateObject31} systemGenerateObject31 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public loginweapp(systemGenerateObject31: SystemGenerateObject31, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).loginweapp(systemGenerateObject31, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信小程序列表(分页)
     * @param {SystemGenerateObject27} systemGenerateObject27 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public pageWeappweapp(systemGenerateObject27: SystemGenerateObject27, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).pageWeappweapp(systemGenerateObject27, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配置发布
     * @param {SystemGenerateObject39} systemGenerateObject39 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public publishWeappweapp(systemGenerateObject39: SystemGenerateObject39, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).publishWeappweapp(systemGenerateObject39, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 授权微信小程序
     * @param {SystemGenerateObject32} systemGenerateObject32 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public registerweapp(systemGenerateObject32: SystemGenerateObject32, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).registerweapp(systemGenerateObject32, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配置所有
     * @param {SystemGenerateObject38} systemGenerateObject38 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public setAllweapp(systemGenerateObject38: SystemGenerateObject38, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).setAllweapp(systemGenerateObject38, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配置基础配置（服务器域名、业务域名、类目）
     * @param {SystemGenerateObject36} systemGenerateObject36 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public setBaseSettingweapp(systemGenerateObject36: SystemGenerateObject36, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).setBaseSettingweapp(systemGenerateObject36, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 配置接口配置（定位）
     * @param {SystemGenerateObject37} systemGenerateObject37 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public setPrivacyInterfaceweapp(systemGenerateObject37: SystemGenerateObject37, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).setPrivacyInterfaceweapp(systemGenerateObject37, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新微信小程序
     * @param {SystemGenerateObject30} systemGenerateObject30 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappApi
     */
    public updateWeappweapp(systemGenerateObject30: SystemGenerateObject30, options?: AxiosRequestConfig) {
        return WeappApiFp(this.configuration).updateWeappweapp(systemGenerateObject30, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WeappTplApi - axios parameter creator
 * @export
 */
export const WeappTplApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加草稿到开放平台模板
         * @param {SystemGenerateObject55} systemGenerateObject55 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTplweappTpl: async (systemGenerateObject55: SystemGenerateObject55, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject55' is not null or undefined
            assertParamExists('addTplweappTpl', 'systemGenerateObject55', systemGenerateObject55)
            const localVarPath = `/weappTpl/addTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject55, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加微信小程序模板
         * @param {WeappTplAdminVo} weappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWeappTplweappTpl: async (weappTplAdminVo: WeappTplAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weappTplAdminVo' is not null or undefined
            assertParamExists('addWeappTplweappTpl', 'weappTplAdminVo', weappTplAdminVo)
            const localVarPath = `/weappTpl/addWeappTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(weappTplAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除开放平台模板
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delTplweappTpl: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('delTplweappTpl', 'templateId', templateId)
            const localVarPath = `/weappTpl/delTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除微信小程序模板
         * @param {number} weappTplId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWeappTplweappTpl: async (weappTplId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weappTplId' is not null or undefined
            assertParamExists('delWeappTplweappTpl', 'weappTplId', weappTplId)
            const localVarPath = `/weappTpl/delWeappTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (weappTplId !== undefined) {
                localVarQueryParameter['weappTplId'] = weappTplId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取开放平台模板草稿列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTplDraftListweappTpl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/weappTpl/getTplDraftList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取开放平台模板列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTplListweappTpl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/weappTpl/getTplList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户授权的微信小程序模板列表
         * @param {PartialWeappTplAdminVo} partialWeappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWeappTplByTenantweappTpl: async (partialWeappTplAdminVo: PartialWeappTplAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partialWeappTplAdminVo' is not null or undefined
            assertParamExists('listWeappTplByTenantweappTpl', 'partialWeappTplAdminVo', partialWeappTplAdminVo)
            const localVarPath = `/weappTpl/listWeappTplByTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialWeappTplAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信小程序模板列表
         * @param {PartialWeappTplAdminVo} partialWeappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWeappTplweappTpl: async (partialWeappTplAdminVo: PartialWeappTplAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partialWeappTplAdminVo' is not null or undefined
            assertParamExists('listWeappTplweappTpl', 'partialWeappTplAdminVo', partialWeappTplAdminVo)
            const localVarPath = `/weappTpl/listWeappTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialWeappTplAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信小程序模板列表(分页)
         * @param {SystemGenerateObject54} systemGenerateObject54 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWeappTplweappTpl: async (systemGenerateObject54: SystemGenerateObject54, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject54' is not null or undefined
            assertParamExists('pageWeappTplweappTpl', 'systemGenerateObject54', systemGenerateObject54)
            const localVarPath = `/weappTpl/pageWeappTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject54, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新微信小程序模板
         * @param {WeappTplAdminVo} weappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeappTplweappTpl: async (weappTplAdminVo: WeappTplAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weappTplAdminVo' is not null or undefined
            assertParamExists('updateWeappTplweappTpl', 'weappTplAdminVo', weappTplAdminVo)
            const localVarPath = `/weappTpl/updateWeappTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(weappTplAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeappTplApi - functional programming interface
 * @export
 */
export const WeappTplApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeappTplApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加草稿到开放平台模板
         * @param {SystemGenerateObject55} systemGenerateObject55 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTplweappTpl(systemGenerateObject55: SystemGenerateObject55, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTplweappTpl(systemGenerateObject55, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加微信小程序模板
         * @param {WeappTplAdminVo} weappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWeappTplweappTpl(weappTplAdminVo: WeappTplAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWeappTplweappTpl(weappTplAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除开放平台模板
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delTplweappTpl(templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delTplweappTpl(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除微信小程序模板
         * @param {number} weappTplId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delWeappTplweappTpl(weappTplId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delWeappTplweappTpl(weappTplId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取开放平台模板草稿列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTplDraftListweappTpl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTplDraftListweappTpl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取开放平台模板列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTplListweappTpl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTplListweappTpl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户授权的微信小程序模板列表
         * @param {PartialWeappTplAdminVo} partialWeappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWeappTplByTenantweappTpl(partialWeappTplAdminVo: PartialWeappTplAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWeappTplByTenantweappTpl(partialWeappTplAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信小程序模板列表
         * @param {PartialWeappTplAdminVo} partialWeappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWeappTplweappTpl(partialWeappTplAdminVo: PartialWeappTplAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWeappTplweappTpl(partialWeappTplAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信小程序模板列表(分页)
         * @param {SystemGenerateObject54} systemGenerateObject54 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageWeappTplweappTpl(systemGenerateObject54: SystemGenerateObject54, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageWeappTplweappTpl(systemGenerateObject54, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新微信小程序模板
         * @param {WeappTplAdminVo} weappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWeappTplweappTpl(weappTplAdminVo: WeappTplAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWeappTplweappTpl(weappTplAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WeappTplApi - factory interface
 * @export
 */
export const WeappTplApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeappTplApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加草稿到开放平台模板
         * @param {SystemGenerateObject55} systemGenerateObject55 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTplweappTpl(systemGenerateObject55: SystemGenerateObject55, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addTplweappTpl(systemGenerateObject55, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加微信小程序模板
         * @param {WeappTplAdminVo} weappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWeappTplweappTpl(weappTplAdminVo: WeappTplAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addWeappTplweappTpl(weappTplAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除开放平台模板
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delTplweappTpl(templateId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delTplweappTpl(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除微信小程序模板
         * @param {number} weappTplId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWeappTplweappTpl(weappTplId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delWeappTplweappTpl(weappTplId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取开放平台模板草稿列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTplDraftListweappTpl(options?: any): AxiosPromise<ResultVo> {
            return localVarFp.getTplDraftListweappTpl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取开放平台模板列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTplListweappTpl(options?: any): AxiosPromise<ResultVo> {
            return localVarFp.getTplListweappTpl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户授权的微信小程序模板列表
         * @param {PartialWeappTplAdminVo} partialWeappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWeappTplByTenantweappTpl(partialWeappTplAdminVo: PartialWeappTplAdminVo, options?: any): AxiosPromise<any> {
            return localVarFp.listWeappTplByTenantweappTpl(partialWeappTplAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信小程序模板列表
         * @param {PartialWeappTplAdminVo} partialWeappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWeappTplweappTpl(partialWeappTplAdminVo: PartialWeappTplAdminVo, options?: any): AxiosPromise<any> {
            return localVarFp.listWeappTplweappTpl(partialWeappTplAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信小程序模板列表(分页)
         * @param {SystemGenerateObject54} systemGenerateObject54 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWeappTplweappTpl(systemGenerateObject54: SystemGenerateObject54, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageWeappTplweappTpl(systemGenerateObject54, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新微信小程序模板
         * @param {WeappTplAdminVo} weappTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWeappTplweappTpl(weappTplAdminVo: WeappTplAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateWeappTplweappTpl(weappTplAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WeappTplApi - object-oriented interface
 * @export
 * @class WeappTplApi
 * @extends {BaseAPI}
 */
export class WeappTplApi extends BaseAPI {
    /**
     * 
     * @summary 添加草稿到开放平台模板
     * @param {SystemGenerateObject55} systemGenerateObject55 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public addTplweappTpl(systemGenerateObject55: SystemGenerateObject55, options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).addTplweappTpl(systemGenerateObject55, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加微信小程序模板
     * @param {WeappTplAdminVo} weappTplAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public addWeappTplweappTpl(weappTplAdminVo: WeappTplAdminVo, options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).addWeappTplweappTpl(weappTplAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除开放平台模板
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public delTplweappTpl(templateId: number, options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).delTplweappTpl(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除微信小程序模板
     * @param {number} weappTplId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public delWeappTplweappTpl(weappTplId: number, options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).delWeappTplweappTpl(weappTplId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取开放平台模板草稿列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public getTplDraftListweappTpl(options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).getTplDraftListweappTpl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取开放平台模板列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public getTplListweappTpl(options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).getTplListweappTpl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户授权的微信小程序模板列表
     * @param {PartialWeappTplAdminVo} partialWeappTplAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public listWeappTplByTenantweappTpl(partialWeappTplAdminVo: PartialWeappTplAdminVo, options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).listWeappTplByTenantweappTpl(partialWeappTplAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信小程序模板列表
     * @param {PartialWeappTplAdminVo} partialWeappTplAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public listWeappTplweappTpl(partialWeappTplAdminVo: PartialWeappTplAdminVo, options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).listWeappTplweappTpl(partialWeappTplAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信小程序模板列表(分页)
     * @param {SystemGenerateObject54} systemGenerateObject54 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public pageWeappTplweappTpl(systemGenerateObject54: SystemGenerateObject54, options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).pageWeappTplweappTpl(systemGenerateObject54, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新微信小程序模板
     * @param {WeappTplAdminVo} weappTplAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeappTplApi
     */
    public updateWeappTplweappTpl(weappTplAdminVo: WeappTplAdminVo, options?: AxiosRequestConfig) {
        return WeappTplApiFp(this.configuration).updateWeappTplweappTpl(weappTplAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WhiteAccountApi - axios parameter creator
 * @export
 */
export const WhiteAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 同意自身管理的门店白名单申请
         * @param {number} storeId 
         * @param {number} whiteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessApplywhiteAccount: async (storeId: number, whiteAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('accessApplywhiteAccount', 'storeId', storeId)
            // verify required parameter 'whiteAccountId' is not null or undefined
            assertParamExists('accessApplywhiteAccount', 'whiteAccountId', whiteAccountId)
            const localVarPath = `/whiteAccount/accessApply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (whiteAccountId !== undefined) {
                localVarQueryParameter['whiteAccountId'] = whiteAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加自身管理的门店白名单
         * @param {SystemGenerateObject123} systemGenerateObject123 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOwnerStoreWhiteAccountwhiteAccount: async (systemGenerateObject123: SystemGenerateObject123, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject123' is not null or undefined
            assertParamExists('addOwnerStoreWhiteAccountwhiteAccount', 'systemGenerateObject123', systemGenerateObject123)
            const localVarPath = `/whiteAccount/addOwnerStoreWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject123, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加白名单
         * @param {SystemGenerateObject119} systemGenerateObject119 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWhiteAccountwhiteAccount: async (systemGenerateObject119: SystemGenerateObject119, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject119' is not null or undefined
            assertParamExists('addWhiteAccountwhiteAccount', 'systemGenerateObject119', systemGenerateObject119)
            const localVarPath = `/whiteAccount/addWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject119, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 门店白名单申请
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applywhiteAccount: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('applywhiteAccount', 'storeId', storeId)
            const localVarPath = `/whiteAccount/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除自身管理的门店白名单
         * @param {number} storeId 
         * @param {number} whiteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOwnerStoreWhiteAccountwhiteAccount: async (storeId: number, whiteAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('delOwnerStoreWhiteAccountwhiteAccount', 'storeId', storeId)
            // verify required parameter 'whiteAccountId' is not null or undefined
            assertParamExists('delOwnerStoreWhiteAccountwhiteAccount', 'whiteAccountId', whiteAccountId)
            const localVarPath = `/whiteAccount/delOwnerStoreWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (whiteAccountId !== undefined) {
                localVarQueryParameter['whiteAccountId'] = whiteAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除白名单
         * @param {number} whiteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWhiteAccountwhiteAccount: async (whiteAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'whiteAccountId' is not null or undefined
            assertParamExists('delWhiteAccountwhiteAccount', 'whiteAccountId', whiteAccountId)
            const localVarPath = `/whiteAccount/delWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (whiteAccountId !== undefined) {
                localVarQueryParameter['whiteAccountId'] = whiteAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取自身管理的门店白名单列表
         * @param {SystemGenerateObject121} systemGenerateObject121 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnerStoreWhiteAccountwhiteAccount: async (systemGenerateObject121: SystemGenerateObject121, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject121' is not null or undefined
            assertParamExists('listOwnerStoreWhiteAccountwhiteAccount', 'systemGenerateObject121', systemGenerateObject121)
            const localVarPath = `/whiteAccount/listOwnerStoreWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject121, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取白名单列表
         * @param {SystemGenerateObject117} systemGenerateObject117 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWhiteAccountwhiteAccount: async (systemGenerateObject117: SystemGenerateObject117, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject117' is not null or undefined
            assertParamExists('listWhiteAccountwhiteAccount', 'systemGenerateObject117', systemGenerateObject117)
            const localVarPath = `/whiteAccount/listWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject117, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取自身管理的门店白名单列表(分页)
         * @param {SystemGenerateObject122} systemGenerateObject122 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOwnerStoreWhiteAccountwhiteAccount: async (systemGenerateObject122: SystemGenerateObject122, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject122' is not null or undefined
            assertParamExists('pageOwnerStoreWhiteAccountwhiteAccount', 'systemGenerateObject122', systemGenerateObject122)
            const localVarPath = `/whiteAccount/pageOwnerStoreWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject122, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取白名单列表(分页)
         * @param {SystemGenerateObject118} systemGenerateObject118 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWhiteAccountwhiteAccount: async (systemGenerateObject118: SystemGenerateObject118, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject118' is not null or undefined
            assertParamExists('pageWhiteAccountwhiteAccount', 'systemGenerateObject118', systemGenerateObject118)
            const localVarPath = `/whiteAccount/pageWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject118, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新自身管理的门店白名单
         * @param {SystemGenerateObject124} systemGenerateObject124 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerStoreWhiteAccountwhiteAccount: async (systemGenerateObject124: SystemGenerateObject124, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject124' is not null or undefined
            assertParamExists('updateOwnerStoreWhiteAccountwhiteAccount', 'systemGenerateObject124', systemGenerateObject124)
            const localVarPath = `/whiteAccount/updateOwnerStoreWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject124, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新白名单
         * @param {SystemGenerateObject120} systemGenerateObject120 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhiteAccountwhiteAccount: async (systemGenerateObject120: SystemGenerateObject120, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject120' is not null or undefined
            assertParamExists('updateWhiteAccountwhiteAccount', 'systemGenerateObject120', systemGenerateObject120)
            const localVarPath = `/whiteAccount/updateWhiteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject120, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WhiteAccountApi - functional programming interface
 * @export
 */
export const WhiteAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WhiteAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 同意自身管理的门店白名单申请
         * @param {number} storeId 
         * @param {number} whiteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accessApplywhiteAccount(storeId: number, whiteAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accessApplywhiteAccount(storeId, whiteAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加自身管理的门店白名单
         * @param {SystemGenerateObject123} systemGenerateObject123 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject123: SystemGenerateObject123, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject123, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加白名单
         * @param {SystemGenerateObject119} systemGenerateObject119 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWhiteAccountwhiteAccount(systemGenerateObject119: SystemGenerateObject119, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWhiteAccountwhiteAccount(systemGenerateObject119, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 门店白名单申请
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applywhiteAccount(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applywhiteAccount(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除自身管理的门店白名单
         * @param {number} storeId 
         * @param {number} whiteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOwnerStoreWhiteAccountwhiteAccount(storeId: number, whiteAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOwnerStoreWhiteAccountwhiteAccount(storeId, whiteAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除白名单
         * @param {number} whiteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delWhiteAccountwhiteAccount(whiteAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delWhiteAccountwhiteAccount(whiteAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取自身管理的门店白名单列表
         * @param {SystemGenerateObject121} systemGenerateObject121 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject121: SystemGenerateObject121, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject121, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取白名单列表
         * @param {SystemGenerateObject117} systemGenerateObject117 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWhiteAccountwhiteAccount(systemGenerateObject117: SystemGenerateObject117, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWhiteAccountwhiteAccount(systemGenerateObject117, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取自身管理的门店白名单列表(分页)
         * @param {SystemGenerateObject122} systemGenerateObject122 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject122: SystemGenerateObject122, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject122, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取白名单列表(分页)
         * @param {SystemGenerateObject118} systemGenerateObject118 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageWhiteAccountwhiteAccount(systemGenerateObject118: SystemGenerateObject118, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageWhiteAccountwhiteAccount(systemGenerateObject118, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新自身管理的门店白名单
         * @param {SystemGenerateObject124} systemGenerateObject124 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject124: SystemGenerateObject124, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject124, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新白名单
         * @param {SystemGenerateObject120} systemGenerateObject120 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWhiteAccountwhiteAccount(systemGenerateObject120: SystemGenerateObject120, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWhiteAccountwhiteAccount(systemGenerateObject120, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WhiteAccountApi - factory interface
 * @export
 */
export const WhiteAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WhiteAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary 同意自身管理的门店白名单申请
         * @param {number} storeId 
         * @param {number} whiteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessApplywhiteAccount(storeId: number, whiteAccountId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.accessApplywhiteAccount(storeId, whiteAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加自身管理的门店白名单
         * @param {SystemGenerateObject123} systemGenerateObject123 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject123: SystemGenerateObject123, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject123, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加白名单
         * @param {SystemGenerateObject119} systemGenerateObject119 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWhiteAccountwhiteAccount(systemGenerateObject119: SystemGenerateObject119, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addWhiteAccountwhiteAccount(systemGenerateObject119, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 门店白名单申请
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applywhiteAccount(storeId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.applywhiteAccount(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除自身管理的门店白名单
         * @param {number} storeId 
         * @param {number} whiteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOwnerStoreWhiteAccountwhiteAccount(storeId: number, whiteAccountId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delOwnerStoreWhiteAccountwhiteAccount(storeId, whiteAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除白名单
         * @param {number} whiteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWhiteAccountwhiteAccount(whiteAccountId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delWhiteAccountwhiteAccount(whiteAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取自身管理的门店白名单列表
         * @param {SystemGenerateObject121} systemGenerateObject121 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject121: SystemGenerateObject121, options?: any): AxiosPromise<any> {
            return localVarFp.listOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject121, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取白名单列表
         * @param {SystemGenerateObject117} systemGenerateObject117 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWhiteAccountwhiteAccount(systemGenerateObject117: SystemGenerateObject117, options?: any): AxiosPromise<any> {
            return localVarFp.listWhiteAccountwhiteAccount(systemGenerateObject117, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取自身管理的门店白名单列表(分页)
         * @param {SystemGenerateObject122} systemGenerateObject122 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject122: SystemGenerateObject122, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject122, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取白名单列表(分页)
         * @param {SystemGenerateObject118} systemGenerateObject118 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWhiteAccountwhiteAccount(systemGenerateObject118: SystemGenerateObject118, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageWhiteAccountwhiteAccount(systemGenerateObject118, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新自身管理的门店白名单
         * @param {SystemGenerateObject124} systemGenerateObject124 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject124: SystemGenerateObject124, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject124, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新白名单
         * @param {SystemGenerateObject120} systemGenerateObject120 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhiteAccountwhiteAccount(systemGenerateObject120: SystemGenerateObject120, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateWhiteAccountwhiteAccount(systemGenerateObject120, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WhiteAccountApi - object-oriented interface
 * @export
 * @class WhiteAccountApi
 * @extends {BaseAPI}
 */
export class WhiteAccountApi extends BaseAPI {
    /**
     * 
     * @summary 同意自身管理的门店白名单申请
     * @param {number} storeId 
     * @param {number} whiteAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public accessApplywhiteAccount(storeId: number, whiteAccountId: number, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).accessApplywhiteAccount(storeId, whiteAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加自身管理的门店白名单
     * @param {SystemGenerateObject123} systemGenerateObject123 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public addOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject123: SystemGenerateObject123, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).addOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject123, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加白名单
     * @param {SystemGenerateObject119} systemGenerateObject119 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public addWhiteAccountwhiteAccount(systemGenerateObject119: SystemGenerateObject119, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).addWhiteAccountwhiteAccount(systemGenerateObject119, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 门店白名单申请
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public applywhiteAccount(storeId: number, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).applywhiteAccount(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除自身管理的门店白名单
     * @param {number} storeId 
     * @param {number} whiteAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public delOwnerStoreWhiteAccountwhiteAccount(storeId: number, whiteAccountId: number, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).delOwnerStoreWhiteAccountwhiteAccount(storeId, whiteAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除白名单
     * @param {number} whiteAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public delWhiteAccountwhiteAccount(whiteAccountId: number, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).delWhiteAccountwhiteAccount(whiteAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取自身管理的门店白名单列表
     * @param {SystemGenerateObject121} systemGenerateObject121 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public listOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject121: SystemGenerateObject121, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).listOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject121, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取白名单列表
     * @param {SystemGenerateObject117} systemGenerateObject117 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public listWhiteAccountwhiteAccount(systemGenerateObject117: SystemGenerateObject117, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).listWhiteAccountwhiteAccount(systemGenerateObject117, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取自身管理的门店白名单列表(分页)
     * @param {SystemGenerateObject122} systemGenerateObject122 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public pageOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject122: SystemGenerateObject122, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).pageOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject122, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取白名单列表(分页)
     * @param {SystemGenerateObject118} systemGenerateObject118 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public pageWhiteAccountwhiteAccount(systemGenerateObject118: SystemGenerateObject118, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).pageWhiteAccountwhiteAccount(systemGenerateObject118, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新自身管理的门店白名单
     * @param {SystemGenerateObject124} systemGenerateObject124 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public updateOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject124: SystemGenerateObject124, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).updateOwnerStoreWhiteAccountwhiteAccount(systemGenerateObject124, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新白名单
     * @param {SystemGenerateObject120} systemGenerateObject120 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhiteAccountApi
     */
    public updateWhiteAccountwhiteAccount(systemGenerateObject120: SystemGenerateObject120, options?: AxiosRequestConfig) {
        return WhiteAccountApiFp(this.configuration).updateWhiteAccountwhiteAccount(systemGenerateObject120, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WxApi - axios parameter creator
 * @export
 */
export const WxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加微信公众号(获取授权信息)
         * @param {SystemGenerateObject43} systemGenerateObject43 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWxwx: async (systemGenerateObject43: SystemGenerateObject43, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject43' is not null or undefined
            assertParamExists('addWxwx', 'systemGenerateObject43', systemGenerateObject43)
            const localVarPath = `/wx/addWx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject43, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取消公众号关联的小程序
         * @param {SystemGenerateObject50} systemGenerateObject50 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRelativeWeappwx: async (systemGenerateObject50: SystemGenerateObject50, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject50' is not null or undefined
            assertParamExists('cancelRelativeWeappwx', 'systemGenerateObject50', systemGenerateObject50)
            const localVarPath = `/wx/cancelRelativeWeapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject50, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除微信公众号
         * @param {string} appid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWxwx: async (appid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appid' is not null or undefined
            assertParamExists('delWxwx', 'appid', appid)
            const localVarPath = `/wx/delWx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appid !== undefined) {
                localVarQueryParameter['appid'] = appid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取公众号关联的小程序列表
         * @param {SystemGenerateObject48} systemGenerateObject48 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelativeWeappwx: async (systemGenerateObject48: SystemGenerateObject48, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject48' is not null or undefined
            assertParamExists('getRelativeWeappwx', 'systemGenerateObject48', systemGenerateObject48)
            const localVarPath = `/wx/getRelativeWeapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject48, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信公众号授权连接
         * @param {SystemGenerateObject42} systemGenerateObject42 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWxAuthUrlwx: async (systemGenerateObject42: SystemGenerateObject42, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject42' is not null or undefined
            assertParamExists('getWxAuthUrlwx', 'systemGenerateObject42', systemGenerateObject42)
            const localVarPath = `/wx/getWxAuthUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject42, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取开放平台appid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWxOpenAppidwx: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wx/getWxOpenAppid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信公众号列表
         * @param {SystemGenerateObject40} systemGenerateObject40 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWxwx: async (systemGenerateObject40: SystemGenerateObject40, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject40' is not null or undefined
            assertParamExists('listWxwx', 'systemGenerateObject40', systemGenerateObject40)
            const localVarPath = `/wx/listWx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject40, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 公众号登录
         * @param {SystemGenerateObject47} systemGenerateObject47 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginwx: async (systemGenerateObject47: SystemGenerateObject47, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject47' is not null or undefined
            assertParamExists('loginwx', 'systemGenerateObject47', systemGenerateObject47)
            const localVarPath = `/wx/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject47, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信公众号列表(分页)
         * @param {SystemGenerateObject41} systemGenerateObject41 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWxwx: async (systemGenerateObject41: SystemGenerateObject41, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject41' is not null or undefined
            assertParamExists('pageWxwx', 'systemGenerateObject41', systemGenerateObject41)
            const localVarPath = `/wx/pageWx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject41, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 公众号注册
         * @param {SystemGenerateObject46} systemGenerateObject46 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerwx: async (systemGenerateObject46: SystemGenerateObject46, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject46' is not null or undefined
            assertParamExists('registerwx', 'systemGenerateObject46', systemGenerateObject46)
            const localVarPath = `/wx/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject46, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 公众号关联的小程序
         * @param {SystemGenerateObject49} systemGenerateObject49 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relativeWeappwx: async (systemGenerateObject49: SystemGenerateObject49, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject49' is not null or undefined
            assertParamExists('relativeWeappwx', 'systemGenerateObject49', systemGenerateObject49)
            const localVarPath = `/wx/relativeWeapp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject49, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发送验证码
         * @param {SystemGenerateObject45} systemGenerateObject45 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCodewx: async (systemGenerateObject45: SystemGenerateObject45, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject45' is not null or undefined
            assertParamExists('sendCodewx', 'systemGenerateObject45', systemGenerateObject45)
            const localVarPath = `/wx/sendCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject45, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新微信公众号
         * @param {SystemGenerateObject44} systemGenerateObject44 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWxwx: async (systemGenerateObject44: SystemGenerateObject44, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject44' is not null or undefined
            assertParamExists('updateWxwx', 'systemGenerateObject44', systemGenerateObject44)
            const localVarPath = `/wx/updateWx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject44, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WxApi - functional programming interface
 * @export
 */
export const WxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加微信公众号(获取授权信息)
         * @param {SystemGenerateObject43} systemGenerateObject43 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWxwx(systemGenerateObject43: SystemGenerateObject43, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWxwx(systemGenerateObject43, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取消公众号关联的小程序
         * @param {SystemGenerateObject50} systemGenerateObject50 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelRelativeWeappwx(systemGenerateObject50: SystemGenerateObject50, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelRelativeWeappwx(systemGenerateObject50, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除微信公众号
         * @param {string} appid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delWxwx(appid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delWxwx(appid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取公众号关联的小程序列表
         * @param {SystemGenerateObject48} systemGenerateObject48 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelativeWeappwx(systemGenerateObject48: SystemGenerateObject48, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelativeWeappwx(systemGenerateObject48, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信公众号授权连接
         * @param {SystemGenerateObject42} systemGenerateObject42 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWxAuthUrlwx(systemGenerateObject42: SystemGenerateObject42, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWxAuthUrlwx(systemGenerateObject42, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取开放平台appid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWxOpenAppidwx(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWxOpenAppidwx(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信公众号列表
         * @param {SystemGenerateObject40} systemGenerateObject40 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWxwx(systemGenerateObject40: SystemGenerateObject40, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWxwx(systemGenerateObject40, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 公众号登录
         * @param {SystemGenerateObject47} systemGenerateObject47 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginwx(systemGenerateObject47: SystemGenerateObject47, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginwx(systemGenerateObject47, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信公众号列表(分页)
         * @param {SystemGenerateObject41} systemGenerateObject41 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageWxwx(systemGenerateObject41: SystemGenerateObject41, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageWxwx(systemGenerateObject41, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 公众号注册
         * @param {SystemGenerateObject46} systemGenerateObject46 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerwx(systemGenerateObject46: SystemGenerateObject46, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerwx(systemGenerateObject46, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 公众号关联的小程序
         * @param {SystemGenerateObject49} systemGenerateObject49 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async relativeWeappwx(systemGenerateObject49: SystemGenerateObject49, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.relativeWeappwx(systemGenerateObject49, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发送验证码
         * @param {SystemGenerateObject45} systemGenerateObject45 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCodewx(systemGenerateObject45: SystemGenerateObject45, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCodewx(systemGenerateObject45, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新微信公众号
         * @param {SystemGenerateObject44} systemGenerateObject44 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWxwx(systemGenerateObject44: SystemGenerateObject44, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWxwx(systemGenerateObject44, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WxApi - factory interface
 * @export
 */
export const WxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WxApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加微信公众号(获取授权信息)
         * @param {SystemGenerateObject43} systemGenerateObject43 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWxwx(systemGenerateObject43: SystemGenerateObject43, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addWxwx(systemGenerateObject43, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取消公众号关联的小程序
         * @param {SystemGenerateObject50} systemGenerateObject50 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRelativeWeappwx(systemGenerateObject50: SystemGenerateObject50, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.cancelRelativeWeappwx(systemGenerateObject50, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除微信公众号
         * @param {string} appid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWxwx(appid: string, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delWxwx(appid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取公众号关联的小程序列表
         * @param {SystemGenerateObject48} systemGenerateObject48 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelativeWeappwx(systemGenerateObject48: SystemGenerateObject48, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.getRelativeWeappwx(systemGenerateObject48, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信公众号授权连接
         * @param {SystemGenerateObject42} systemGenerateObject42 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWxAuthUrlwx(systemGenerateObject42: SystemGenerateObject42, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.getWxAuthUrlwx(systemGenerateObject42, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取开放平台appid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWxOpenAppidwx(options?: any): AxiosPromise<ResultVo> {
            return localVarFp.getWxOpenAppidwx(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信公众号列表
         * @param {SystemGenerateObject40} systemGenerateObject40 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWxwx(systemGenerateObject40: SystemGenerateObject40, options?: any): AxiosPromise<any> {
            return localVarFp.listWxwx(systemGenerateObject40, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 公众号登录
         * @param {SystemGenerateObject47} systemGenerateObject47 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginwx(systemGenerateObject47: SystemGenerateObject47, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.loginwx(systemGenerateObject47, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信公众号列表(分页)
         * @param {SystemGenerateObject41} systemGenerateObject41 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWxwx(systemGenerateObject41: SystemGenerateObject41, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageWxwx(systemGenerateObject41, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 公众号注册
         * @param {SystemGenerateObject46} systemGenerateObject46 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerwx(systemGenerateObject46: SystemGenerateObject46, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.registerwx(systemGenerateObject46, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 公众号关联的小程序
         * @param {SystemGenerateObject49} systemGenerateObject49 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relativeWeappwx(systemGenerateObject49: SystemGenerateObject49, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.relativeWeappwx(systemGenerateObject49, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发送验证码
         * @param {SystemGenerateObject45} systemGenerateObject45 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCodewx(systemGenerateObject45: SystemGenerateObject45, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.sendCodewx(systemGenerateObject45, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新微信公众号
         * @param {SystemGenerateObject44} systemGenerateObject44 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWxwx(systemGenerateObject44: SystemGenerateObject44, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateWxwx(systemGenerateObject44, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WxApi - object-oriented interface
 * @export
 * @class WxApi
 * @extends {BaseAPI}
 */
export class WxApi extends BaseAPI {
    /**
     * 
     * @summary 添加微信公众号(获取授权信息)
     * @param {SystemGenerateObject43} systemGenerateObject43 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public addWxwx(systemGenerateObject43: SystemGenerateObject43, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).addWxwx(systemGenerateObject43, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取消公众号关联的小程序
     * @param {SystemGenerateObject50} systemGenerateObject50 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public cancelRelativeWeappwx(systemGenerateObject50: SystemGenerateObject50, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).cancelRelativeWeappwx(systemGenerateObject50, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除微信公众号
     * @param {string} appid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public delWxwx(appid: string, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).delWxwx(appid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取公众号关联的小程序列表
     * @param {SystemGenerateObject48} systemGenerateObject48 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public getRelativeWeappwx(systemGenerateObject48: SystemGenerateObject48, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).getRelativeWeappwx(systemGenerateObject48, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信公众号授权连接
     * @param {SystemGenerateObject42} systemGenerateObject42 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public getWxAuthUrlwx(systemGenerateObject42: SystemGenerateObject42, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).getWxAuthUrlwx(systemGenerateObject42, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取开放平台appid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public getWxOpenAppidwx(options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).getWxOpenAppidwx(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信公众号列表
     * @param {SystemGenerateObject40} systemGenerateObject40 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public listWxwx(systemGenerateObject40: SystemGenerateObject40, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).listWxwx(systemGenerateObject40, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 公众号登录
     * @param {SystemGenerateObject47} systemGenerateObject47 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public loginwx(systemGenerateObject47: SystemGenerateObject47, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).loginwx(systemGenerateObject47, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信公众号列表(分页)
     * @param {SystemGenerateObject41} systemGenerateObject41 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public pageWxwx(systemGenerateObject41: SystemGenerateObject41, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).pageWxwx(systemGenerateObject41, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 公众号注册
     * @param {SystemGenerateObject46} systemGenerateObject46 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public registerwx(systemGenerateObject46: SystemGenerateObject46, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).registerwx(systemGenerateObject46, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 公众号关联的小程序
     * @param {SystemGenerateObject49} systemGenerateObject49 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public relativeWeappwx(systemGenerateObject49: SystemGenerateObject49, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).relativeWeappwx(systemGenerateObject49, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发送验证码
     * @param {SystemGenerateObject45} systemGenerateObject45 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public sendCodewx(systemGenerateObject45: SystemGenerateObject45, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).sendCodewx(systemGenerateObject45, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新微信公众号
     * @param {SystemGenerateObject44} systemGenerateObject44 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxApi
     */
    public updateWxwx(systemGenerateObject44: SystemGenerateObject44, options?: AxiosRequestConfig) {
        return WxApiFp(this.configuration).updateWxwx(systemGenerateObject44, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WxTplApi - axios parameter creator
 * @export
 */
export const WxTplApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加微信h5模板
         * @param {WxTplAdminVo} wxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWxTplwxTpl: async (wxTplAdminVo: WxTplAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wxTplAdminVo' is not null or undefined
            assertParamExists('addWxTplwxTpl', 'wxTplAdminVo', wxTplAdminVo)
            const localVarPath = `/wxTpl/addWxTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wxTplAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除微信h5模板
         * @param {number} wxTplId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWxTplwxTpl: async (wxTplId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wxTplId' is not null or undefined
            assertParamExists('delWxTplwxTpl', 'wxTplId', wxTplId)
            const localVarPath = `/wxTpl/delWxTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wxTplId !== undefined) {
                localVarQueryParameter['wxTplId'] = wxTplId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取租户授权的h5模板列表
         * @param {PartialWxTplAdminVo} partialWxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWxTplByTenantwxTpl: async (partialWxTplAdminVo: PartialWxTplAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partialWxTplAdminVo' is not null or undefined
            assertParamExists('listWxTplByTenantwxTpl', 'partialWxTplAdminVo', partialWxTplAdminVo)
            const localVarPath = `/wxTpl/listWxTplByTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialWxTplAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信h5模板列表
         * @param {PartialWxTplAdminVo} partialWxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWxTplwxTpl: async (partialWxTplAdminVo: PartialWxTplAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partialWxTplAdminVo' is not null or undefined
            assertParamExists('listWxTplwxTpl', 'partialWxTplAdminVo', partialWxTplAdminVo)
            const localVarPath = `/wxTpl/listWxTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialWxTplAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信h5模板列表(分页)
         * @param {SystemGenerateObject53} systemGenerateObject53 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWxTplwxTpl: async (systemGenerateObject53: SystemGenerateObject53, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject53' is not null or undefined
            assertParamExists('pageWxTplwxTpl', 'systemGenerateObject53', systemGenerateObject53)
            const localVarPath = `/wxTpl/pageWxTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject53, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新微信h5模板
         * @param {WxTplAdminVo} wxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWxTplwxTpl: async (wxTplAdminVo: WxTplAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wxTplAdminVo' is not null or undefined
            assertParamExists('updateWxTplwxTpl', 'wxTplAdminVo', wxTplAdminVo)
            const localVarPath = `/wxTpl/updateWxTpl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wxTplAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WxTplApi - functional programming interface
 * @export
 */
export const WxTplApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WxTplApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加微信h5模板
         * @param {WxTplAdminVo} wxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWxTplwxTpl(wxTplAdminVo: WxTplAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWxTplwxTpl(wxTplAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除微信h5模板
         * @param {number} wxTplId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delWxTplwxTpl(wxTplId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delWxTplwxTpl(wxTplId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取租户授权的h5模板列表
         * @param {PartialWxTplAdminVo} partialWxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWxTplByTenantwxTpl(partialWxTplAdminVo: PartialWxTplAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWxTplByTenantwxTpl(partialWxTplAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信h5模板列表
         * @param {PartialWxTplAdminVo} partialWxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWxTplwxTpl(partialWxTplAdminVo: PartialWxTplAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWxTplwxTpl(partialWxTplAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信h5模板列表(分页)
         * @param {SystemGenerateObject53} systemGenerateObject53 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageWxTplwxTpl(systemGenerateObject53: SystemGenerateObject53, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageWxTplwxTpl(systemGenerateObject53, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新微信h5模板
         * @param {WxTplAdminVo} wxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWxTplwxTpl(wxTplAdminVo: WxTplAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWxTplwxTpl(wxTplAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WxTplApi - factory interface
 * @export
 */
export const WxTplApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WxTplApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加微信h5模板
         * @param {WxTplAdminVo} wxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWxTplwxTpl(wxTplAdminVo: WxTplAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addWxTplwxTpl(wxTplAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除微信h5模板
         * @param {number} wxTplId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delWxTplwxTpl(wxTplId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delWxTplwxTpl(wxTplId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取租户授权的h5模板列表
         * @param {PartialWxTplAdminVo} partialWxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWxTplByTenantwxTpl(partialWxTplAdminVo: PartialWxTplAdminVo, options?: any): AxiosPromise<any> {
            return localVarFp.listWxTplByTenantwxTpl(partialWxTplAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信h5模板列表
         * @param {PartialWxTplAdminVo} partialWxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWxTplwxTpl(partialWxTplAdminVo: PartialWxTplAdminVo, options?: any): AxiosPromise<any> {
            return localVarFp.listWxTplwxTpl(partialWxTplAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信h5模板列表(分页)
         * @param {SystemGenerateObject53} systemGenerateObject53 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageWxTplwxTpl(systemGenerateObject53: SystemGenerateObject53, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.pageWxTplwxTpl(systemGenerateObject53, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新微信h5模板
         * @param {WxTplAdminVo} wxTplAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWxTplwxTpl(wxTplAdminVo: WxTplAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateWxTplwxTpl(wxTplAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WxTplApi - object-oriented interface
 * @export
 * @class WxTplApi
 * @extends {BaseAPI}
 */
export class WxTplApi extends BaseAPI {
    /**
     * 
     * @summary 添加微信h5模板
     * @param {WxTplAdminVo} wxTplAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxTplApi
     */
    public addWxTplwxTpl(wxTplAdminVo: WxTplAdminVo, options?: AxiosRequestConfig) {
        return WxTplApiFp(this.configuration).addWxTplwxTpl(wxTplAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除微信h5模板
     * @param {number} wxTplId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxTplApi
     */
    public delWxTplwxTpl(wxTplId: number, options?: AxiosRequestConfig) {
        return WxTplApiFp(this.configuration).delWxTplwxTpl(wxTplId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取租户授权的h5模板列表
     * @param {PartialWxTplAdminVo} partialWxTplAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxTplApi
     */
    public listWxTplByTenantwxTpl(partialWxTplAdminVo: PartialWxTplAdminVo, options?: AxiosRequestConfig) {
        return WxTplApiFp(this.configuration).listWxTplByTenantwxTpl(partialWxTplAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信h5模板列表
     * @param {PartialWxTplAdminVo} partialWxTplAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxTplApi
     */
    public listWxTplwxTpl(partialWxTplAdminVo: PartialWxTplAdminVo, options?: AxiosRequestConfig) {
        return WxTplApiFp(this.configuration).listWxTplwxTpl(partialWxTplAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信h5模板列表(分页)
     * @param {SystemGenerateObject53} systemGenerateObject53 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxTplApi
     */
    public pageWxTplwxTpl(systemGenerateObject53: SystemGenerateObject53, options?: AxiosRequestConfig) {
        return WxTplApiFp(this.configuration).pageWxTplwxTpl(systemGenerateObject53, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新微信h5模板
     * @param {WxTplAdminVo} wxTplAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WxTplApi
     */
    public updateWxTplwxTpl(wxTplAdminVo: WxTplAdminVo, options?: AxiosRequestConfig) {
        return WxTplApiFp(this.configuration).updateWxTplwxTpl(wxTplAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



