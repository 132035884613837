<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="客户数据"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :top-operates="topOperates"
      :codition-init-values="conditionsInitValue"
    />
    <Export ref="exportRef" />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
  useBaseOperateStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { computed, ref } from 'vue';
import { getTodaySec, todaySecFormat, todaySecToDayjs } from '@/utils';
import dayjs from 'dayjs';
import Export from '@/components/Export.vue';
import lodash from 'lodash';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const exportRef = ref<InstanceType<typeof Export>>();
const baseOperateStore = useBaseOperateStore();

let paramsLast: any;
let tableDateLast: any;


const addInitValue = {

};
const conditionsInitValue = {
  timeRange: [
    dayjs().subtract(6, 'day')
      .format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ],
};
const currentRow = ref<any>();
// 可管理服务商，则是管理员
const canAdminFacilitator = computed(() => baseOperateStore.operates?.find((item) => item.key === 'ADMIN_STORE_FACILITATOR'));

// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'timeRange',
      label: '时间范围',
      type: 'datepick',
      isCondition: true,
      hideCreate: true,
      hideEdit: true,
      hideTable: true,
      pickType: 'daterange',
      fastPick: [
        {
          name: '近7日',
          handler: (formdata) => {
            formdata['timeRange'] = [
              dayjs().subtract(6, 'day')
                .format('YYYY-MM-DD'),
              dayjs().format('YYYY-MM-DD'),
            ];
          },
          dateChangeHandler: ([start, end]) => dayjs().format('YYYY-MM-DD') === end && dayjs(end).subtract(6, 'day')
            .format('YYYY-MM-DD') === start,
        },
        {
          name: '近30日',
          handler: (formdata) => {
            formdata['timeRange'] = [
              dayjs().subtract(29, 'day')
                .format('YYYY-MM-DD'),
              dayjs().format('YYYY-MM-DD'),
            ];
          },
          dateChangeHandler: ([start, end]) => dayjs().format('YYYY-MM-DD') === end && dayjs(end).subtract(29, 'day')
            .format('YYYY-MM-DD') === start,
        },
      ],
    },
    ...(
      canAdminFacilitator.value ? [
        {
          name: 'deviceRemark',
          label: '设备备注',
          type: 'string',
          isCondition: true,
          hideEdit: true,
          hideCreate: true,
          order: 10,
        },
      ] : []
    ) as any,
    {
      name: 'storeName',
      label: '店铺名称',
      type: 'string',
      isCondition: true,
    },
    {
      name: 'phone',
      label: '客户手机号',
      type: 'numberStr',
      isCondition: true,
    },

    {
      name: 'type',
      label: '类型',
      type: 'string',
      render: ({ row: { customType } }) => customType || '客户',
    },

    {
      name: 'date',
      label: '开门年月日',
      type: 'string',
      render: ({ row: { createTime } }) => dayjs(createTime).format('YYYY年MM月DD日'),
    },

    {
      name: 'time',
      label: '开门时间',
      type: 'string',
      render: ({ row: { createTime } }) => dayjs(createTime).format('HH:mm:ss'),
    },

    {
      name: 'userGeo',
      label: '定位',
      width: 120,
      type: 'string',
      render: ({ row: { userGeo } }) => `${userGeo.longtitude}, ${userGeo.latitude}`,
    },
    {
      name: 'msg',
      label: '开门状态',
      type: 'string',
    },

    {
      name: 'distance',
      label: '开门距离',
      type: 'string',
      render: ({ row: { distance } }) => (distance ? `${distance}米` : '0米'),
    },
  ];
});


const operates: any[] = [];

const topOperates: any[] = [
  {
    key: 'export',
    name: '导出',
    handler: () => {
      exportRef.value?.show(
        'recordApi',
        'pageAdminRecordrecord',
        paramsLast,
        columns.value,
        10,
        tableDateLast.total,
      );
    },
  },
];
const loading = ref(false);


// eslint-disable-next-line max-lines-per-function
const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const endTime = dayjs(filter.conditions.timeRange[1]).isSame(dayjs(), 'day')
      ? dayjs().format('HH:mm:ss')
      : '23:59:59';
    paramsLast = {
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '' || key === 'timeRange') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      start: `${filter.conditions.timeRange[0]} 00:00:00`,
      end: `${filter.conditions.timeRange[1]} ${endTime}`,

    };
    const res = await useApi().recordApi.pageAdminRecordrecord({
      ...paramsLast,
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },

    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    tableDateLast = tableData;
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.idHex})操作，请谨慎操作`;


init();

</script>
<style lang="scss" scoped>
</style>
